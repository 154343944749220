import { ColaboradorRoutes } from '@/pages/colaborador/colaborador.routes';
import { RouterService } from '@/services/router.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getSvg } from '@/utils/helpers';
import { goToDependents } from '@/layout/layout.component';
import { BootstrapDataService } from '@/services/api/bootstrap_data.service';

@Component({
  selector: 'app-dependents',
  templateUrl: './dependents.component.html',
  styleUrls: ['./dependents.component.scss'],
})
export class DependentsComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  getSvg = getSvg;
  constructor(
    private bootstrapDataService: BootstrapDataService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.close.emit();
  }

  onDependent() {
    if (this.bootstrapDataService.isLoaded) {
      this.resolveDependentRedirect(this.bootstrapDataService.plan);
    } else {
      this.bootstrapDataService.planChange.subscribe(() => {
        this.resolveDependentRedirect(this.bootstrapDataService.plan);
      });
    }

    this.closeModal();
  }

  resolveDependentRedirect(plan) {
    const hasDependents = plan?.features?.filter((feature) => feature.name === 'dependents_included').length > 0;
    if (hasDependents) {
      return this.routerService.navigate([ColaboradorRoutes.MANAGE_DEPENDENTS]);
    }

    goToDependents();
  }
}
