import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentProps } from 'react';

import RefundRequestDetails from '@/react/components/refund/refund-management/resource-approval-details/item/detail-refund-request';

@Component({
  selector: 'component-refund-request-details',
  templateUrl: './refund-request-details.component.html',
})
export class RefundRequestDetailsComponent implements OnInit {
  RefundRequestDetails = RefundRequestDetails;

  props: ComponentProps<typeof RefundRequestDetails> = {
    resourceApprovalUuid: undefined,
    refundRequestUuid: undefined,
  };

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const { resourceApprovalUuid, refundRequestUuid } =
      this.route.snapshot.params;

    this.props = {
      ...this.props,
      resourceApprovalUuid,
      refundRequestUuid,
    };
  }
}
