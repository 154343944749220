import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  ToggleButton,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useSetTitle } from '@/react/hooks/useSetTitle';
import { ToggleButtonGroup } from '@/react/components/toggle-buttons';
import { IdpConfirmDialog } from '@/react/components/idp/dialogs';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { ProfileBasedRecommendation } from '@/react/data/idp/profile';
import { useRedoRecommendation } from '@/react/data/idp/useRedoRecommendation';

export const formValidationSchema = z.object({
  selectedActivitiesIds: z.array(z.string()).optional(),
});

export const PlanRecommendationReview: React.ElementType<
  WizardStepComponent & {
    profileBasedRecommendation: Partial<ProfileBasedRecommendation>;
  }
> = ({ onNext, profileBasedRecommendation }) => {
  useSetTitle('Recomendação');
  const { redoRecommendation } = useRedoRecommendation(
    profileBasedRecommendation.id,
    (recommendation) => {
      window.location.assign(`/pdi/planos/recomendacoes/${recommendation.id}`);
    }
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const recommendedActivities =
    profileBasedRecommendation.recommended_activities;

  const defaultValues = {
    selectedActivitiesIds: [],
  };

  const { handleSubmit, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(formValidationSchema),
  });

  const handleNext = (data) => {
    if (onNext) {
      onNext({
        selectedActivitiesIds: data.selectedActivitiesIds,
      });
    }
  };

  const { selectedActivitiesIds } = watch();

  const handleChange = (_, value) => {
    if (value !== null) {
      setValue('selectedActivitiesIds', value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      <Box mt={3}>
        <Typography variant="h6">
          Sugerimos o seguinte plano para você
        </Typography>
        <Typography variant="body3">
          Essas são as atividades recomendadas para o seu plano de
          desenvolvimento. Selecione quantas quiser utilizar. Algumas atividades
          vêm com recomendações de cursos. Você pode escolher entre uma das
          opções ou continuar sem nenhuma.
        </Typography>

        <Typography variant="body3">
          Na próxima etapa, você também pode adicionar atividades manualmente,
          se quiser complementar as recomendações selecionadas.
        </Typography>
      </Box>

      <Box sx={{ py: 3 }}>
        <form onSubmit={handleSubmit(handleNext)}>
          <ToggleButtonGroup
            aria-label="plans recommendation review"
            color="primary"
            fullWidth
            onChange={handleChange}
            size="large"
            value={selectedActivitiesIds}
          >
            <Grid container spacing={2}>
              {recommendedActivities?.map((activity) => (
                <Grid item xs={12} key={activity.id}>
                  <ToggleButton value={activity.id}>
                    <Stack
                      sx={{
                        textAlign: 'left',
                      }}
                      spacing={0}
                      width="100%"
                    >
                      <Typography variant="body3" fontWeight="bold">
                        {activity.name}
                      </Typography>
                      <Typography variant="body3">{activity.reason}</Typography>
                    </Stack>
                  </ToggleButton>
                </Grid>
              ))}
            </Grid>
          </ToggleButtonGroup>
          <WizardStepActions>
            <Button
              color="primary"
              onClick={() => setIsConfirmModalOpen(true)}
              variant="text"
            >
              Refazer a recomendação
            </Button>
            <Button
              onClick={() => {
                trigger();
              }}
              color="primary"
              type="submit"
              variant="contained"
            >
              Criar Plano
            </Button>
          </WizardStepActions>
        </form>
      </Box>
      <IdpConfirmDialog
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={redoRecommendation}
        actionLabels={{
          confirm: 'Refazer a recomendação',
          cancel: 'Voltar',
        }}
        title="Tem certeza de que deseja refazer a recomendação?"
        subtitle="As sugestões atuais serão descartadas, e você precisará responder ao questionário novamente."
        description="Lembre-se: é possível adicionar manualmente outras atividades após esta etapa de recomendação."
      />
    </>
  );
};
