import React from 'react';

import { IdpClient } from '@/react/data/idp/idp-client';
import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';
import { IdpConfirmDialog } from '@/react/components/idp/dialogs';
import { Plan } from '@/react/data/idp/interfaces';
import { useQueryClient } from '@tanstack/react-query';
import { useGetPlanKey } from '@/react/data/idp/usePlan';

export interface ConfirmStartPlanDialogProps {
  open: boolean;
  onClose: () => void;
  plan: Partial<Plan>;
}

const handleStartPlan = async ({ plan, onSuccess, onError }) => {
  try {
    await IdpClient.startPlan(plan.id);
    onSuccess();
  } catch (error) {
    if (error.response.status !== 422) {
      onError('Ocorreu um erro ao iniciar o plano. Tente novamente mais tarde');
      Sentry.captureException(error);
      return;
    }
    const offenderPlan: Plan =
      error.response?.data?.errors?.offender_plan?.title ?? 'em andamento';
    onError(
      `Só é possível ter um plano em andamento por vez. Para iniciar esse, finalize o plano ${offenderPlan}.`
    );
  }
};

export const ConfirmStartPlanDialog = ({
  open,
  onClose,
  plan,
}: ConfirmStartPlanDialogProps) => {
  const { toast } = useNotification();
  const queryClient = useQueryClient();

  const listeners = {
    onError: (error: string) => {
      toast({
        content: error,
        type: 'error',
      });
      onClose();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [useGetPlanKey] });
      toast({
        content: 'Seu plano foi iniciado com sucesso!',
        type: 'success',
      });
      onClose();
    },
  };

  return (
    <IdpConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={() => handleStartPlan({ plan, ...listeners })}
      actionLabels={{
        confirm: 'Iniciar o plano',
        cancel: 'Voltar',
      }}
      title="Você está iniciando o seu plano"
      subtitle="Vamos iniciar sua jornada de aprendizagem!"
      description="Depois de iniciado, você vai poder acompanhar e atualizar o progresso das atividades. E não se preocupe, mesmo depois de inicado é possível editá-lo à vontade."
    />
  );
};
