import React, { useState } from 'react';
import { SectionHeader, PageContainer } from '@/react/components/refund/refund-management/shared';
import { useResourceApprovalsDetails } from '@/react/data/subsidy/useResourceApprovalsDetails';
import { useDecideResourceApproval } from '@/react/data/subsidy/useDecideResourceApproval';
import {
  Box,
  Typography,
  Grid,
  Divider,
  Stack,
  Button,
  Skeleton
} from '@mui/material';
import { formatDate } from '@/react/utils/date';
import { ResourceCourseSources } from '@/react/components/refund/refund-request/interfaces';
import { CourseTypesLabels, ModalityTypesLabels, PeriodTypesLabels } from '@/react/data/catalog/constants';
import { Modalities, PriceTypes } from '@/react/data/catalog/interfaces';
import { formatDuration } from '@/react/utils/time';
import { formatMoney } from '@/react/utils/money';
import {
  ResourceApprovalDecisionType,
  RefundResourceStatus,
  ResourceTypes
} from '@/react/data/subsidy/interfaces';
import { useNotification } from '@/react/components/notification';
import ConfirmResourceApprovalDecisionDialog from './confirm-resource-approval-decision-dialog.component';

const handleBackButton = () => {
    window.location.assign('/empresa/gestao-de-reembolsos');
}

const FieldSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Typography component="div" variant="body1">
        <Skeleton width="20%" />
      </Typography>
    </Stack>
  );
};

const FieldWrapper = ({ loading, children }) => {
  return loading ? <FieldSkeleton /> : children;
};

export const ResourceApprovalReview = ({ resourceApprovalUuid } : {
  resourceApprovalUuid: string;
}) => {
  const { resourceApprovalsDetails: resourceApproval, isFetching: fetchingResourceApproval } = useResourceApprovalsDetails({
    uuid: resourceApprovalUuid,
  });  
  const { decideResourceApproval } = useDecideResourceApproval();
  const { toast } = useNotification();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [decision, setDecision] = useState(null);

  const handleOpenDialog = (decision: ResourceApprovalDecisionType) => {
    setDecision(decision);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmDecision = (comment: string) => {
    handleDecideResourceApproval(decision, comment);
    setDialogOpen(false);
  };

  const handleDecideResourceApproval = async (
    decision: ResourceApprovalDecisionType,
    decision_comment?: string
  ) => {
    try {
      await decideResourceApproval({
        resource_approval_uuid: resourceApproval?.uuid,
        decision,
        decision_comment,
      });

      decision === ResourceApprovalDecisionType.APPROVED ?
      toast({
        content: 'Solicitação aprovado!\nO usuário receberá um e-mail informando sobre a aprovação.',
        type: 'success',
      }) :
      toast({
        content: 'Pedido recusado.\nO usuário receberá um e-mail com as informações de recusa e deverá abrir uma nova solicitação.',
        type: 'warning',
      })
    } catch (error) {
      toast({
        content: 'Erro ao processar a solicitação. Tentar novamente em instantes.',
        type: 'error',
      })
    }
  }

  return (
    <PageContainer>
      <SectionHeader
        title="Analisar pedido"
        handleBackNavigation={handleBackButton}
      />

      <Box sx={({ palette }) => ({
          padding: 2,
          border: `1px solid ${palette.neutral[300]}`,
          backgroundColor: `${palette.neutral[50]}`,
        })}
      >
        <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontWeight: '600' }}
              color="primary.200"
            >
              Dados da solicitação
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1" style={{ fontWeight: '600' }}>Data da solicitação</Typography>
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={10}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1">
                {formatDate(resourceApproval?.requested_at)}
              </Typography>
            </FieldWrapper>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1" style={{ fontWeight: '600' }}>Solicitante</Typography>
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={10}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1">
                {resourceApproval?.user.name || '--'}
              </Typography>
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} md={2}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1" style={{ fontWeight: '600' }}>Usuário</Typography>
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={10}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1">{resourceApproval?.user.email}</Typography>
            </FieldWrapper>
          </Grid>
        </Grid>
      </Box>

      <Box sx={({ palette }) => ({
          marginTop: 3,
          padding: 2,
          border: `1px solid ${palette.neutral[300]}`,
          backgroundColor: `${palette.neutral[50]}`,
        })}
      >
        <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontWeight: '600' }}
              color="primary.200"
            >
              Descrição do item
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1" style={{ fontWeight: '600' }}>Catálogo</Typography>
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={10}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1">
                {resourceApproval?.resource.course?.source === ResourceCourseSources.CATALOG
                  ? 'Plataforma Galena'
                  : 'Fora da Galena'
                }
              </Typography>
            </FieldWrapper>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1" style={{ fontWeight: '600' }}>Tipo de Item</Typography>
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={10}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1">{resourceApproval?.category}</Typography>
            </FieldWrapper>
          </Grid>
          
          {(resourceApproval?.type.label === ResourceTypes.COURSE) && (
            <>
              <Grid item xs={12} md={2}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography variant="body1" style={{ fontWeight: '600' }}>Instituição</Typography>
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} md={10}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography variant="body1">
                    {resourceApproval?.resource.course?.institution_name}
                  </Typography>
                </FieldWrapper>
              </Grid>

              <Grid item xs={12} md={2}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography variant="body1" style={{ fontWeight: '600' }}>Curso</Typography>
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} md={10}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography variant="body1">{resourceApproval?.name}</Typography>
                </FieldWrapper>
              </Grid>

              <Grid item xs={12} md={2}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography variant="body1" style={{ fontWeight: '600' }}>Categoria</Typography>
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} md={10}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography variant="body1">
                    {CourseTypesLabels[resourceApproval?.resource.course?.course_type]}
                  </Typography>
                </FieldWrapper>
              </Grid>

              <Grid item xs={12} md={2}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography variant="body1" style={{ fontWeight: '600' }}>Modalidade</Typography>
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} md={10}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography variant="body1">
                    {ModalityTypesLabels[resourceApproval?.resource.course?.modality]}
                  </Typography>
                </FieldWrapper>
              </Grid>

              {(resourceApproval?.resource.course?.modality === Modalities.PRESENTIAL 
                || resourceApproval?.resource.course?.modality === Modalities.HYBRID) && (
                <>
                  <Grid item xs={12} md={2}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1" style={{ fontWeight: '600' }}>Estado</Typography>
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1">
                        {resourceApproval?.resource.course?.address?.state}
                      </Typography>
                    </FieldWrapper>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1" style={{ fontWeight: '600' }}>Cidade</Typography>
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1">
                        {resourceApproval?.resource.course?.address?.city}
                      </Typography>
                    </FieldWrapper>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1" style={{ fontWeight: '600' }}>Polo</Typography>
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1">
                        {resourceApproval?.resource.course?.address?.campus}
                      </Typography>
                    </FieldWrapper>
                  </Grid>
                </>
              )}

              {resourceApproval?.resource.course?.period !== null && (
                <>
                  <Grid item xs={12} md={2}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1" style={{ fontWeight: '600' }}>Período</Typography>
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1">
                        {PeriodTypesLabels[resourceApproval?.resource.course?.period]}
                      </Typography>
                    </FieldWrapper>
                  </Grid>
                </>
              )}

              {resourceApproval?.resource.course?.workload !== null && (
                <>
                  <Grid item xs={12} md={2}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1" style={{ fontWeight: '600' }}>Carga horária</Typography>
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1">
                        {PeriodTypesLabels[resourceApproval?.resource.course?.workload]}
                      </Typography>
                    </FieldWrapper>
                  </Grid>
                </>
              )}

              {resourceApproval?.resource.course?.duration !== null && (
                <>
                  <Grid item xs={12} md={2}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1" style={{ fontWeight: '600' }}>Duração</Typography>
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FieldWrapper loading={fetchingResourceApproval}>
                      <Typography variant="body1">
                        {formatDuration(
                          resourceApproval?.resource.course?.duration_type,
                          resourceApproval?.resource.course?.duration
                        )}
                      </Typography>
                    </FieldWrapper>
                  </Grid>
                </>
              )}
            </>
          )}

          <Grid item xs={12} md={2}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1" style={{ fontWeight: '600' }}>Tipo de pagamento</Typography>
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={10}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1">
                {resourceApproval?.sale_type === PriceTypes.one_off ? 'Compra à vista' : 'Compra parcelada'}
              </Typography>
            </FieldWrapper>
          </Grid>

          <Grid item xs={12} md={2}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1" style={{ fontWeight: '600' }}>Valor</Typography>
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={10}>
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography variant="body1">
                {formatMoney(Number(resourceApproval?.sale_price))}
              </Typography>
            </FieldWrapper>
          </Grid>

          {(resourceApproval?.resource.course?.link ||
            resourceApproval?.resource.learning_aid?.link) && (
              <>
                <Grid item xs={12} md={2}>
                  <FieldWrapper loading={fetchingResourceApproval}>
                    <Typography variant="body1" style={{ fontWeight: '600' }}>Link</Typography>
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FieldWrapper loading={fetchingResourceApproval}>
                    <Typography variant="body1">
                      {resourceApproval?.type.label === ResourceTypes.COURSE ?
                        resourceApproval?.resource.course?.link :
                        resourceApproval?.resource.learning_aid?.link}
                    </Typography>
                  </FieldWrapper>
                </Grid>
              </>
          )}
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid item xs={12}>
        <Box
          sx={{
            px: 2,
            my: 2,
          }}
        >
          <Stack spacing={2}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 0, sm: 3 }}
            >
              <Box flexGrow={1}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography
                    component="div"
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                    variant="body3"
                  >
                    Custo total do item
                  </Typography>
                </FieldWrapper>
              </Box>
              <Box>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography
                    component="div"
                    display="flex"
                    justifyContent={{ xs: 'left', sm: 'right' }}
                    variant="body3"

                  >
                    {formatMoney(parseFloat(resourceApproval?.sale_price))}
                  </Typography>
                </FieldWrapper>
              </Box>
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 4 }}
            >
              <Box flexGrow={1}>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography
                    component="div"
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                    variant="body3"
                  >
                    Valor máximo reembolsável
                  </Typography>
                </FieldWrapper>
              </Box>
              <Box>
                <FieldWrapper loading={fetchingResourceApproval}>
                  <Typography
                    component="div"
                    display="flex"
                    justifyContent={{ xs: 'left', sm: 'right' }}
                    variant="body3"
                  >
                    {formatMoney(parseFloat(resourceApproval?.requested_refundable_amount))}
                  </Typography>
                </FieldWrapper>
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
            mt={2}
          >
            <FieldWrapper loading={fetchingResourceApproval}>
              <Typography component="p" variant="body3">
                Calculado de acordo com a política de reembolso da sua empresa.
              </Typography>
            </FieldWrapper>
          </Stack>
        </Box>
        <Divider sx={{ my: 3 }} />
        {resourceApproval?.status === RefundResourceStatus.pending_approval && (
          <>
            <Stack
              direction="row"
              spacing={2}
              mt={2}
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                sx={{
                padding: '14px 16px',
                width: 130,
                height: 48,
                borderRadius: 0,
                backgroundColor: '#E3005F',
                '&:hover': { backgroundColor: '#E3005F' }
                }}
                onClick={() => handleOpenDialog(ResourceApprovalDecisionType.REJECTED)}
                data-testid="reject-button"
              >
                Recusar item
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '14px 16px',
                  width: 130,
                  height: 48,
                  borderRadius: 0,
                  backgroundColor: 'success.700',
                  '&:hover': { backgroundColor: 'success.700' }
                }}
                onClick={() => handleOpenDialog(ResourceApprovalDecisionType.APPROVED)}
                data-testid="approve-button"
              >
                Aprovar item
              </Button>
            </Stack>
            <ConfirmResourceApprovalDecisionDialog
              decision={decision}
              open={dialogOpen}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmDecision}
            />
          </> 
        )}
      </Grid>
      </Box>
    </PageContainer>
  );
}

export default ResourceApprovalReview;
