import { GOALS, SCHOLARITIES, SENIORITIES } from './constants';

export interface Profile {
  id: number;
  email: string;
  agreed_to_receive_emails: boolean;
  current_step: any;
  completed_at: string;
  external_employee_id?: number;
  external_user_id?: number;
  address_state: string;
  address_city: string;
  scholarity: string;
  goal: string;
  seniority: string;
  course_types: string[];
  languages: string[];
  modalities: (keyof typeof Modalities)[];
  knowledge_areas: {
    id: number;
    external_category_id: string;
    description_pt_br: string;
    description_en_us: string;
  }[];
  desired_job: ProfileJob;
  current_job: ProfileJob;
  created_at?: string;
  updated_at?: string;
  min_budget_per_month: string | null;
  max_budget_per_month: string | null;
  min_hours_available_per_week: number | null;
  max_hours_available_per_week: number | null;
  discarded_at: string;
}

export enum Modalities {
  hibrido = 'Híbrido',
  presencial = 'Presencial',
  curso_a_distancia = 'Curso a distância',
}

export type ProfileSkill = {
  name_en: string;
  name_pt: string;
  confidence: number;
};

export type ProfileJob = {
  id: number;
  lightcast_id: string;
  name_en: string;
  name_pt: string;
  gpt_skill_knowledge_areas: ProfileSkill[];
  gpt_skill_subcategories: ProfileSkill[];
};

export type ProfileGoals = (typeof GOALS)[keyof typeof GOALS];
export type ProfileGoalsLabels = {
  readonly [K in ProfileGoals]: string;
};
export type ProfileScholarity =
  (typeof SCHOLARITIES)[keyof typeof SCHOLARITIES];
export type ProfileScholarityLabelsType = {
  readonly [K in ProfileScholarity]: string;
};
export type ProfileSeniority = (typeof SENIORITIES)[keyof typeof SENIORITIES];
export type ProfileSeniorityLabelsType = {
  readonly [K in ProfileSeniority]: string;
};
