import * as React from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import Accordion from '@/react/components/refund/accordion/accordion.component';
import { AccordionProvider } from '@/react/components/refund/accordion/state/context';
import { AccordionSteps } from '@/react/components/refund/accordion/interfaces';
import { State as AccordionState } from '@/react/components/refund/accordion/state/interfaces';
import ExtensiveOverview from '@/react/components/refund/refund-request/description-step/refund-details/extensive-overview/extensive-overview.component';

import { RefundStatus } from '@/react/data/subsidy/interfaces';
import {
  ExtensiveOverviewProps,
  ResourceApprovalDescriptionProps,
  ResumeProps,
} from './intefaces';

type StatusType = RefundStatus;

enum VariantType {
  resource_approval_pending_approval = 'waitingForConclusion',
  resource_approval_canceled = 'negativeRequestResponse',
  resource_approval_approved = 'waitingForConclusion',
}

type StatusParams = {
  status: string;
  date: any;
  email?: string;
};

const statusMessages: Record<StatusType, (params: StatusParams) => string> = {
  revision_required: ({ date }) => `Correção necessária em ${date}`,
  rejected: ({ date }) => `Solicitação recusada em ${date}`,
  approved: ({ date, email }) => `Pedido aprovado por ${email} em ${date}`,
};

const getStatusMessage = (params: StatusParams): string => {
  const normalizedStatus = params.status
    .toLowerCase()
    .replace(/resource_approval_|refund_request_/g, '');

  if (!(normalizedStatus in statusMessages)) {
    return 'Status desconhecido';
  }
  return statusMessages[normalizedStatus as StatusType](params);
};

const ResourceApprovalItemDescription: React.FC<ExtensiveOverviewProps> = ({
  resourceApproval,
}) => {
  return <ExtensiveOverview resourceApproval={resourceApproval} />;
};

const Resume: React.FC<ResumeProps> = ({ resourceApproval }) => {
  return (
    <Grid
      container
      spacing={2}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} mb={2} style={{ textAlign: 'left' }}>
        <Typography
          variant="body1"
          style={{ fontWeight: '600' }}
          color="primary.200"
        >
          Descrição do item
        </Typography>
        <Grid
          item
          xs={12}
          justifyContent="center"
          sx={({ palette, spacing }) => ({
            color: palette.neutral[800],
            marginBottom: spacing(2),
            paddingBottom: spacing(0.5),
            paddingTop: spacing(0.5),
          })}
        >
          <Typography variant="body3">
            {resourceApproval?.name?.split(', ')[0] +
              ' | ' +
              resourceApproval?.resource?.course?.institution_name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Chip
            label={`${getStatusMessage({ status: resourceApproval.status, date: new Date(resourceApproval.decided_at).toLocaleDateString(), email: resourceApproval?.user?.email })}`}
            variant={VariantType[resourceApproval?.status]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const detailAccordionSteps = ({ resourceApprovalsDetails }) => {
  const schema: AccordionSteps = {
    INIT: {
      disabled: false,
      expanded: false,
      renderContent: () => (
        <ResourceApprovalItemDescription
          resourceApproval={resourceApprovalsDetails}
        />
      ),
      renderHeader: () => (
        <Resume resourceApproval={resourceApprovalsDetails} />
      ),
      status: null,
    },
  };

  return schema;
};

const ResourceApprovalDescription: React.FC<
  ResourceApprovalDescriptionProps
> = () => {
  return (
    <>
      <Accordion />
    </>
  );
};

export const DetailsResourceApporvalItem: React.FC<
  ResourceApprovalDescriptionProps
> = ({ resourceApprovalsDetails }) => {
  const steps = React.useMemo(() => {
    return detailAccordionSteps({
      resourceApprovalsDetails,
    });
  }, [resourceApprovalsDetails]);

  const initialAccordionState: AccordionState = {
    steps,
  };

  return (
    <AccordionProvider initialState={initialAccordionState}>
      <ResourceApprovalDescription
        resourceApprovalsDetails={resourceApprovalsDetails}
      />
    </AccordionProvider>
  );
};
