import { inject } from '@angular/core';
import { UrlTree, CanActivateFn } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RouterService } from '../router.service';
import { BootstrapDataService } from '../api/bootstrap_data.service';

export const SubsidiesGuard: CanActivateFn = (): Observable<
  boolean | UrlTree
> => {
  const routerService = inject(RouterService);
  const bootstrapDataService = inject(BootstrapDataService);

  return bootstrapDataService.planChange.pipe(
    switchMap(() =>
      checkEligibilityAndNavigate(routerService, bootstrapDataService)
    )
  );

  async function checkEligibilityAndNavigate(
    routerService: RouterService,
    bootstrapDataService: BootstrapDataService,
  ): Promise<boolean | UrlTree> {
    if (isEligibleForSubsidies(bootstrapDataService)) {
      return true;
    }

    routerService.navigate(['cursos'], {
      queryParams: { redirectUrl: window.location.href },
    });
    return false;
  }

  function isEligibleForSubsidies(
    bootstrapDataService: BootstrapDataService,
  ): boolean {
    const hasSubsidies = bootstrapDataService.plan?.features?.filter((feature) => feature.name === 'subsidies').length > 0;

    return (
      hasSubsidies &&
      bootstrapDataService?.plan?.subsidies_eligibility?.eligible_for_subsidies
    );
  }
};
