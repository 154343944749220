import { useMutation } from '@tanstack/react-query';
import { IdpClient } from './idp-client';

import { ProfileBasedRecommendation } from '@/react/data/idp/profile/interfaces';

export function useUpdateProfileBasedRecommendation(
  profileBasedRecommendationId: string
) {
  const { mutateAsync: updateProfileBasedRecommendation } = useMutation({
    mutationFn: async (
      profileBasedRecommendation: Partial<ProfileBasedRecommendation>
    ) => {
      return IdpClient.updateProfileBasedRecommendation(
        profileBasedRecommendationId,
        profileBasedRecommendation
      );
    },
  });

  return {
    updateProfileBasedRecommendation,
  };
}
