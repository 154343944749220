import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import KnowledgeAreaCard from './knowledge-area-card.component';

export const CustomGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 0,
  gridTemplateColumns: '1fr',
  borderLeftColor: theme.palette.neutral[300],
  borderLeftStyle: 'solid',
  borderLeftWidth: 1,
  borderTopColor: theme.palette.neutral[300],
  borderTopStyle: 'solid',
  borderTopWidth: 1,
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
  [theme.breakpoints.up('xl')]: {
    gridTemplateColumns: 'repeat(8, 1fr)',
  },
}));

const KnowledgeAreaList = ({ data }) => (
  <CustomGrid>
    {data.map((knowledgeArea) => (
      <KnowledgeAreaCard
        data={knowledgeArea}
        key={knowledgeArea.external_category_id}
      />
    ))}
  </CustomGrid>
);

export default KnowledgeAreaList;
