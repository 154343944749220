import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Rating from '@mui/material/Rating';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const Cheatsheet = () => (
  <Box sx={{ p: 2 }}>
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} lg={4}>
        <Typography variant="h3">Buttons</Typography>
        <Box sx={{ '& > :not(style)': { mb: 3 } }}>
          <Fab color="primary" aria-label="add">
            <Icon>add_circle</Icon>
          </Fab>
          <Fab color="secondary" aria-label="edit">
            <Icon>add_circle</Icon>
          </Fab>
          <Fab variant="extended">
            <Icon sx={{ mr: 1 }}>add_circle</Icon>
            Navigate
          </Fab>
          <Fab disabled aria-label="like">
            <Icon>add_circle</Icon>
          </Fab>
          <Box>
            <ToggleButtonGroup
              value={['bold', 'underlined']}
              onChange={() => {}}
            >
              <ToggleButton value="bold" aria-label="bold">
                <Icon>format_bold</Icon>
              </ToggleButton>
              <ToggleButton value="italic" aria-label="italic">
                <Icon>format_italic</Icon>
              </ToggleButton>
              <ToggleButton value="underlined" aria-label="underlined">
                <Icon>format_underlined</Icon>
              </ToggleButton>
              <ToggleButton value="color" aria-label="color" disabled>
                <Icon>format_color_fill</Icon>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Stack spacing={2} direction="column">
            <Typography variant="subtitle1">As anchors (href)</Typography>
            <Button href="#" variant="text" color="primary">
              Text Primary (anchor)
            </Button>
            <Button href="#" variant="contained" color="primary">
              Contained Primary (anchor)
            </Button>
            <Button href="#" variant="outlined" color="primary">
              Outlined Primary (anchor)
            </Button>
            <Button href="#" variant="text" color="secondary">
              Text Secondary (anchor)
            </Button>
            <Button href="#" variant="contained" color="secondary">
              Contained Secondary (anchor)
            </Button>
            <Button href="#" variant="outlined" color="secondary">
              Outlined Secondary (anchor)
            </Button>
            <Button href="#" variant="text" color="success">
              Text Success (anchor)
            </Button>
            <Button href="#" variant="contained" color="success">
              Contained Success (anchor)
            </Button>
            <Button href="#" variant="outlined" color="success">
              Outlined Success (anchor)
            </Button>
            <Button href="#" variant="text" color="error">
              Text Error (anchor)
            </Button>
            <Button href="#" variant="contained" color="error">
              Contained Error (anchor)
            </Button>
            <Button href="#" variant="outlined" color="error">
              Outlined Error (anchor)
            </Button>
            <Button href="#" variant="text" color="info">
              Text Info (anchor)
            </Button>
            <Button href="#" variant="contained" color="info">
              Contained Info (anchor)
            </Button>
            <Button href="#" variant="outlined" color="info">
              Outlined Info (anchor)
            </Button>
            <Button href="#" variant="text" color="warning">
              Text Warning (anchor)
            </Button>
            <Button href="#" variant="contained" color="warning">
              Contained Warning (anchor)
            </Button>
            <Button href="#" variant="outlined" color="warning">
              Outlined Warning (anchor)
            </Button>
            <Typography variant="subtitle1">As buttons</Typography>
            <Button variant="text" color="primary">
              Text Primary
            </Button>
            <Button variant="text" color="primary" disabled>
              Text Primary Disabled
            </Button>
            <Button variant="contained" color="primary" size="large">
              Contained Primary
            </Button>
            <Button variant="contained" color="primary" size="medium">
              Contained Primary Medium
            </Button>
            <Button variant="contained" color="primary" size="small">
              Contained Primary Small
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
            >
              Contained Primary Icon Right
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
            >
              Contained Primary Icon Left
            </Button>
            <Button variant="contained" color="primary" disabled>
              Contained Primary Disabled
            </Button>
            <Button variant="outlined" color="primary">
              Outlined Primary
            </Button>
            <Button variant="outlined" color="primary" disabled>
              Outlined Primary Disabled
            </Button>
            <Button variant="text" color="secondary">
              Text Secondary
            </Button>
            <Button variant="text" color="secondary" disabled>
              Text Secondary Disabled
            </Button>
            <Button variant="contained" color="secondary">
              Contained Secondary
            </Button>
            <Button variant="contained" color="secondary" disabled>
              Contained Secondary Disabled
            </Button>
            <Button variant="outlined" color="secondary">
              Outlined Secondary
            </Button>
            <Button variant="outlined" color="secondary" disabled>
              Outlined Secondary Disabled
            </Button>
            <Button variant="text" color="success">
              Text Success
            </Button>
            <Button variant="text" color="success" disabled>
              Text Success Disabled
            </Button>
            <Button variant="contained" color="success">
              Contained Success
            </Button>
            <Button variant="contained" color="success" disabled>
              Contained Success Disabled
            </Button>
            <Button variant="outlined" color="success">
              Outlined Success
            </Button>
            <Button variant="outlined" color="success" disabled>
              Outlined Success Disabled
            </Button>
            <Button variant="text" color="error">
              Text Error
            </Button>
            <Button variant="text" color="error" disabled>
              Text Error Disabled
            </Button>
            <Button variant="contained" color="error">
              Contained Error
            </Button>
            <Button variant="contained" color="error" disabled>
              Contained Error Disabled
            </Button>
            <Button variant="outlined" color="error">
              Outlined Error
            </Button>
            <Button variant="outlined" color="error" disabled>
              Outlined Error Disabled
            </Button>
            <Button variant="text" color="info">
              Text Info
            </Button>
            <Button variant="text" color="info" disabled>
              Text Info Disabled
            </Button>
            <Button variant="contained" color="info">
              Contained Info
            </Button>
            <Button variant="contained" color="info" disabled>
              Contained Info Disabled
            </Button>
            <Button variant="outlined" color="info">
              Outlined Info
            </Button>
            <Button variant="outlined" color="info" disabled>
              Outlined Info Disabled
            </Button>
            <Button variant="text" color="warning">
              Text Warning
            </Button>
            <Button variant="text" color="warning" disabled>
              Text Warning Disabled
            </Button>
            <Button variant="contained" color="warning">
              Contained Warning
            </Button>
            <Button variant="contained" color="warning" disabled>
              Contained Warning Disabled
            </Button>
            <Button variant="outlined" color="warning">
              Outlined Warning
            </Button>
            <Button variant="outlined" color="warning" disabled>
              Outlined Warning Disabled
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Typography variant="h3">Data Display</Typography>
        <Box sx={{ width: '100%', maxWidth: 500 }}>
          <Typography variant="h1" component="div" gutterBottom>
            h1. Heading
          </Typography>
          <Typography variant="h2" gutterBottom component="div">
            h2. Heading
          </Typography>
          <Typography variant="h3" gutterBottom component="div">
            h3. Heading
          </Typography>
          <Typography variant="h4" gutterBottom component="div">
            h4. Heading
          </Typography>
          <Typography variant="h5" gutterBottom component="div">
            h5. Heading
          </Typography>
          <Typography variant="h6" gutterBottom component="div">
            h6. Heading
          </Typography>
          <Typography variant="subtitle1" gutterBottom component="div">
            Subtitle-1
          </Typography>
          <Typography variant="subtitle2" gutterBottom component="div">
            Subtitle-2
          </Typography>
          <Typography variant="subtitle3" gutterBottom component="div">
            Subtitle-3
          </Typography>
          <Typography variant="body1" gutterBottom>
            Body-1
          </Typography>
          <Typography variant="body2" gutterBottom>
            Body-2
          </Typography>
          <Typography variant="body3" gutterBottom>
            Body-3
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            Button
          </Typography>
          <Typography variant="button1" display="block" gutterBottom>
            Button-1
          </Typography>
          <Typography variant="button2" display="block" gutterBottom>
            Button-2
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            Caption
          </Typography>
        </Box>
        <Typography variant="h3">Font Weights</Typography>
        <Box sx={{ width: '100%', maxWidth: 500 }}>
          <Typography fontWeight={300}>Custom (300)</Typography>
          <Typography fontWeight="regular">Regular</Typography>
          <Typography fontWeight="medium">Medium</Typography>
          <Typography fontWeight="semibold">Semibold</Typography>
          <Typography fontWeight="bold">Bold</Typography>
          <Typography fontWeight={800}>Custom (800)</Typography>
          <Typography fontWeight={900}>Custom (900)</Typography>
        </Box>
        <Stack spacing={3}>
          <AvatarGroup max={4}>
            <Avatar alt="Rodrigo" src="" />
            <Avatar alt="Vallades" src="" />
            <Avatar alt="Magalhães" src="" />
            <Avatar alt="Galena" src="" />
            <Avatar alt="Educação" src="" />
          </AvatarGroup>
          <Stack spacing={3} direction="column">
            <Avatar variant="square">V</Avatar>
            <Avatar variant="rounded">
              <Icon>assignment</Icon>
            </Avatar>
          </Stack>
          <Stack spacing={3} direction="row">
            <Badge color="secondary" badgeContent=" ">
              <Avatar variant="square">V</Avatar>
            </Badge>
            <Badge color="success" badgeContent=" " variant="dot">
              <Avatar variant="square">V</Avatar>
            </Badge>
            <Badge color="primary" overlap="circular" badgeContent=" ">
              <Avatar>V</Avatar>
            </Badge>
            <Badge
              color="secondary"
              overlap="circular"
              badgeContent=" "
              variant="dot"
            >
              <Avatar>N</Avatar>
            </Badge>
          </Stack>
          <Stack spacing={2}>
            <Box sx={{ '& > :not(style)': { mr: 1, mb: 1 } }}>
              <Chip label="Chip Filled" />
              <Chip label="Chip Outlined" variant="outlined" />
            </Box>
            <Box sx={{ '& > :not(style)': { mr: 1, mb: 1 } }}>
              <Chip label="primary" color="primary" />
              <Chip label="secondary" color="secondary" />
              <Chip label="error" color="error" />
              <Chip label="info" color="info" />
              <Chip label="warning" color="warning" />
              <Chip label="success" color="success" />
            </Box>
            <Box sx={{ '& > :not(style)': { mr: 1, mb: 1 } }}>
              <Chip label="primary" color="primary" variant="outlined" />
              <Chip label="secondary" color="secondary" variant="outlined" />
              <Chip label="error" color="error" variant="outlined" />
              <Chip label="info" color="info" variant="outlined" />
              <Chip label="warning" color="warning" variant="outlined" />
              <Chip label="success" color="success" variant="outlined" />
            </Box>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Typography variant="h3">Feedback</Typography>
        <Stack spacing={3}>
          <Stack spacing={2}>
            <CircularProgress />
            <CircularProgress color="secondary" />
            <CircularProgress color="success" />
            <CircularProgress color="inherit" />
            <LinearProgress />
            <LinearProgress color="secondary" />
            <LinearProgress color="success" />
            <LinearProgress color="inherit" />
          </Stack>
          <Stack spacing={2}>
            <Alert variant="filled" severity="error">
              This is an error alert — check it out!
            </Alert>
            <Alert variant="filled" severity="warning">
              This is a warning alert — check it out!
            </Alert>
            <Alert variant="filled" severity="info">
              This is an info alert — check it out!
            </Alert>
            <Alert variant="filled" severity="success">
              This is a success alert — check it out!
            </Alert>
            <Alert severity="error">
              This is an error alert — check it out!
            </Alert>
            <Alert severity="warning">
              This is a warning alert — check it out!
            </Alert>
            <Alert severity="info">This is an info alert — check it out!</Alert>
            <Alert severity="success">
              This is a success alert — check it out!
            </Alert>
            <Alert variant="outlined" severity="error">
              This is an error alert — check it out!
            </Alert>
            <Alert variant="outlined" severity="warning">
              This is a warning alert — check it out!
            </Alert>
            <Alert variant="outlined" severity="info">
              This is an info alert — check it out!
            </Alert>
            <Alert variant="outlined" severity="success">
              This is a success alert — check it out!
            </Alert>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Typography variant="h2">Fields</Typography>
        <Stack spacing={3}>
          <Box>
            <Autocomplete
              options={['Angular', 'Vue', 'React', 'Parcel']}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Autocomplete"
                  slotProps={{
                    htmlInput: {
                      ...params.inputProps,
                      className: `${params.inputProps.className} browser-default`,
                    }
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Select (standard)
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={20}
                label="Select (standard)"
                onChange={() => {}}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth variant="filled">
              <InputLabel id="demo-simple-select-label2">
                Select (filled)
              </InputLabel>
              <Select
                labelId="demo-simple-select-label2"
                id="demo-simple-select2"
                value={20}
                label="Select (filled)"
                onChange={() => {}}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            id="outlined-basic"
            label="Outlined"
            fullWidth
            variant="outlined"
            slotProps={{
              htmlInput: {
                className: 'browser-default',
              }
            }}
          />
          <TextField
            id="filled-basic"
            label="Filled"
            fullWidth
            helperText="Helper text"
            variant="filled"
            slotProps={{
              htmlInput: {
                className: 'browser-default',
              }
            }}
          />
          <TextField
            id="standard-basic"
            label="Standard"
            fullWidth
            variant="standard"
            slotProps={{
              htmlInput: {
                className: 'browser-default',
              }
            }}
          />
          <TextField
            error={true}
            fullWidth
            helperText="Error message"
            value="Value"
            variant="standard"
            slotProps={{
              htmlInput: {
                className: 'browser-default',
              }
            }}
          />
          <TextField
            fullWidth
            label="Outlined secondary"
            color="secondary"
            focused
            slotProps={{
              htmlInput: {
                className: 'browser-default',
              }
            }}
          />
          <TextField
            fullWidth
            label="Filled success"
            variant="filled"
            color="success"
            focused
            slotProps={{
              htmlInput: {
                className: 'browser-default',
              }
            }}
          />
          <TextField
            fullWidth
            label="Standard warning"
            variant="standard"
            color="warning"
            focused
            slotProps={{
              htmlInput: {
                className: 'browser-default',
              }
            }}
          />
          <FormControl error={true} variant="standard">
            <FormLabel>Você já concluiu o Ensino Médio?</FormLabel>
            <RadioGroup name="has_completed_high_school" row={true}>
              <FormControlLabel
                control={
                  <Radio
                    inputProps={{
                      className: 'browser-default',
                    }}
                  />
                }
                label="Não"
                value="false"
              />
              <FormControlLabel
                control={
                  <Radio
                    inputProps={{
                      className: 'browser-default',
                    }}
                  />
                }
                label="Sim"
                value="true"
              />
            </RadioGroup>
            <FormHelperText>Error message</FormHelperText>
          </FormControl>
          <FormControl error={true} variant="standard">
            <FormLabel>Você já concluiu o Ensino Médio?</FormLabel>
            <RadioGroup name="has_completed_high_school" row={false}>
              <FormControlLabel
                control={
                  <Radio
                    inputProps={{
                      className: 'browser-default',
                    }}
                  />
                }
                label="Não"
                value="false"
              />
              <FormControlLabel
                control={
                  <Radio
                    inputProps={{
                      className: 'browser-default',
                    }}
                  />
                }
                label="Sim"
                value="true"
              />
            </RadioGroup>
            <FormHelperText>Error message</FormHelperText>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Typography variant="h2">Inputs</Typography>
        <Box>
          <Switch defaultChecked />
          <Switch />
          <Switch disabled defaultChecked />
          <Switch disabled />
          <Switch defaultChecked />
          <Switch defaultChecked color="secondary" />
          <Switch defaultChecked color="warning" />
          <Switch defaultChecked color="default" />
        </Box>
        <Stack spacing={2}>
          <Slider value={55} onChange={() => {}} />
          <Slider value={75} color="secondary" onChange={() => {}} />
          <Slider disabled defaultValue={30} />
        </Stack>
        <Box>
          <Checkbox indeterminate />
          <Checkbox defaultChecked />
          <Checkbox />
          <Checkbox disabled />
          <Checkbox disabled checked />
          <Checkbox defaultChecked />
          <Checkbox defaultChecked color="secondary" />
          <Checkbox defaultChecked color="success" />
          <Checkbox defaultChecked color="default" />
        </Box>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Label"
          />
          <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
        </FormGroup>
        <Box>
          <Radio
            checked
            color="secondary"
            inputProps={{
              className: 'browser-default',
            }}
          />
          <Radio
            checked
            color="success"
            inputProps={{
              className: 'browser-default',
            }}
          />
          <Radio
            checked
            color="default"
            inputProps={{
              className: 'browser-default',
            }}
          />
          <Radio
            checked
            color="default"
            disabled
            inputProps={{
              className: 'browser-default',
            }}
          />
          <Radio
            disabled
            inputProps={{
              className: 'browser-default',
            }}
          />
          <Radio
            inputProps={{
              className: 'browser-default',
            }}
          />
          <Radio
            disabled
            inputProps={{
              className: 'browser-default',
            }}
          />
        </Box>
        <Box>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="female"
                control={
                  <Radio
                    inputProps={{
                      className: 'browser-default',
                    }}
                  />
                }
                label="Female"
              />
              <FormControlLabel
                value="male"
                control={
                  <Radio
                    inputProps={{
                      className: 'browser-default',
                    }}
                  />
                }
                label="Male"
              />
              <FormControlLabel
                disabled
                value="other"
                control={
                  <Radio
                    inputProps={{
                      className: 'browser-default',
                    }}
                  />
                }
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <Rating
            className="fix-materialize"
            name="simple-controlled"
            value={2}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Typography variant="h2">Surfaces</Typography>
        <Stack spacing={2}>
          <AppBar position="static" color="primary">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                sx={{ mr: 1 }}
              >
                <Icon>menu</Icon>
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Primary
              </Typography>
              <Button color="inherit">Login</Button>
            </Toolbar>
          </AppBar>
          <AppBar position="static" color="secondary">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                sx={{ mr: 1 }}
              >
                <Icon>menu</Icon>
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Secondary
              </Typography>
              <Button color="inherit">Login</Button>
            </Toolbar>
          </AppBar>
          <Card>
            <CardContent>
              <Typography color="text.secondary" gutterBottom>
                Word of the Day
              </Typography>
              <Typography variant="h5" component="div">
                be • nev • o • lent
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                adjective
              </Typography>
              <Typography variant="body2">
                well meaning and kindly.
                <br />
                {'"a benevolent smile"'}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Typography color="text.secondary" gutterBottom>
                Word of the Day
              </Typography>
              <Typography variant="h5" component="div">
                be • nev • o • lent
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                adjective
              </Typography>
              <Typography variant="body2">
                well meaning and kindly.
                <br />
                {'"a benevolent smile"'}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions>
          </Card>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                flex: 1,
                height: 128,
              },
            }}
          >
            <Paper elevation={0} />
            <Paper />
            <Paper elevation={3} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                flex: 1,
                height: 128,
              },
            }}
          >
            <Paper variant="outlined" />
            <Paper variant="outlined" square />
          </Box>
          <Box>
            <Accordion>
              <AccordionSummary
                expandIcon={<Icon>expand_more</Icon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Accordion 1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<Icon>expand_more</Icon>}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Accordion 2</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion disabled>
              <AccordionSummary
                expandIcon={<Icon>expand_more</Icon>}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>Disabled Accordion</Typography>
              </AccordionSummary>
            </Accordion>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  </Box>
);

export default Cheatsheet;
