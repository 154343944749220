import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/browser';

import { restClient } from '@/react/utils/fetch';
import { useManageDependents } from './manage-dependents.context';

const RemovetDependentDialog = ({ dependentId, open, toggleOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const { dispatch } = useManageDependents();

  const handleRemove = async () => {
    setLoading(true);

    try {
      const removed = await restClient.delete(`/dependents/${dependentId}`);
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message: 'Seu dependente foi removido com sucesso.',
          severity: 'success',
        },
      });
      dispatch({
        type: 'REMOVE_DEPENDENT',
        payload: removed.data.data,
      });
      handleClose();
    } catch (error) {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message: 'Erro ao remover dependente.',
          severity: 'error',
        },
      });
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  const handleClose = () => {
    toggleOpen();
  };

  return (
    <Dialog onClose={handleClose} fullWidth open={open}>
      <DialogTitle>Remover dependente</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2">
            Ao remover o dependente, ele não terá mais acesso à plataforma. Não
            se preocupe, seu dependente não perderá os descontos que já
            adquiriu.
          </Typography>
          <Typography variant="body2">
            <strong>Tem certeza que deseja remover?</strong>
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="tertiary">
          Cancelar
        </Button>
        <Button
          color="error"
          loading={loading}
          onClick={handleRemove}
          type="submit"
          variant="contained"
        >
          Remover dependente
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemovetDependentDialog;
