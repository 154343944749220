import * as React from 'react';
import Box from '@mui/material/Box';

import { WizardStep } from './interfaces';

const StepWrapper = ({ children }) => {
  return <Box>{children}</Box>;
};

const Step: React.ElementType<WizardStep> = ({
  component: Component,
  titleElement,
  ...callbacks
}) => {
  return (
    <StepWrapper>
      {titleElement}
      <Component {...callbacks} />
    </StepWrapper>
  );
};

export default Step;
