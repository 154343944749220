import * as React from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';

import { useClickOutside } from '@/react/hooks/useClickOutside';

export type InlineEditTextFieldProps = {
  onAccept: (value: string) => Promise<boolean>;
  onClickOutside?: (value?: string) => Promise<boolean>;
  onCancel?: () => void;
  value: string;
  disabled?: boolean;
} & BaseTextFieldProps;

const Modes = {
  editing: 'editing',
  pending: 'pending',
  readOnly: 'readOnly',
} as const;

const Actions = {
  changeMode: 'changeMode',
  updateInternalValue: 'updateInternalValue',
} as const;

type ModeTypes = keyof typeof Modes;
type ActionTypes = keyof typeof Actions;

interface InternalState {
  internalValue: string;
  mode: ModeTypes;
}

export const InlineEditTextField = React.forwardRef<
  HTMLInputElement,
  InlineEditTextFieldProps
>((props, ref) => {
  const { onAccept, onClickOutside, onCancel, value, disabled, ...inputProps } =
    props;

  const initialState: InternalState = {
    internalValue: value,
    mode: Modes.readOnly,
  };

  const [state, dispatch] = React.useReducer<
    React.Reducer<InternalState, { type: ActionTypes; payload: any }>
  >((state: InternalState, action): InternalState => {
    switch (action.type) {
      case Actions.changeMode:
        return { ...state, mode: action.payload };
      case Actions.updateInternalValue:
        return { ...state, internalValue: action.payload };
      default:
        return state;
    }
  }, initialState);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const { mode, internalValue } = state;

  React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  const addSelection = () => {
    inputRef.current.focus();
    inputRef.current.select();
  };

  const handleEditMode = (active: boolean) => {
    if (active) {
      if (mode === Modes.pending) return;

      dispatch({
        type: Actions.changeMode,
        payload: Modes.editing,
      });

      addSelection();
    } else {
      if (mode !== Modes.editing) return;

      dispatch({ type: Actions.changeMode, payload: Modes.readOnly });
      dispatch({ type: Actions.updateInternalValue, payload: value });

      removeSelection();
    }
  };

  const removeSelection = () => {
    if (window.getSelection) {
      window.getSelection()?.removeAllRanges();
    }
  };

  const handleSuccess = (success: boolean) => {
    if (success) {
      dispatch({ type: Actions.changeMode, payload: Modes.readOnly });
      removeSelection();
    } else {
      addSelection();
      dispatch({ type: Actions.changeMode, payload: Modes.editing });
    }
  };

  const handleAccept = async () => {
    dispatch({ type: Actions.changeMode, payload: Modes.pending });
    const success = await onAccept(internalValue);

    handleSuccess(success);
  };

  const handleCancel = () => {
    handleEditMode(false);

    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const handleClickOutside = React.useCallback(async () => {
    if (mode !== Modes.editing) return handleEditMode(false);

    if (typeof onClickOutside === 'function') {
      dispatch({ type: Actions.changeMode, payload: Modes.pending });
      const success = await onClickOutside(internalValue);

      handleSuccess(success);
    } else {
      handleEditMode(false);
    }
  }, [onClickOutside, internalValue]);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        handleAccept();
        break;
      case 'Escape':
        e.stopPropagation();
        handleCancel();
        break;
      default:
        break;
    }
  };

  useClickOutside({
    callback: handleClickOutside,
    ref: wrapperRef,
  });

  React.useEffect(() => {
    dispatch({ type: Actions.updateInternalValue, payload: value });
  }, [value]);

  return (
    <TextField
      {...inputProps}
      hiddenLabel
      inputRef={inputRef}
      ref={wrapperRef}
      sx={(theme) => ({
        ...(mode !== Modes.editing && {
          '& .MuiInputBase-input': {
            cursor: 'default',
          },
          '& .MuiInputBase-root': {
            cursor: 'default',
            '&:hover': {
              backgroundColor: theme.palette.neutral[200],
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }),
      })}
      value={mode === Modes.readOnly ? value : internalValue}
      slotProps={{
        input: {
          onChange: (e) => {
            dispatch({
              type: Actions.updateInternalValue,
              payload: e.target.value,
            });
          },
          onKeyDown: handleKeyDown,
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                height: 'auto',
              }}
            >
              {mode === Modes.editing && (
                <>
                  <IconButton
                    aria-label="accept changes"
                    onClick={handleAccept}
                  >
                    <CheckCircleRoundedIcon />
                  </IconButton>
                  <IconButton
                    aria-label="cancel changes"
                    onClick={handleCancel}
                  >
                    <CancelRoundedIcon />
                  </IconButton>
                </>
              )}
              {!disabled && mode !== Modes.editing && (
                <IconButton
                  aria-label="toggle editing mode"
                  onClick={() => handleEditMode(true)}
                >
                  <EditRoundedIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
          readOnly: mode === Modes.readOnly,
          sx: {
            ...(props.multiline && {
              alignItems: 'flex-start',
            }),
          },
        },
      }}
    />
  );
});
