import palette from '../palette';

const MuiChip = {
  styleOverrides: {
    root: {
      variants: [
        {
          props: { variant: 'planStart' as const },
          style: {
            backgroundColor: palette.warning.main,
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'planFull' as const },
          style: {
            backgroundColor: palette.primary[300],
            color: palette.neutral[900],
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'planFamily' as const },
          style: {
            backgroundColor: palette.primary[500],
            color: palette.neutral[50],
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'statusRemoved' as const },
          style: {
            backgroundColor: palette.neutral[200],
            color: palette.neutral[800],
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'statusPending' as const },
          style: {
            backgroundColor: palette.warning[300],
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'statusActive' as const },
          style: {
            backgroundColor: palette.success[700],
            color: palette.neutral[100],
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'waitingForRevision' as const },
          style: {
            backgroundColor: palette.idle.main,
            color: palette.idle.contrastText,
            fontWeight: 600,
            borderRadius: 0,
          },
        },
        {
          props: { variant: 'negativeRequestResponse' as const },
          style: {
            backgroundColor: palette.negative.main,
            color: palette.negative.contrastText,
            fontWeight: 600,
            borderRadius: 0,
          },
        },
        {
          props: { variant: 'actionNeededAfterRevision' as const },
          style: {
            backgroundColor: palette.attention.main,
            color: palette.attention.contrastText,
            fontWeight: 600,
            borderRadius: 0,
          },
        },
        {
          props: { variant: 'waitingForConclusion' as const },
          style: {
            backgroundColor: palette.idleSuccess.main,
            color: palette.idleSuccess.contrastText,
            fontWeight: 600,
            borderRadius: 0,
          },
        },
        {
          props: { variant: 'processConcludedSuccessfully' as const },
          style: {
            backgroundColor: palette.concluded.main,
            color: palette.neutral[50],
            fontWeight: 600,
            borderRadius: 0,
          },
        },
      ],
    },
  },
};

export default MuiChip;
