import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';

import { restClient } from '@/react/utils/fetch';
import { useManageDependents } from './manage-dependents.context';
import SharedDialogContent, {
  formValidationSchema,
} from './add-or-edit-dialog-content.component';

const intialValues = {
  name: '',
  email: '',
  kinship_degree: 'spouse',
};

const AddDependentDialog = ({ open, toggleOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: intialValues,
    resolver: zodResolver(formValidationSchema),
  });
  const { dispatch } = useManageDependents();

  const handleAdd = async (data) => {
    const payload = {
      ...data,
    };

    setLoading(true);

    try {
      const added = await restClient.post(`/dependents`, payload);
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message: `Um convite foi enviado para: '${added.data.data.user.email}', com os dados de acesso à plataforma.`,
          severity: 'success',
          title: 'Dependente adicionado',
        },
      });
      dispatch({
        type: 'ADD_DEPENDENT',
        payload: added.data.data,
      });
      handleClose();
    } catch (error) {
      const message = error.message || 'Erro ao criar dependente.';
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message,
          severity: 'error',
        },
      });
      setLoading(false);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  const handleClose = () => {
    toggleOpen();
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      open={open}
      TransitionProps={{
        onExited: () => {
          reset();
        },
      }}
    >
      <form onSubmit={handleSubmit(handleAdd)}>
        <DialogTitle>Adicionar dependente</DialogTitle>
        <DialogContent>
          <SharedDialogContent formControl={control} />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleClose} variant="contained" color="tertiary">
            Cancelar
          </Button>
          <Button
            color="primary"
            loading={loading}
            loadingPosition="start"
            startIcon={<AddIcon />}
            type="submit"
            variant="contained"
          >
            Adicionar dependente
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddDependentDialog;
