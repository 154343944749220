import { restClient } from '@/react/utils/fetch';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { toastSuccess, toastError } from '@/react/utils/custom-toast';

const RemoveEmployee = ({ employeeId, open, toggleOpen, onUpdateEmployee }) => {
  const [loading, setLoading] = React.useState(false);

  const handleRemove = async () => {
    setLoading(true);

    try {
      const response = await restClient.delete(`/employees/${employeeId}`);

      const messageToast =
        response?.data?.data?.message || 'Usuário excluído com sucesso.';

      toastSuccess({ messageToast, handleCallback: toggleOpen() });
      onUpdateEmployee();
    } catch (error) {
      const messageToast = error?.message ?? 'Erro ao excluir usuário!';

      toastError({ messageToast, handleCallback: toggleOpen() });
      setLoading(false);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  return (
    <Dialog onClose={toggleOpen} fullWidth open={open}>
      <DialogTitle>Excluir usuário</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2">
            <p> Tem certeza que deseja excluir esse usuário? </p>
            <p>
              O acesso à plataforma será revogado imediatamente, mas matrículas
              feitas antes da exclusão continuam válidas.
            </p>
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleOpen} variant="outlined" color="primary">
          Cancelar
        </Button>
        <Button
          color="error"
          loading={loading}
          onClick={handleRemove}
          type="submit"
          variant="contained"
        >
          Excluir usuário
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveEmployee;
