import { restClient } from '@/react/utils/fetch';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { toastSuccess, toastError } from '@/react/utils/custom-toast';
import { emailValidationSchema } from '../schemas';

const createInitialValues = (employee) => ({
  name: employee?.nome,
  email: employee?.email,
});

const EditEmployeeEmail = ({
  employee,
  open,
  toggleOpen,
  onUpdateEmployee,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: createInitialValues(employee),
    resolver: zodResolver(emailValidationSchema),
  });

  React.useEffect(() => {
    reset(createInitialValues(employee));
  }, [employee]);

  const handleUpdate = async (data) => {
    const payload = { ...data };

    setLoading(true);

    try {
      const response = await restClient.post(
        `/employees/${employee.id}/change-email`,
        payload
      );

      const messageToast =
        response?.data?.data?.message || 'Email alterado com sucesso.';

      toastSuccess({ messageToast, handleCallback: toggleOpen() });
      onUpdateEmployee(response.data.data);
    } catch (error) {
      console.error(error);
      const messageToast = error?.message ?? 'Erro ao alterar email!';

      toastError({ messageToast, handleCallback: toggleOpen() });
      setLoading(false);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  return (
    <Dialog
      onClose={toggleOpen}
      fullWidth
      open={open}
      TransitionProps={{
        onExited: () => {
          reset();
        },
      }}
    >
      <form onSubmit={handleSubmit(handleUpdate)}>
        <DialogTitle>Alteração de email</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2">
              O email é utilizado para o usuário entrar na plataforma. Após essa
              alteração, o usuário precisará usar o novo email para isso.
            </Typography>
          </Stack>
          <Divider sx={{ my: 2 }} />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormLabel>Novo email*</FormLabel>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="nome@nomedaempresa.com.br"
                    variant="outlined"
                    helperText={error?.message || ' '}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={toggleOpen} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            loading={loading}
            loadingPosition="start"
            type="submit"
            variant="contained"
          >
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditEmployeeEmail;
