import * as React from 'react';
import { Grid, Typography, Link, Chip, Divider } from '@mui/material';
import {
  Dialog,
  DialogContent,
  IconButton,
  CircularProgress,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { formatMoney } from '@/react/utils/money';
import { formatDate } from '@/react/utils/date';
import { PriceTypes } from '@/react/data/catalog/interfaces';
import {
  Months,
  PaymentReceiptTypes,
  PurchaseReceiptTypes,
  SupplierType,
} from '@/react/components/refund/refund-request/invoice-step/interfaces';
import {
  RefundRequestStatus,
  ResourceApproval,
} from '@/react/data/subsidy/interfaces';
import { messageCancelled } from '@/react/components/refund/utils/messages-from-steps';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { base64ToBlob } from '@/react/components/refund/refund-request/invoice-step/sending-receipts-form/formValuesHelper';

export const ShowRefundRequest = ({
  resource_approval,
}: {
  resource_approval: ResourceApproval;
}) => {
  const receiptAttachmentsByType = (receipts, type) => {
    return receipts
      ?.filter((receipt) => type.includes(receipt.type))
      ?.flatMap((receipt) => receipt.attachments);
  };

  const ImagePreviewModal = ({ files }) => {
    const [open, setOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [preloadedImages, setPreloadedImages] = React.useState({});

    const { isSmallScreen } = useScreenSizes();
    const isMobile = isSmallScreen;

    React.useEffect(() => {
      const preloadImages = async () => {
        const imageBlobs = {};
        for (const file of files) {
          try {
            const blob = base64ToBlob(file.blob, file.content_type);
            imageBlobs[file.url] = URL.createObjectURL(blob);
          } catch (error) {
            console.error('❌ Error preloading image:', error);
          }
        }
        setPreloadedImages(imageBlobs);
      };

      preloadImages();
    }, [files]);

    const handleOpen = () => {
      setCurrentIndex(0);
      setLoading(true);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setLoading(true);
    };

    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % files.length);
      setLoading(true);
    };

    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? files.length - 1 : prevIndex - 1
      );
      setLoading(true);
    };

    return (
      <>
        <IconButton onClick={handleOpen} aria-label="Preview Files">
          <VisibilityIcon />
        </IconButton>

        <Dialog open={open} onClose={handleClose} maxWidth="lg">
          <DialogContent
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              width: isMobile ? '90vw' : '80vw',
              height: isMobile ? '60vh' : '65vh',
              padding: '20px',
              overflow: 'hidden',
            }}
          >
            {files.length > 1 && (
              <IconButton
                onClick={handlePrev}
                style={{
                  position: 'absolute',
                  left: 0,
                  zIndex: 10,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  borderRadius: '50%',
                  marginLeft: '10px',
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}

            <div
              style={{
                width: isMobile ? '85vw' : '80vw',
                height: isMobile ? '55vh' : '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: '#f0f0f0',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              {loading && (
                <CircularProgress size={50} style={{ position: 'absolute' }} />
              )}

              {files[currentIndex].url.endsWith('.pdf') ? (
                <embed
                  src={files[currentIndex].url}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                  style={{
                    borderRadius: '8px',
                    display: loading ? 'none' : 'block',
                  }}
                  onLoad={() => setLoading(false)}
                />
              ) : (
                <img
                  src={
                    preloadedImages[files[currentIndex].url] ||
                    files[currentIndex].url
                  }
                  alt={files[currentIndex].alt || 'Imagem'}
                  onLoad={() => setLoading(false)}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    borderRadius: '8px',
                    display: loading ? 'none' : 'block',
                  }}
                />
              )}
            </div>

            {files.length > 1 && (
              <IconButton
                onClick={handleNext}
                style={{
                  position: 'absolute',
                  right: 0,
                  zIndex: 10,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  borderRadius: '50%',
                  marginRight: '10px',
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <>
      {resource_approval &&
        [RefundRequestStatus.rejected, RefundRequestStatus.cancelled].includes(
          resource_approval.status
        ) && (
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              justifyContent="center"
              sx={({ spacing }) => ({
                paddingBottom: spacing('0.5'),
                marginLeft: spacing(2),
                marginBottom: spacing(1),
              })}
            >
              <Chip
                label={`${messageCancelled(resource_approval)} ${formatDate(resource_approval?.refund_request?.decided_at)}`}
                variant="negativeRequestResponse"
              />
            </Grid>
          </Grid>
        )}

      <Grid
        item
        xs={12}
        sx={({ spacing }) => ({
          paddingTop: spacing(0.5),
          paddingBottom: spacing(2),
        })}
      >
        <Divider />
      </Grid>
      <Grid container rowSpacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Data do pagamento
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatDate(resource_approval?.refund_request?.payment_date) ||
              '--'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Comprovante de pagamento
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid item xs={12} md={9}>
            <Typography
              variant="body3"
              style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
            >
              {receiptAttachmentsByType(
                resource_approval.refund_request?.receipts,
                PaymentReceiptTypes
              )?.map((attachment, index) => {
                return (
                  <Link
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    style={{ marginRight: '10px' }}
                  >
                    {attachment.content?.filename}
                  </Link>
                );
              })}
              <ImagePreviewModal
                files={receiptAttachmentsByType(
                  resource_approval.refund_request?.receipts,
                  PaymentReceiptTypes
                )?.map((attachment) => ({
                  url: attachment.url,
                  alt: attachment.content?.filename,
                  blob: attachment.content?.blob,
                  content_type: attachment.content?.content_type,
                }))}
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            {resource_approval.sale_type === PriceTypes.tuition
              ? 'Valor da mensalidade'
              : 'Valor da compra'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatMoney(resource_approval.refund_request?.purchase_amount)}
          </Typography>
        </Grid>

        {resource_approval.sale_type === PriceTypes.tuition && (
          <>
            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Mês de referência
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {Months[resource_approval.refund_request?.reference_month]}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Nome do fornecedor
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {resource_approval.refund_request.supplier_name}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            {resource_approval.refund_request?.supplier_type ===
            SupplierType.legal_person
              ? 'CNPJ'
              : 'CPF'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {resource_approval.refund_request?.supplier_document}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Valor do pagamento
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatMoney(resource_approval.refund_request?.payment_amount)}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Data da compra
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatDate(resource_approval.refund_request?.purchase_date) ||
              '--'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Comprovante de compra
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid item xs={12} md={9}>
            <Typography
              variant="body3"
              style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
            >
              {receiptAttachmentsByType(
                resource_approval.refund_request?.receipts,
                PurchaseReceiptTypes
              )?.map((attachment, index) => (
                <Link
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  style={{ marginRight: '10px' }}
                >
                  {attachment.content?.filename}
                </Link>
              ))}

              <ImagePreviewModal
                files={receiptAttachmentsByType(
                  resource_approval.refund_request?.receipts,
                  PurchaseReceiptTypes
                )?.map((attachment) => ({
                  url: attachment.url,
                  alt: attachment.content?.filename,
                  blob: attachment.content?.blob,
                  content_type: attachment.content?.content_type,
                }))}
              />
            </Typography>
          </Grid>
        </Grid>

        {resource_approval.refund_request?.banking_info?.pix_key ? (
          <>
            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Chave PIX
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {resource_approval.refund_request?.banking_info?.pix_key}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Número da conta
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {resource_approval.refund_request?.banking_info?.account_number}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Banco
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {resource_approval.refund_request?.banking_info?.bank}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Agência
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {resource_approval.refund_request?.banking_info?.agency}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
