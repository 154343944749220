import * as React from 'react';
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  FormHelperText
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { useWizard } from '@/react/components/wizard/state/useWizard';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import StepActions from '@/react/components/wizard/step-actions.component';
import { MoneyField } from '@/react/components/form';
import ExitRefundRequestFlowDialog from '@/react/components/refund/refund-request/exit-refund-request-flow-dialog.component';
import { PriceTypes } from '@/react/data/catalog/interfaces';
import { ResourceTypes } from '@/react/data/subsidy/interfaces';

interface LearningAidFormProps {
  resourceType: ResourceTypes;
}

const defaultValues = {
  name: '',
  amount: '',
  link: '',
  requestReason: '',
  salesType: PriceTypes.one_off,
};

const getNameErrorMessage = (resourceType: ResourceTypes) => {
  switch (resourceType) {
    case ResourceTypes.BOOK:
      return 'Nome do Livro obrigatório';
    case ResourceTypes.EVENT_OR_LECTURE:
      return 'Nome do Evento obrigatório';
    case ResourceTypes.OTHERS:
      return 'Nome do Item obrigatório';
    default:
      return 'Nome obrigatório';
  }
};

const mainValidationSchema = (resourceType: ResourceTypes) => z.object({
  name: z.string().min(1, { message: getNameErrorMessage(resourceType) }),
  amount: z.coerce
    .number()
    .min(1, 'Valor obrigatório')
    .positive('Valor deve ser maior que zero'),
  link: z.union([z.literal(''), z.string().trim().url()]),
  requestReason: z.string(),
});

const saleTypeSchema = (resourceType: ResourceTypes) => {
  return z.object({
    salesType: z.nativeEnum(PriceTypes).superRefine((value, ctx) => {
      if (resourceType === ResourceTypes.OTHERS && !value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Tipo de pagamento obrigatório',
        });
      }
    }),
  });
};

const formValidationSchema = (resourceType: ResourceTypes) => mainValidationSchema(resourceType).and(saleTypeSchema(resourceType));

export const wizardStepIdsMapping = {
  [ResourceTypes.BOOK]: WizardStepIds.EXTERNAL_BOOK_FORM,
  [ResourceTypes.EVENT_OR_LECTURE]: WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM,
  [ResourceTypes.OTHERS]: WizardStepIds.EXTERNAL_OTHER_FORM,
}

const LearningAidForm: React.FC<LearningAidFormProps> = ({ resourceType }) => {
   
  const { actions, values } = useWizard();
  const formValues = values[wizardStepIdsMapping[resourceType]];
  const [exitCallback, setExitCallback] = React.useState(() => () => {});
  const [showDialog, setShowDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(formValidationSchema(resourceType)),
  });

  const { salesType } = watch();
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    reset({ ...defaultValues, ...formValues });
  }, [formValues]);

  React.useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const handleNext = async (data) => {

    const formData = { ...data };
    Object.keys(formData).forEach((key) => {
      return formData[key] === undefined && delete formData[key];
    });

    const payload = {
      ...defaultValues,
      ...formData,
    };

    if (resourceType === ResourceTypes.BOOK) {
      actions.updateValues({
        [WizardStepIds.EXTERNAL_BOOK_FORM]: payload,
      });
    } else if (resourceType === ResourceTypes.EVENT_OR_LECTURE) {
      actions.updateValues({
        [WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM]: payload,
      });
    } else if (resourceType === ResourceTypes.OTHERS) {
      actions.updateValues({
        [WizardStepIds.EXTERNAL_OTHER_FORM]: payload,
      });
    }

    actions.navigate(WizardStepIds.SUMMARY);
  };

  const handleErrormessage = (error) => {
    return error ? error.message : '';
  };

  const handleGoBack = () => {
    if (isDirty) {
      setExitCallback(
        () => () => actions.navigate(WizardStepIds.EXTERNAL_RESOURCE_TYPE_FORM)
      );
      setShowDialog(true);
    } else {
      actions.navigate(WizardStepIds.EXTERNAL_RESOURCE_TYPE_FORM);
    }
  };

  const defineLabelInputName = (resourceType) => {
    const labelMapping = {
      [ResourceTypes.BOOK]: 'Nome do Livro',
      [ResourceTypes.EVENT_OR_LECTURE]: 'Nome do Evento',
      [ResourceTypes.OTHERS]: 'Nome do item',
    }
  
    return labelMapping[resourceType];
  }
  
  const definePlaceHolderInputName = (resourceType) => {
    const placeHolderMapping = {
      [ResourceTypes.BOOK]: 'Digite o nome do Livro',
      [ResourceTypes.EVENT_OR_LECTURE]: 'Digite o nome do Evento',
      [ResourceTypes.OTHERS]: 'Digite o nome do item',
    }
  
    return placeHolderMapping[resourceType];
  }

  const defineCommonId = (resourceType) => {
    const idMapping = {
      [ResourceTypes.BOOK]: 'book',
      [ResourceTypes.EVENT_OR_LECTURE]: 'event_or_lecture',
      [ResourceTypes.OTHERS]: 'others',
    }
  
    return idMapping[resourceType];
  }

  return (
    <>
      <form
        data-testid={wizardStepIdsMapping[resourceType]}
        onSubmit={handleSubmit(handleNext)}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, sm: 12 }}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <Stack spacing={1}>
                    <FormLabel htmlFor="name">{defineLabelInputName(resourceType)}</FormLabel>
                    <TextField
                      {...field}
                      error={!!error}
                      id={`${defineCommonId(resourceType)}-name`}
                      helperText={handleErrormessage(error)}
                      inputProps={{ 'data-testid': 'name-input' }}
                      placeholder={definePlaceHolderInputName(resourceType)}
                      variant="outlined"
                    />
                  </Stack>
                </FormControl>
              )}
            />
          </Grid>
          {resourceType === ResourceTypes.OTHERS ? (
            <>
              <Grid size={{ xs: 12 }}>
                <Controller
                  control={control}
                  name="salesType"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error}>
                      <Stack spacing={1}>
                        <FormLabel>
                          Qual é o formato de pagamento do item?
                        </FormLabel>
                        <RadioGroup {...field} aria-labelledby="salesType">
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                inputProps={
                                  {
                                    'data-testid': 'salesType-radio1',
                                  } as React.InputHTMLAttributes<HTMLInputElement>
                                }
                              />
                            }
                            label={
                              <Typography variant="body3">
                                Compra único
                              </Typography>
                            }
                            value={PriceTypes.one_off}
                          />
                          <FormControlLabel
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="body3">Mensalidade</Typography>
                            }
                            value={PriceTypes.tuition}
                          />
                        </RadioGroup>
                      </Stack>
                      <FormHelperText>{handleErrormessage(error)}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Controller
                  control={control}
                  name="amount"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="amount">
                            {salesType === PriceTypes.one_off
                              ? 'Valor total (R$)'
                              : 'Valor por mês (R$)'}
                          </FormLabel>
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={handleErrormessage(error)}
                            id="amount"
                            inputProps={{
                              currency: 'BRL',
                              'data-testid': 'amount-input',
                            }}
                            InputProps={{
                              inputComponent: MoneyField as any,
                              startAdornment: (
                                <InputAdornment
                                  disableTypography
                                  position="start"
                                >
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                          />
                        </Stack>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
            </>
          ) : 
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                control={control}
                name="amount"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="amount">{'Valor (R$)'}</FormLabel>
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          id={`${defineCommonId(resourceType)}-amount`}
                          inputProps={{
                            currency: 'BRL',
                            'data-testid': 'amount-input',
                          }}
                          InputProps={{
                            inputComponent: MoneyField as any,
                            startAdornment: (
                              <InputAdornment
                                disableTypography
                                position="start"
                              >
                                R$
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                      </Stack>
                    </FormControl>
                  );
                }}
              />
            </Grid>
          }
          <Grid size={{ xs: 12 }}>
            <Controller
              control={control}
              name="link"
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <Stack spacing={1}>
                    <FormLabel htmlFor="link">
                      Link do item
                      <Box component="span" sx={{ pl: 1, fontWeight: 400, fontSize: '12px' }}>
                        opcional
                      </Box>
                    </FormLabel>
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={handleErrormessage(error)}
                      inputProps={{ 'data-testid': 'link-input' }}
                      id={`${defineCommonId(resourceType)}-link`}
                      placeholder="Ex.: www.link.com"
                      variant="outlined"
                    />
                  </Stack>
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Controller
              control={control}
              name="requestReason"
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <Stack spacing={1}>
                    <FormLabel htmlFor="requestReason">
                      Descrição ou justificativa
                      <Box component="span" sx={{ pl: 1, fontWeight: 400, fontSize: '12px' }}>
                        opcional
                      </Box>
                    </FormLabel>
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={handleErrormessage(error)}
                      inputProps={{ 'data-testid': 'requestReason-input' }}
                      id={`${defineCommonId(resourceType)}-requestReason`}
                      multiline
                      placeholder="Descreva o motivo da compra para avaliação da sua empresa."
                      rows={4}
                      variant="outlined"
                    />
                  </Stack>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <StepActions>
          <Button color="primary" onClick={handleGoBack} variant="text">
            Voltar
          </Button>
          <Button color="primary" type="submit" variant="contained">
            Próximo
          </Button>
          <ExitRefundRequestFlowDialog
            open={showDialog}
            onClose={handleCloseDialog}
            onConfirm={exitCallback}
          />
        </StepActions>
      </form>
    </>
  );
};

export default LearningAidForm;
