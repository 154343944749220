import * as React from 'react';
interface CustomIconProps {
  svg: string;
  alt: string;
}

const CustomIcon: React.FC<CustomIconProps> = ({ svg, alt }) => (
  <img src={svg} alt={alt} />
);

export default CustomIcon;
