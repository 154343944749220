import * as     React from 'react';

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { ResourceApprovalDecisionType } from '@/react/data/subsidy/interfaces';

export type ConfirmResourceApprovalDecisionDialogProps = {
	decision:  ResourceApprovalDecisionType;
	open: boolean;
	onClose: () => void;
  onConfirm: (comment: string) => void;
};

const ConfirmResourceApprovalDecisionDialog: React.FC<
	ConfirmResourceApprovalDecisionDialogProps
> = ({
	decision,
	open: externalOpen,
	onClose,
  onConfirm,
}) => {
	const [open, setOpen] = React.useState(externalOpen);
  const [comment, setComment] = React.useState('');
  const [error, setError] = React.useState('');

	React.useEffect(() => {
		setOpen(externalOpen);
	}, [externalOpen]);

  const handleConfirm = () => {
    if (decision === ResourceApprovalDecisionType.REJECTED && !comment.trim()) {
      setError('É obrigatório justificar a rejeição de uma solicitação');
      return;
    }
    onConfirm(comment);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    if (e.target.value.trim()) {
      setError('');
    }
  };

  const renderApprovalDialog = () => {
    return (
      <>
	  	  <DialogTitle>Aprovar item</DialogTitle>
	  	  <DialogContent>
	  	  	<Stack spacing={2}>
	  	  		<Typography variant="body3" sx={{ fontWeight: 400 }}>
	  	  			Você está aprovando um item para reembolso. Após aprovação, o solicitante receberá um email de confirmação e seguirá para a etapa do envio de comprovantes.
	  	  		</Typography>
	  	  		<TextField
              label="Justificativa"
              placeholder="Você pode escrever uma justificativa aqui se quiser ;)"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
	  	  	</Stack>
	  	  </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            sx={{
              padding: '14px 16px',
              backgroundColor: 'neutral.50',
              color: 'neutral.800',
              width: 101,
              height: 48,
              borderRadius: 0,
              '&:hover': {
                backgroundColor: 'neutral.50',
                color: 'neutral.800',
               }
            }} 
            onClick={onClose}>
              Cancelar
          </Button>
          <Button
            variant="contained"
            sx={{
              padding: '14px 16px',
              width: 128,
              height: 48,
              borderRadius: 0,
              backgroundColor: 'success.700',
              '&:hover': { backgroundColor: 'success.700' }
            }}
            onClick={handleConfirm}
          >
            Aprovar item
          </Button>
        </DialogActions>
	    </>
    )
  }

  const renderRejectionDialog = () => {
    return (
      <>
        <DialogTitle>Recusar item</DialogTitle>
	  	  <DialogContent>
	  	  	<Stack spacing={2}>
	  	  		<Typography variant="body3" sx={{ fontWeight: 400 }}>
              Você está recusando o pedido de um item para reembolso. Para finalizar a ação, descreva o motivo abaixo e envie-o para o solicitante.
	  	  		</Typography>
	  	  		<TextField
              label="Justificativa"
              placeholder="Escreva a justificativa aqui..."
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={comment}
              error={!!error}
              helperText={error}
              required
              onChange={handleCommentChange}
            />
	  	  	</Stack>
	  	  </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            sx={{
              padding: '14px 16px',
              backgroundColor: 'neutral.50',
              color: 'neutral.800',
              width: 101,
              height: 48,
              borderRadius: 0,
              '&:hover': {
                backgroundColor: 'neutral.50',
                color: 'neutral.800',
               }
            }} 
            onClick={onClose}>
              Cancelar
          </Button>
          <Button
            variant="contained"
            sx={{
              padding: '14px 16px',
              width: 128,
              height: 48,
              borderRadius: 0,
              backgroundColor: '#E3005F',
              '&:hover': { backgroundColor: '#E3005F' }
            }}
            onClick={handleConfirm}
          >
            Recusar item
          </Button>
        </DialogActions>
      </>
    )
  }

	return (
	  <Dialog open={open} onClose={onClose}>
	  	{decision === ResourceApprovalDecisionType.APPROVED ? renderApprovalDialog() : renderRejectionDialog()}
	  </Dialog>
	)
};

export default ConfirmResourceApprovalDecisionDialog;
