import * as React from 'react';

import { CursosSection } from './section.component';
import {
  RecommendationCarousel,
  RecommendationCarouselLoading,
} from './recommendation-carousel';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useState } from 'react';
import { GlobalProfileStateEmitter } from '@/react/components/profile-quiz/global-profile-emitter';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { themeValue } from '@/react/components/quiz/utils/media-query';

const Subtitle = styled('span')`
  a {
    color: ${themeValue('palette.primary.main')};
    font-weight: bold;
  }
`;

export function HomeRecommendations() {
  const [showResetModal, setShowResetModal] = useState(false);

  const data = GlobalProfileStateEmitter.useQuizState((state, { loading }) => {
    const { recommendations, profile } = state;
    return {
      profile,
      recommendations,
      loading,
    };
  });

  const { value, resetting } = data;

  const { recommendations, profile } = value || {};

  const isLoading = (() => {
    if (resetting) {
      // considering as not loading to avoiding
      // showing the Quiz with a loading behind it
      return false;
    }

    const { pollingRecommendations } = data;
    if (pollingRecommendations) return true;

    if (profile?.completed_at && !recommendations) {
      return true;
    }

    const { loading: globalProfileLoading } = data;

    return (
      recommendations?.processing_status === 'pending' || globalProfileLoading
    );
  })();

  const content = (() => {
    if (isLoading) {
      return <RecommendationCarouselLoading />;
    }

    const generated = recommendations?.processing_status === 'completed';

    if (generated) {
      const courses = recommendations.courses;
      if (!courses?.length) return null;

      return <RecommendationCarousel slides={courses} />;
    }
  })();

  if (!content) return null;

  const subtitle = (
    <Subtitle>
      Essas recomendações foram geradas a partir dos seus interesses e
      objetivos. Para responder novamente,{' '}
      <a
        href="#"
        role="button"
        onClick={(ev) => {
          ev.preventDefault();
          setShowResetModal(true);
        }}
      >
        clique aqui
      </a>
      .
    </Subtitle>
  );

  return (
    <>
      <ModalResetProfile
        open={showResetModal}
        onReset={async () => {
          await GlobalProfileStateEmitter.load({
            forceResetProfile: true, //
          });
        }}
        onClose={() => {
          setShowResetModal(false);
        }}
      />

      <CursosSection title={'Recomendados para você'} subtitle={subtitle}>
        {content}
      </CursosSection>
    </>
  );
}

function ModalResetProfile(props: {
  onReset: () => Promise<void>;
  onClose: () => void;
  open: boolean;
}) {
  const { onReset: onResetProp, open, onClose: onCLoseProp } = props;

  const [status, setStatus] = useState<'idle' | 'loading'>('idle');

  function onClose() {
    if (status === 'loading') return;
    setStatus('idle');
    return onCLoseProp();
  }

  async function onReset() {
    if (status === 'loading') return;
    setStatus('loading');
    try {
      await onResetProp();
    } catch (e) {}
    setStatus('idle');
    onClose();
  }

  return (
    <React.Fragment>
      <Dialog
        fullScreen={false}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Tem certeza que deseja responder novamente?
        </DialogTitle>

        <DialogContent>
          <Typography variant={'body2'}>
            Isso substituirá suas definições de interesses e objetivos
            anteriores.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={onClose} color={'primary'} type={'reset'}>
            Cancelar
          </Button>

          <Button
            onClick={onReset}
            autoFocus
            loading={status === 'loading'}
            type={'submit'}
            color={'primary'}
            variant={'contained'}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
