import * as React from 'react';
import { Box, Skeleton, Typography, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { PageContainer, SectionHeader } from '@/react/components/idp/shared';
import { Wizard } from '@/react/components/wizard';
import { WizardProvider, useWizard } from '@/react/components/wizard/state';
import { WizardSteps } from '@/react/components/wizard/interfaces';
import { State as WizardState } from '@/react/components/wizard/state/interfaces';
import { ProfileProvider } from '@/react/components/idp/create-plan/state';

import { PlanDurationForm } from './plan-duration-form.component';
import { GenerateRecommendation } from './generate-recommendation.component';
import { PlanSkillSelectionForm } from './plan-skill-selection-form.component';
import { PlanProfile } from './plan-profile.component';
import { PlanRecommendationReview } from './plan-recommendation-review.component';
import { ConfirmDiscardRecommendationDialog } from '@/react/components/idp/dialogs/confirm-discard-recommendation-dialog.component';
import {
  useGetProfileBasedRecommendation,
  queryKey as useGetProfileBasedRecommendationQueryKey,
} from '@/react/data/idp/useGetProfileBasedRecommendation';
import {
  useDeleteRecommendation,
  useCompleteProfileBasedRecommendation,
  useUpdateProfileBasedRecommendation,
} from '@/react/data/idp';

import { ProfileBasedRecommendation } from '@/react/data/idp/profile';
import { IdpClient } from '@/react/data/idp/idp-client';

export enum StepsIds {
  DURATION = 'duration',
  GENERATE_RECOMMENDATION = 'generateRecommendation',
  PROFILE = 'profile',
  RECOMMENDATION_REVIEW = 'recommendationReview',
  RECOMMENDATION_TYPE = 'recommendationType',
  SKILL_SELECTION = 'skillSelection',
}

const handleBackToPlanList = () => {
  window.location.assign('/pdi/planos');
};

const createWizardState = (
  profileBasedRecommendation: Partial<ProfileBasedRecommendation>
) => {
  const defaultValues: WizardState['values'] = {
    [StepsIds.DURATION]: null,
    [StepsIds.SKILL_SELECTION]: null,
    [StepsIds.GENERATE_RECOMMENDATION]: null,
    [StepsIds.RECOMMENDATION_REVIEW]: null,
  };

  const state: WizardState = {
    currentStepId: profileBasedRecommendation.current_step ?? StepsIds.DURATION,
    values: {
      ...defaultValues,
      ...profileBasedRecommendationToWizardState(profileBasedRecommendation),
    },
  };

  return state;
};

const profileBasedRecommendationToWizardState = (
  profileBasedRecommendation: Partial<ProfileBasedRecommendation>
): WizardState['values'] => {
  const parser = {
    duration_in_months: (data) => ({
      [StepsIds.DURATION]: data,
    }),
    developing_skills: (data) => ({
      [StepsIds.SKILL_SELECTION]: data,
    }),
  };

  const profileToState = Object.entries(parser).reduce(
    (wizardState, [key, parse]) => {
      const value = profileBasedRecommendation[key];

      if (!value) {
        return wizardState;
      }
      return {
        ...wizardState,
        ...parse(value),
      };
    },
    {}
  );

  return profileToState;
};

const wizardStateToProfileBasedRecommendation = (
  state: WizardState['values'],
  profileBasedRecommendation: Partial<ProfileBasedRecommendation>
): Partial<ProfileBasedRecommendation> => {
  const parser = {
    [StepsIds.DURATION]: (values) => ({
      duration_in_months: Number(values),
      plan: {
        id: profileBasedRecommendation.plan.id,
        duration_in_months: Number(values),
      },
    }),
    [StepsIds.SKILL_SELECTION]: (values) => ({
      developing_skills: values.map(({ checked, ...skill }) => skill),
    }),
  };

  const stateToProfile: Partial<ProfileBasedRecommendation> = Object.entries(
    parser
  ).reduce((profileBasedRecommendation, [key, parse]) => {
    const value = state[key];

    if (!value) {
      return profileBasedRecommendation;
    }
    return {
      ...profileBasedRecommendation,
      ...parse(value),
    };
  }, {});

  return stateToProfile;
};

interface CreatePlanProps {
  profileBasedRecommendation: Partial<ProfileBasedRecommendation>;
}

const CreatePlan = ({ profileBasedRecommendation }: CreatePlanProps) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const { deleteRecommendation } = useDeleteRecommendation(
    profileBasedRecommendation.id,
    () => window.location.assign('/pdi/planos')
  );

  const queryClient = useQueryClient();
  const { updateProfileBasedRecommendation } =
    useUpdateProfileBasedRecommendation(profileBasedRecommendation.id);
  const { isPending, completeProfileBasedRecommendation } =
    useCompleteProfileBasedRecommendation(profileBasedRecommendation.id);

  const {
    currentStepId,
    actions: wizardActions,
    values: wizardValues,
  } = useWizard();
  const ref = React.useRef(true);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleBackNavigation = () => {
    handleModalOpen();
  };

  const updateSkillsList = async (stepId: StepsIds, skills) => {
    await IdpClient.updatePlanRecommendationsSkills(
      stepId,
      profileBasedRecommendation.id,
      skills
    );
    wizardActions.navigate(stepId);
  };

  React.useEffect(() => {
    if (currentStepId) {
      wizardActions.navigate(currentStepId);
    }
  }, [currentStepId]);

  const steps: WizardSteps = {
    [StepsIds.DURATION]: {
      component: PlanDurationForm,
      onNext: ({ stepValues }) => {
        wizardActions.updateValues({
          [StepsIds.DURATION]: stepValues,
        });
        wizardActions.navigate(StepsIds.PROFILE);
      },
    },
    [StepsIds.PROFILE]: {
      component: (props) => (
        <ProfileProvider>
          <PlanProfile {...props} />
        </ProfileProvider>
      ),
      onNext: () => {
        wizardActions.navigate(StepsIds.SKILL_SELECTION);
      },
      onPrevious: () => {
        wizardActions.navigate(StepsIds.DURATION);
      },
    },
    [StepsIds.SKILL_SELECTION]: {
      component: PlanSkillSelectionForm,
      onNext: ({ stepValues }) => {
        wizardActions.updateValues({
          [StepsIds.SKILL_SELECTION]: stepValues,
        });
        updateSkillsList(StepsIds.GENERATE_RECOMMENDATION, stepValues);
      },
      onPrevious: () => {
        wizardActions.navigate(StepsIds.PROFILE);
      },
    },
    [StepsIds.GENERATE_RECOMMENDATION]: {
      component: (props) => (
        <GenerateRecommendation
          {...props}
          generateRecommendation={updateSkillsList}
          profileBasedRecommendationId={profileBasedRecommendation.id}
        />
      ),
      onNext: async () => {
        await updateProfileBasedRecommendation({
          current_step: StepsIds.RECOMMENDATION_REVIEW,
        });
        queryClient.invalidateQueries({
          queryKey: useGetProfileBasedRecommendationQueryKey,
        });
      },
    },
    [StepsIds.RECOMMENDATION_REVIEW]: {
      component: (props) => (
        <PlanRecommendationReview
          {...props}
          profileBasedRecommendation={profileBasedRecommendation}
        />
      ),
      onNext: async ({ selectedActivitiesIds }) => {
        const result = (await completeProfileBasedRecommendation({
          currentStep: StepsIds.RECOMMENDATION_REVIEW,
          activities: selectedActivitiesIds.map((id) => ({ id })),
        })) as ProfileBasedRecommendation;

        window.location.assign(`/pdi/planos/${result.plan.id}`);
      },
    },
  };

  React.useEffect(() => {
    if (ref.current) {
      ref.current = false;
      return;
    }

    if (currentStepId !== StepsIds.GENERATE_RECOMMENDATION) {
      updateProfileBasedRecommendation({
        current_step: currentStepId as StepsIds,
        ...wizardStateToProfileBasedRecommendation(
          wizardValues,
          profileBasedRecommendation
        ),
      });
    }
  }, [JSON.stringify(wizardValues), currentStepId]);

  return (
    <PageContainer>
      <Box sx={{ py: 3 }}>
        <SectionHeader
          handleBackNavigation={handleBackNavigation}
          actions={[
            { label: 'Descartar plano', onClick: handleBackNavigation },
          ]}
          title="Voltar"
        />
        <Wizard steps={steps} />
      </Box>
      <ConfirmDiscardRecommendationDialog
        open={modalOpen}
        onCancel={handleModalClose}
        onContinueLater={handleBackToPlanList}
        onConfirm={deleteRecommendation}
      />
    </PageContainer>
  );
};

const CreatePlanWithContext = ({
  recommendationId,
}: {
  recommendationId: string;
}) => {
  const { isFetching, profileBasedRecommendation } =
    useGetProfileBasedRecommendation(recommendationId);

  if (isFetching) {
    return (
      <PageContainer>
        <Stack spacing={3}>
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              <Skeleton width="25%" />
            </Typography>
            <Stack spacing={2}>
              <Skeleton variant="rounded" height={80} />
            </Stack>
          </Box>
        </Stack>
      </PageContainer>
    );
  }

  const initialState = createWizardState(profileBasedRecommendation);

  return (
    <WizardProvider initialState={initialState}>
      <CreatePlan profileBasedRecommendation={profileBasedRecommendation} />
    </WizardProvider>
  );
};

export { CreatePlanWithContext, CreatePlan };
