import { RouterService } from 'src/app/services/router.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { LayoutService } from 'src/app/services/layout.service';
import { User } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import { getSvg } from 'src/app/utils/helpers';
import { BootstrapDataService } from '@/services/api/bootstrap_data.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pageContainer', { static: true })
  pageContainer: ElementRef;

  @ViewChild('scrollableContainer', { static: true })
  scrollableContainer: ElementRef<HTMLDivElement>;

  user: User;

  hideSearchButton: boolean = false;

  eduplayModal: boolean = false;
  modalDependent: boolean = false;
  modalFreemium: boolean = false;
  leroyModal: boolean = false;
  leroyFlag: boolean = true;

  leroyUser: boolean;
  paymentTopbar: boolean = false;

  subscriptions: Subscription[] = [];

  private readonly ROTA_PESQUISA = '/cursos/pesquisa';

  constructor(
    private router: Router,
    private routerService: RouterService,
    private userService: UsersService,
    private layoutService: LayoutService,
    private toastr: ToastrService,
    private bootstrapDataService: BootstrapDataService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.user;

    this.leroyUser = this.userService.user.isLeroy();

    document.getElementsByTagName('body')[0].style.overflow = 'hidden';

    if (this.bootstrapDataService.isLoaded) {
      this.checkModalDependent();
    } else {
      this.bootstrapDataService.planChange.subscribe(() => {
        this.checkModalDependent();
      });
    }

    this.hideSearchButton = this.router.url === this.ROTA_PESQUISA;

    this.subscriptions.push(
      this.layoutService.onError.subscribe((error) => {
        this.toastr.error(error);
      })
    );

    this.subscriptions.push(
      this.layoutService.onPaymentTopbar.subscribe((paymentTopbarDisplay) => {
        this.paymentTopbar = paymentTopbarDisplay;
      })
    );

    this.subscriptions.push(
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.hideSearchButton = val.url === this.ROTA_PESQUISA;
        }
      })
    );

    this.subscriptions.push(
      this.layoutService.onSuccess.subscribe((message) => {
        this.toastr.success(message);
      })
    );

    this.subscriptions.push(
      this.layoutService.onFreemiumModalChange.subscribe((display) => {
        this.modalFreemium = display;
      })
    );

    this.subscriptions.push(
      this.layoutService.onOpenModalDependent.subscribe(() => {
        if (
          this.user.hasDependent() ||
          (this.bootstrapDataService.plan?.features?.filter((feature) => feature.name === 'dependents_included').length > 0)
        ) {
          this.modalDependent = true;
        }
      })
    );

    this.subscriptions.push(
      this.layoutService.onScrollContainerTop.subscribe(() => {
        this.scrollContainerToTop();
      })
    );

    this.subscriptions.push(
      this.layoutService.onLeroyFlagDisplay.subscribe(
        (shouldLeroyDisplay: boolean) => {
          this.leroyFlag = shouldLeroyDisplay;
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  ngAfterViewInit() {
    const pageEl = this.pageContainer.nativeElement.childNodes[1];
    pageEl.style.flexGrow = 1;
    pageEl.style.display = 'flex';
    pageEl.style.flexDirection = 'column';
  }

  checkModalDependent() {
    const openedDependentModal = localStorage.getItem('dependentModal');
    if (
      !openedDependentModal &&
      !this.user.isDependent() &&
      (this.user.hasDependent() ||

        this.bootstrapDataService.plan?.features?.filter((feature) => feature.name === 'dependents_included').length > 0)
    ) {
      this.modalDependent = true;
    }
  }

  getSvg = getSvg;

  onPesquisar() {
    this.routerService.navigate([this.ROTA_PESQUISA]);
  }

  eduplayUrl() {
    return (
      this.user.colaborador.empresa.eduplay_custom ||
      'https://eduplay.eadbox.com'
    );
  }

  onCloseModal() {
    this.eduplayModal = false;
  }

  closeModalDependent() {
    this.modalDependent = false;
    localStorage.setItem('dependentModal', 'true');
  }

  checkLeroyModal() {
    return this.leroyModal;
  }

  openLeroyModal() {
    this.leroyModal = true;
  }

  closeLeroyModal() {
    this.leroyModal = false;
  }

  scrollContainerToTop() {
    this.scrollableContainer.nativeElement.scroll({ top: 0 });
  }
}

export function goToDependents() {
  window.open(
    'https://docs.google.com/forms/d/e/1FAIpQLSdBrf3lkzhl8SE-rLrGZ11xEC7Nlp-VYHrLX7D_ZJXx8NuRIQ/viewform'
  );
}
