import { useQuery } from '@tanstack/react-query';
import {
  RequestRefundableAmountResponse,
  RequestRefundableAmount,
} from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from './subsidy-client';

export function useRequestRefundableAmount(
  requestParams: RequestRefundableAmount
) {
  const result = useQuery<RequestRefundableAmountResponse>({
    queryKey: ['useRequestRefundableAmount', requestParams],
    refetchOnMount: false,
    queryFn: async ({ signal }) => {
      const response = await SubsidyClient.getRefundableAmount(
        requestParams.refund_amount_requested,
        {
          resourceApprovalUuid: requestParams.resource_approval_uuid,
          refundRequestUuid: requestParams?.refund_request_uuid,
        }
      );

      return response as RequestRefundableAmountResponse;
    },
  });
  const requestRefundableAmount: Partial<RequestRefundableAmountResponse> =
    result.data || {};

  return {
    requestRefundableAmount,
    isFetching: result.isFetching,
    error: !!result.error,
  };
}
