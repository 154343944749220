import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ResourceTypes } from '@/react/data/subsidy/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { Button, Grid, Typography } from '@mui/material';
import StepActions from '@/react/components/wizard/step-actions.component';
import CustomIcon from '@/react/components/custom-icon/custom-icon.component';
import BookIconSvg from '@/react/assets/images/book-icon.svg';
import CourseIconSvg from '@/react/assets/images/course-icon.svg';
import EventsAndLecturesIconSvg from '@/react/assets/images/events-and-lectures-icon.svg';
import OthersIconSvg from '@/react/assets/images/others-icon.svg';

const Styled = {
  Button: styled(Button)(({ theme: { palette, spacing } }) => ({
    alignItems: 'center',
    backgroundColor: palette.neutral[50],
    border: `1px solid ${palette.neutral[300]}`,
    borderRadius: 0,
    color: palette.neutral[800],
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    padding: spacing(3),
    height: '120px',
    width: '100%',

    '&.active': {
      backgroundColor: palette.primary[100],
      border: `1px solid ${palette.primary[900]}`,
      color: palette.neutral[900],
    },
  })),
};

const stepId = WizardStepIds.EXTERNAL_RESOURCE_TYPE_FORM;

export const ExternalResourceTypeForm = () => {
  const { actions, values: wizardValues } = useWizard();
  const [selected, setSelected] = React.useState<ResourceTypes>(
    wizardValues[stepId]
  );

  const handleSelection = (source: ResourceTypes) => {
    setSelected(source);
  };

  const handleNext = () => {
    actions.updateValues({
      [stepId]: selected,
      [WizardStepIds.RESOURCE_TYPE]: selected,
    });

    let destination;
    if (selected === ResourceTypes.COURSE) {
      destination = WizardStepIds.EXTERNAL_COURSE_FORM;
    } else if (selected === ResourceTypes.EVENT_OR_LECTURE) {
      destination = WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM;
    } else if (selected === ResourceTypes.BOOK) {
      destination = WizardStepIds.EXTERNAL_BOOK_FORM;
    } else if (selected === ResourceTypes.OTHERS) {
      destination = WizardStepIds.EXTERNAL_OTHER_FORM;
    }

    actions.navigate(destination);
  };

  const cssClass = (source: ResourceTypes) =>
    selected === source ? 'active' : '';

  return (
    <div data-testid={WizardStepIds.EXTERNAL_RESOURCE_TYPE_FORM}>
      <Typography variant="h6" color="primary.900" mb={2}>
        Estou pedindo reembolso para:
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Styled.Button
            className={cssClass(ResourceTypes.COURSE)}
            onClick={() => handleSelection(ResourceTypes.COURSE)}
          >
            <CustomIcon svg={CourseIconSvg} alt="ícone cursos" />
            <Typography variant="body3" fontWeight={600}>
              Cursos
            </Typography>
          </Styled.Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Styled.Button
            className={cssClass(ResourceTypes.BOOK)}
            onClick={() => handleSelection(ResourceTypes.BOOK)}
          >
            <CustomIcon svg={BookIconSvg} alt="ícone livros" />
            <Typography variant="body3" fontWeight={600}>
              Livros
            </Typography>
          </Styled.Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Styled.Button
            className={cssClass(ResourceTypes.EVENT_OR_LECTURE)}
            onClick={() => handleSelection(ResourceTypes.EVENT_OR_LECTURE)}
          >
            <CustomIcon
              svg={EventsAndLecturesIconSvg}
              alt="ícone eventos e palestras"
            />
            <Typography variant="body3" fontWeight={600}>
              Eventos e Palestras
            </Typography>
          </Styled.Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Styled.Button
            className={cssClass(ResourceTypes.OTHERS)}
            onClick={() => handleSelection(ResourceTypes.OTHERS)}
          >
            <CustomIcon svg={OthersIconSvg} alt="ícone outros" />
            <Typography variant="body3" fontWeight={600}>
              Outros
            </Typography>
          </Styled.Button>
        </Grid>
      </Grid>
      <StepActions>
        <Button
          color="primary"
          onClick={() => {
            actions.navigate(WizardStepIds.ITEM_SOURCE_FORM);
          }}
          variant="text"
        >
          Voltar
        </Button>
        <Button
          color="primary"
          disabled={!selected}
          onClick={handleNext}
          type="submit"
          variant="contained"
        >
          Próximo
        </Button>
      </StepActions>
    </div>
  );
};

export default ExternalResourceTypeForm;
