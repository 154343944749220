import cx from 'clsx';
import { QuestionKind, QuestionNode } from '../quiz.interfaces';
import React, { ReactNode } from 'react';
import { useQuiz } from '../state/context';
import { styledBoxes } from '../utils/styled-boxes';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { QuizStateValue, QuizState } from '@/react/components/quiz';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled, css } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  spacing,
  upMediumScreen,
} from '@/react/components/quiz/utils/media-query';

const Row = styled(Stack)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  flex-wrap: nowrap;
`;

const Styled = styledBoxes('Question', {
  Container: {
    padding: 3,
    paddingBottom: 0,
    gap: 2,
    component: styled(Stack)`
      display: grid;
      grid-template-areas: 'header' 'progress' 'main' 'footer';
      grid-template-rows: auto 20px 1fr 60px;
      grid-template-columns: 100%;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100vw;
      height: 100vh;
      max-width: 100%;
      overflow: hidden;
      max-height: min(calc(100vh - 70px), 600px);

      &.kind-presentation {
        grid-template-rows: 0 0 1fr 0;
        gap: 0;

        &.has-actions {
          grid-template-rows: 0 0 1fr 60px;
        }
      }
    `,
  },

  Title: {
    component: styled(DialogTitle)`
      line-height: 1.2;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      &.canClose {
        > span {
          display: inline-block;
          max-width: calc(100% - 40px);
        }
      }
    `,
  },

  Progress: {
    component: styled(Row)`
      flex-direction: row;
      grid-area: progress;
    `,
  },

  Main: {
    paddingTop: 1,
    component: styled(Row)`
      flex-direction: column;
      grid-area: main;
      max-height: min(100%, 410px);
      min-height: 200px;
      overflow: hidden;
      justify-content: flex-start;
      align-items: stretch;
      align-self: center;
      overflow-y: auto;

      &.kind-presentation {
        gap: 0;
        height: 100%;
        max-height: 100%;
        padding: ${spacing(3)} 0;
      }

      &.kind-tag_selection {
        overflow: visible;
      }

      ${upMediumScreen} {
        align-self: flex-start;
        &:not(.kind-presentation) {
          padding-top: ${spacing(2)};
        }
      }
    `,
  },

  Footer: {
    component: styled(Row)`
      flex-direction: row;
      grid-area: footer;
      justify-content: space-between;
    `,
  },
});

export function QuestionWrapper<Kind extends QuestionKind>(props: {
  className?: cx.ClassValue;
  kind: Kind;
  render: (node: QuestionWrapperNode) => ReactNode;
}) {
  const { className, render, kind } = props;

  const [node, context] = useQuiz((state) => {
    return state.nodeById[state.activeNodeId] as unknown as QuestionNode<Kind>;
  });

  const [previous] = useQuiz((state) => {
    return state.nodeById[state.previousActiveNodeId];
  });

  const [hasRequiredNodesEmpty] = useQuiz((state) => {
    return state.hasRequiredNodesEmpty;
  });

  if (!node || node.kind !== kind) {
    console.error({ question: node, kind });
    throw new Error(`invalid question kind`);
  }

  const current = () => {
    const current = context.current();
    return current.nodeById[node.id] as unknown as QuestionNode<Kind>;
  };

  const nodeUtils: QuestionNodeUtils<QuestionNode<Kind>> = {
    exitQuiz: context.exitQuiz,
    goBack: context.goBack,
    setErrors: context.setActiveNodeErrors,
    setValue: (payload) => {
      const { value: previous } = nodeUtils.current().draftState;

      const generated =
        typeof payload === 'function' ? payload(previous) : payload;

      return context.setActiveNodeValue(generated);
    },
    lastSubmittedValue: current().value,
    setMetadata: (payload) => {
      const previous = nodeUtils.current().meta;
      const meta = typeof payload === 'function' ? payload(previous) : payload;
      return context.setActiveNodeMetadata(meta);
    },
    submit: context.submitActiveNode,
    forceCloseQuiz() {
      context.update((draft) => {
        if (draft.hasRequiredNodesEmpty) {
          console.error("can't close with `RequiredNodesEmpty`");
          return;
        } else {
          draft.activeNodeId = null;
        }
      });
    },
    current,
  };

  const {
    title,
    errors,
    draftState: { value },
    dialogProps,
    showFooterActions = true,
  } = node;

  const { maxWidth } = dialogProps;
  const isPresentation = kind === 'presentation';
  const hasErrors = !isPresentation && !!errors.length;
  const disabled = hasErrors || (!value.length && !isPresentation);

  const isOptional = !node.required;
  const showProgress = !isPresentation;
  const showTitle = !isPresentation;
  const showSkipButton = isOptional && !isPresentation;
  const canClose = !hasRequiredNodesEmpty;

  return (
    <>
      <Dialog
        data-id={'question-wrapper'}
        maxWidth={maxWidth}
        hideBackdrop
        className={cx(className)}
        open={true}
        fullWidth
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Styled.Container
          className={[{ 'has-actions': showFooterActions }, `kind-${kind}`]}
        >
          {/**/}

          <Styled.Title id="scroll-dialog-title" className={{ canClose }}>
            {showTitle && (
              <>
                <span>{title}</span>

                {canClose && (
                  <IconButton onClick={() => nodeUtils.exitQuiz()}>
                    <CloseIcon />
                  </IconButton>
                )}
              </>
            )}
          </Styled.Title>

          <Styled.Progress>{showProgress && <Progress />}</Styled.Progress>

          <Styled.Main className={[`kind-${kind}`]}>
            {render(
              // @ts-ignore
              {
                ...node,
                ...nodeUtils,
                ...context.current(),
                ...node.props,
                value: node.draftState.value,
              }
            )}
          </Styled.Main>

          <Styled.Footer>
            {showFooterActions && (
              <>
                <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                  {showSkipButton && (
                    <Button
                      color={'primary'}
                      variant={'text'}
                      onClick={() => context.submitActiveNode()}
                    >
                      Pular
                    </Button>
                  )}
                </Stack>

                <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                  {previous ? (
                    <Button
                      color={'primary'}
                      type={'reset'}
                      onClick={() => context.goBack()}
                    >
                      <ArrowBack />
                    </Button>
                  ) : null}

                  <Button
                    color={disabled ? 'secondary' : 'primary'}
                    variant={'contained'}
                    type={'submit'}
                    disabled={disabled}
                    onClick={() => context.submitActiveNode()}
                  >
                    <ArrowForward />
                  </Button>
                </Stack>
              </>
            )}
          </Styled.Footer>
        </Styled.Container>
      </Dialog>

      {mobileStyles.render()}
    </>
  );
}

const StyledProgress = styledBoxes('Progress', {
  Container: { justifyContent: 'center', alignItems: 'center' },

  Wrapper: { justifyContent: 'center', alignItems: 'center' },

  LineWrapper: {
    component: styled('div')`
      width: 100%;
      height: 3px;
      background: rgba(126, 12, 128, 0.28);
    `,
  },

  Line: {
    height: 3,
    sx: {
      backgroundColor: (theme) => theme.palette.primary.main,
    },
  },
});

function Progress() {
  const [progress] = useQuiz((state) => {
    return state.progress;
  });

  return (
    <StyledProgress.Wrapper
      width={'100%'}
      flexDirection={'row'}
      alignItems={'center'}
      gap={2}
    >
      <Typography
        className={'Progress__left'}
        sx={{ color: (t) => t.palette.primary.main }}
        variant={'h6'}
      >
        {progress.current}/{progress.total}
      </Typography>

      <StyledProgress.LineWrapper>
        <StyledProgress.Line width={`${progress.percentage}%`} />
      </StyledProgress.LineWrapper>
    </StyledProgress.Wrapper>
  );
}

const mobileStyles = {
  // positioning dialog on button when in mobile
  css: css`
    [data-id='question-wrapper'] {
      @media screen and (max-width: 767px) {
        background: transparent;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .MuiDialog-container {
          align-items: flex-end;
          //max-height: calc(100vh - 100px);
        }

        .MuiDialog-paper {
          padding: 0;
          margin: 0;
          max-width: 100vw;
          width: 100vw;
          border-radius: 16px 16px 0 0;
          justify-content: space-between;
        }
      }
    }
  `,
  render() {
    return (
      <style
        id={mobileStyles.css.name}
        dangerouslySetInnerHTML={{ __html: mobileStyles.css.styles }}
      />
    );
  },
};

type Value = string | null | undefined;

type QuestionNodeUtils<Node extends QuestionNode> = {
  goBack: QuizState['goBack'];
  forceCloseQuiz: () => void;
  submit: QuizState['submitActiveNode'];
  setValue: (value: Value[] | ((current: string[]) => Value[])) => void;
  setMetadata: (
    value: Node['meta'] | ((current: Node['meta']) => Node['meta'])
  ) => void;
  setErrors: (errors: string[]) => void;
  exitQuiz: () => void;
  current: () => Node;
  lastSubmittedValue: string[];
};

type QuestionWrapperNode<Kind extends QuestionKind = QuestionKind> =
  QuestionNode<Kind> & QuestionNodeUtils<QuestionNode<Kind>> & QuizStateValue;
