import { useQuery } from '@tanstack/react-query';
import { ResourceApprovalDetailsRequest } from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from './subsidy-client';

export const useResourceApprovalsDetailsQueryKey = (uuid: string) => ['resourceApprovalDetails', uuid];

export function useResourceApprovalsDetails(
  requestParams: ResourceApprovalDetailsRequest
) {
  const result = useQuery({
    queryKey: useResourceApprovalsDetailsQueryKey(requestParams.uuid),
    enabled: !!requestParams.uuid,
    refetchOnMount: true,
    queryFn: async ({ signal }) => {
      try {
        return await SubsidyClient.getResourceApprovalDetails({
          requestParams,
          signal,
        });
      } catch (error) {
        console.error('error', error);
      }
    },
  });

  const resourceApprovalsDetails = result.data?.resource_approval;

  return {
    resourceApprovalsDetails,
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    error: !!result.error,
  };
}
