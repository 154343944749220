import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { styledBoxes } from '../utils/styled-boxes';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Search } from '@mui/icons-material';
import { styled } from '@mui/material';
import { useJobSearch } from '@/react/data/jobs/useJobSearch';
import { keyBy } from 'powership';
import { JobSearchResultItem } from '@/react/data/jobs/interfaces';

const Styled = styledBoxes('RoleSelection', {
  Stack: {
    component: styled(Stack)`
      &&&& {
        .MuiInputBase-root {
          width: 100%;
          padding-right: 20px;
        }
        .MuiOutlinedInput-input {
          width: 80%;
        }
      }
    `,
    width: '100%',
  },
  EndAdornment: {
    marginRight: '-19px',
  },
  HelperText: {
    component: 'span',
    fontSize: (p) => p.typography.body3.fontSize,
  },
});

export function QuestionTypeJobSelection({
  meta,
  value,
  setValue,
  setMetadata,
  props,
}) {
  let { isLoading, term, setTerm, options } = useJobSearch();
  const [open, setOpen] = React.useState(false);
  const [hasChanged, setHasChanged] = React.useState(false);

  const jobReloadedFromProfile = meta?.jobReloadedFromProfile;
  const jobLoadedFromSearch = meta?.jobLoadedFromSearch;
  const { allowMarkAsOptionNotFound, searchHelperText, searchPlaceholder } =
    props;

  const reloaded = (() => {
    if (hasChanged) return null;
    const candidate = jobLoadedFromSearch?.id
      ? jobLoadedFromSearch
      : jobReloadedFromProfile;
    if (candidate?.id) return candidate;
    return null;
  })();

  const selectedId = value[0];

  const rehydratedData = useMemo(() => {
    const rehydratedJobs = (() => {
      if (!reloaded?.id) return options;
      const byId = keyBy(options, (el) => el.id);
      const isValueInOptions = !!byId[reloaded.id];
      return isValueInOptions ? options : [...options, reloaded];
    })();

    const dict: {
      [K: string]: {
        label: string;
        value: string;
        job: JobSearchResultItem;
      };
    } = Object.create(null);

    rehydratedJobs.forEach((job) => {
      const id = job.id.toString();
      dict[id] = {
        job,
        value: id,
        label: job.name_pt,
      };
    });

    const uniqOptions = Object.keys(dict);

    return {
      options: uniqOptions,
      dict,
    };
  }, [reloaded?.id, options]);

  const calculatedValue =
    (() => {
      if (hasChanged) return selectedId;
      return reloaded?.id?.toString();
    })() || null;

  return (
    <Styled.Stack>
      <Autocomplete
        filterOptions={(opt) => {
          // by default, the autocomplete component
          // applies a filter on options, so we need to pass a custom filter fn.
          return opt;
        }}
        options={rehydratedData.options}
        value={calculatedValue}
        open={open && !!term}
        onChange={(_, id) => {
          const data = rehydratedData.dict[id];
          setValue([id]);
          setMetadata({
            jobReloadedFromProfile,
            jobLoadedFromSearch: data?.job,
          });
        }}
        onOpen={() => {
          setHasChanged(true);
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={(id) => rehydratedData.dict[id]?.label || ''}
        getOptionKey={(id) => id}
        isOptionEqualToValue={(option, value) =>
          String(option) === String(value)
        }
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={searchPlaceholder}
            helperText={
              <Styled.HelperText color={(t) => t.palette.text.primary}>
                {searchHelperText}
              </Styled.HelperText>
            }
            slotProps={{
              input: {
                ...params.InputProps,
                placeholder: searchPlaceholder,
                onKeyUp(event: React.ChangeEvent<{ value?: any }>) {
                  setTerm(event.target.value);
                },
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    <Search />
                  </>
                ),
              },
            }}
          />
        )}
      />
      {allowMarkAsOptionNotFound ? (
        <Stack
          padding={1}
          sx={{
            paddingLeft: '14px', //  adjust
          }}
        />
      ) : null}
    </Styled.Stack>
  );
}
