import React from 'react';

import { Alert, AlertTitle, Container, Grid, Stack } from '@mui/material';

export const BannerStatus = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Stack
        spacing={2}
        sx={(theme) => ({
          width: '100vw',
          backgroundColor: '#FFF2F7',
          position: 'relative',
        })}
      >
        <Alert
          severity="error"
          sx={{
            pr: 4,
            pl: 4,
            gap: 1,
            display: 'flex',
            alignItems: 'center',
            '& .MuiAlert-icon': {
              marginRight: '2px',
            },
          }}
        >
          <AlertTitle
            style={{ fontWeight: '600', marginBottom: 0 }}
            variant="body3"
          >
            Documentação recusada
          </AlertTitle>
          <span style={{ fontSize: '14px' }}>
            Os documentos foram recusados e o pedido não será reembolsado.
          </span>
        </Alert>
      </Stack>
    </Container>
  );
};
