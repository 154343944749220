import { CourseTypes, Modalities, PeriodLabels } from './interfaces';

export const CourseTypesLabels = {
  [CourseTypes.FREE]: 'Livre',
  [CourseTypes.GRADUATION]: 'Graduação',
  [CourseTypes.LANGUAGES]: 'Idiomas',
  [CourseTypes.POSTGRADUATE]: 'Pós-graduação',
  [CourseTypes.TECHNICAL]: 'Técnico',
} as const;

export const ModalityTypesLabels = {
  [Modalities.ONLINE]: 'Online',
  [Modalities.LIVE_ONLINE]: 'Online ao vivo',
  [Modalities.HYBRID]: 'Híbrido',
  [Modalities.PRESENTIAL]: 'Presencial',
  [Modalities.PRIVATE_CLASS]: 'Aula particular',
};

export const PeriodTypesLabels = {
  [PeriodLabels.MORNING]: 'Manhã',
  [PeriodLabels.AFTERNOON]: 'Tarde',
  [PeriodLabels.EVENING]: 'Noite',
  [PeriodLabels.FLEX]: 'Flexível',
  [PeriodLabels.FULL_TIME]: 'Integral',
  [PeriodLabels.UPON_REQUEST]: 'Sob consulta',
};
