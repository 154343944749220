import * as React from 'react';
import { createPortal } from 'react-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  FabProps,
  Grow,
  Stack,
  Typography,
} from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import cx from 'clsx';

import { useScreenSizes } from '@/react/utils/useScreenSizes';

const QR_CODE_IMAGE =
  'https://assets.galena.com/edupass-api/public/whatsapp_qr_code.svg';

enum Modes {
  BUTTON = 'button',
  DIALOG = 'dialog',
}

interface ContentProps {
  onClose: () => void;
}

interface ButtonProps extends FabProps {
  mode: Modes;
}

interface HelpButtonProps extends FabProps {
  mode: Modes;
}

const Container = styled('div')(({ theme }) => ({
  bottom: theme.spacing(1.5),
  position: 'fixed',
  right: theme.spacing(1.5),
  width: '100%',
  zIndex: theme.zIndex.fab,

  [theme.breakpoints.up('sm')]: {
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

const HelpButton = styled(Fab, {
  shouldForwardProp: (prop) => prop !== 'mode',
})<HelpButtonProps>(({ mode }) => {
  return {
    bottom: '0',
    letterSpacing: 1,
    position: 'absolute',
    right: '0',
    textTransform: 'none',
    height: 48,
    transition:
      'transform 0.15s ease, width 0.1s ease, background-color 0.5s ease',

    '&:hover': {
      transform: 'scale(1.1)',
    },

    '&:active': {
      transform: 'scale(1)',
    },

    ...(mode === Modes.BUTTON && {
      width: 100,
    }),

    ...(mode === Modes.DIALOG && {
      width: 48,
    }),
  };
});

const CloseButton: React.FC = () => <CloseRoundedIcon sx={{ fontSize: 20 }} />;

const OpenButton: React.FC = () => {
  return (
    <Stack alignItems="center" spacing={1} direction="row">
      <HelpOutlineRoundedIcon sx={{ fontSize: 20 }} />
      <Typography variant="body3">Ajuda</Typography>
    </Stack>
  );
};

const ButtonComponent: React.FC<ButtonProps> = ({ mode, onClick }) => {
  interface ButtonComponentOptions extends Omit<FabProps, 'content'> {
    ariaLabel: string;
    children: React.ReactNode;
  }

  const defaultOptions: ButtonComponentOptions = {
    ariaLabel: 'Abrir menu de ajuda',
    children: <OpenButton />,
    color: 'primary',
    variant: 'extended',
  };

  const options: ButtonComponentOptions = (() => {
    switch (mode) {
      case Modes.BUTTON:
        return defaultOptions;
      case Modes.DIALOG:
        return {
          ...defaultOptions,
          ariaLabel: 'Fechar menu de ajuda',
          children: <CloseButton />,
          variant: 'circular',
        };
      default:
        return defaultOptions;
    }
  })();

  const { ariaLabel, children, ...otherOptions } = options;

  return (
    <>
      <HelpButton
        aria-label={ariaLabel}
        mode={mode}
        onClick={onClick}
        {...otherOptions}
      >
        {children}
      </HelpButton>
    </>
  );
};

const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  ({ onClose }, ref) => {
    const { mobileSize } = useScreenSizes();

    const desktopContent = (
      <>
        <Stack spacing={2}>
          <Typography component="p" variant="h5">
            Precisa de ajuda?
          </Typography>
          <Typography component="p" variant="body2">
            Clique no botão ou escaneie o QR code para falar com a Galena via
            whatsapp.
          </Typography>
          <Box>
            <Button
              color="success"
              href="https://wa.me/5511987566079"
              size="large"
              startIcon={<WhatsAppIcon />}
              target="_blank"
              variant="contained"
            >
              Abrir whastapp
            </Button>
          </Box>
        </Stack>
        <Box component="img" src={QR_CODE_IMAGE} sx={{ width: 160 }} mt={4} />
      </>
    );

    const mobileContent = (
      <>
        <Stack spacing={2}>
          <Typography component="p" variant="h5">
            Precisa de ajuda?
          </Typography>
          <Typography component="p" variant="body2">
            Clique no botão para falar com a Galena via whatsapp.
          </Typography>
          <Box>
            <Button
              color="success"
              href="https://wa.me/5511987566079"
              size="large"
              startIcon={<WhatsAppIcon />}
              target="_blank"
              variant="contained"
            >
              Abrir whastapp
            </Button>
          </Box>
        </Stack>
      </>
    );

    const content = mobileSize ? mobileContent : desktopContent;

    return (
      <Grow
        in={true}
        style={{ transformOrigin: 'bottom right' }}
        role="presentation"
        {...(open ? { timeout: 300 } : { timeout: 0 })}
      >
        <Box
          ref={ref}
          sx={(theme) => ({
            bottom: 60,
            position: 'absolute',
            right: 0,
            width: `calc(100% - (2 * ${theme.spacing(1.5)}))`,

            [theme.breakpoints.up('sm')]: {
              bottom: 70,
              height: 450,
              width: 350,
            },
          })}
        >
          <ClickAwayListener
            onClickAway={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }}
          >
            <Card
              sx={{
                height: '100%',
              }}
              elevation={2}
              raised
            >
              <CardContent
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'center',
                  padding: 3,
                  textAlign: 'center',
                }}
              >
                {content}
              </CardContent>
            </Card>
          </ClickAwayListener>
        </Box>
      </Grow>
    );
  }
);

export const BaseHelpMenu: React.FC = () => {
  const [mode, setMode] = React.useState(Modes.BUTTON);

  const handleClick = () => {
    if (mode === Modes.BUTTON) {
      setMode(Modes.DIALOG);
    } else {
      setMode(Modes.BUTTON);
    }
  };

  const handleClose = () => {
    setMode(Modes.BUTTON);
  };

  const renderContent = () => {
    if (mode === Modes.BUTTON) {
      return null;
    }

    return <Content onClose={handleClose} />;
  };

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setMode(Modes.BUTTON);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // preload qr code image so the menu doesn't "jump" during animation
  React.useEffect(() => {
    const img = new Image();
    img.src = QR_CODE_IMAGE;
  }, []);

  return (
    <Container className={cx('HelpMenu', 'media-screen', `mode-${mode}`)}>
      {renderContent()}
      <ButtonComponent mode={mode} onClick={handleClick} />
    </Container>
  );
};

export const HelpMenu = () => {
  return createPortal(<BaseHelpMenu />, document.body);
};
