import React from 'react';
import {
  SectionHeader,
  PageContainer,
} from '@/react/components/refund/refund-management/shared';
import { useResourceApprovalsDetails } from '@/react/data/subsidy/useResourceApprovalsDetails';
import { ResourceApprovalSkeleton } from './item';
const handleBackButton = () => {
  window.location.assign('/empresa/gestao-de-reembolsos');
};

export const ResourceApprovalDetails = ({
  resourceApprovalUuid,
}: {
  resourceApprovalUuid: string;
}) => {
  const { resourceApprovalsDetails, isLoading } = useResourceApprovalsDetails({
    uuid: resourceApprovalUuid,
  });

  return (
    <PageContainer>
      <SectionHeader
        title="Detalhes do pedido"
        handleBackNavigation={handleBackButton}
      />
      <ResourceApprovalSkeleton
        resourceApprovalsDetails={resourceApprovalsDetails}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default ResourceApprovalDetails;
