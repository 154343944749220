import { ComponentsModule } from '@/components/components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RelatoriosComponent } from './relatorios/relatorios.component';
import { RefundReportComponent } from './refund/report/report.component';
import { RefundNavigationComponent } from './refund/navigation/navigation.component';
import { RefundPendingComponent } from './refund/pending/pending.component';
import { RefundReceiptReportComponent } from './refund_receipt/report/report.component';
import { RefundReceiptApprovalComponent } from './refund_receipt/review/approval.component';
import { EmpresaRoutingModule } from './empresa-routing.module';
import { LmsReportComponent } from './relatorios/lms-report/lms-report.component';
import { ManageEmployeesComponent } from '../colaborador/employee/manage-employees.component';
import { RegisterEmployeesComponent } from '../colaborador/employee/register/register-employees.component';
import { EditEmployeesComponent } from '../colaborador/employee/edit/edit-employees.component';
import { RefundReceiptNavigationComponent } from './refund_receipt/navigation/navigation.component';
import { RefundManagementComponent } from './refund-management/refund-management/refund-management.component';
import { ResourceApprovalReviewComponent } from './refund-management/resource-management/review/resource-approval-review.component';
import { ResourceApprovalDetailsComponent } from './refund-management/resource-management/details/resource-approval-details.component';
import { RefundRequestDetailsComponent } from './refund-management/resource-management/details/refund-request/refund-request-details.component';

@NgModule({
  declarations: [
    DashboardComponent,
    RelatoriosComponent,
    RefundReportComponent,
    RefundReceiptReportComponent,
    RefundNavigationComponent,
    RefundPendingComponent,
    RefundReceiptApprovalComponent,
    LmsReportComponent,
    RefundReceiptNavigationComponent,
    ManageEmployeesComponent,
    RegisterEmployeesComponent,
    EditEmployeesComponent,
    RefundManagementComponent,
    ResourceApprovalReviewComponent,
    ResourceApprovalDetailsComponent,
    RefundRequestDetailsComponent,
  ],

  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    ImageCropperModule,
    ReactiveFormsModule,
    FormsModule,
    EmpresaRoutingModule,
  ],
})
export class EmpresaModule {}
