import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { Controller } from 'react-hook-form';

const ChangeEmailDialog = ({
  emailValueRef,
  editState: {
    control,
    handleChangeEmail,
    loading,
    reset,
    handleSubmit,
    onCloseDialog,
    setValue,
  },
  open,
}) => {
  const [newValueEmail, setnewValueEmail] = React.useState(emailValueRef);

  React.useEffect(() => {
    if (open) {
      setnewValueEmail(emailValueRef);
      setValue('email', newValueEmail);
    }
  }, [open, emailValueRef, newValueEmail, setValue]);

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      fullWidth
      TransitionProps={{
        onExited: () => {
          reset();
        },
      }}
    >
      <DialogTitle>Alteração de email</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2">
            O email é utilizado para o usuário entrar na plataforma. Após essa
            alteração, o usuário precisará usar o novo email para isso.
          </Typography>
        </Stack>
        <Divider sx={{ my: 2 }} />

        <Grid item xs={12}>
          <FormLabel>Novo email*</FormLabel>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                fullWidth
                placeholder="nome@nomedaempresa.com.br"
                variant="outlined"
                helperText={error?.message || ' '}
              />
            )}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={onCloseDialog} variant="outlined" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(handleChangeEmail)}
          color="primary"
          loading={loading}
          loadingPosition="start"
          variant="contained"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeEmailDialog;
