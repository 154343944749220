import React from 'react';
import {
  FormControl,
  FormHelperText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import {
  PageContainer,
  SectionHeader,
} from '@/react/components/refund/refund-management/shared';
import { useResourceApproval } from '@/react/data/subsidy/useResourceApproval';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';
import { BannerStatus } from '@/react/components/refund/shared/banner-status';
import { RefundRequestOrderDetail } from './refund-request-order-detail';

const handleBackButton = (resource_approval_uuid) => {
  window.location.assign(
    `/empresa/gestao-de-reembolsos/${resource_approval_uuid}/detalhes`
  );
};

export const RefundRequestDetails = ({
  resourceApprovalUuid,
  refundRequestUuid,
}: {
  resourceApprovalUuid: string;
  refundRequestUuid: string;
}) => {
  const refundEntitiesUuids = {
    refundRequestUuid,
    resourceApprovalUuid,
  };

  const { isFetching: isFetchingRefundOrder, resourceApproval } =
    useResourceApproval(refundEntitiesUuids);

  const FieldSkeleton = ({ padding }) => {
    return (
      <FormControl
        fullWidth
        sx={{
          pl: padding ? padding : null,
          pr: padding ? padding : null,
        }}
      >
        <Stack spacing={1}>
          <Typography component="div" variant="body3">
            <Skeleton width="100%" />
          </Typography>
          <Skeleton height={53} variant="rounded" />
        </Stack>
        <FormHelperText />
      </FormControl>
    );
  };

  const FieldWrapper = ({
    loading,
    padding,
    children,
  }: {
    loading: boolean;
    padding?: number;
    children?: React.ReactNode;
  }) => {
    return loading ? <FieldSkeleton padding={padding} /> : <>{children}</>;
  };

  return (
    <>
      {resourceApproval &&
        [RefundRequestStatus.rejected, RefundRequestStatus.cancelled].includes(
          resourceApproval.status
        ) && (
          <FieldWrapper loading={isFetchingRefundOrder} padding={2}>
            <BannerStatus />
          </FieldWrapper>
        )}
      <PageContainer>
        <FieldWrapper loading={isFetchingRefundOrder}>
          <SectionHeader
            title="Detalhes do reembolso"
            handleBackNavigation={() => handleBackButton(resourceApprovalUuid)}
          />
          <RefundRequestOrderDetail resourceApproval={resourceApproval} />
        </FieldWrapper>
      </PageContainer>
    </>
  );
};

export default RefundRequestDetails;
