import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { ComponentProps } from 'react';

import ResourceApprovalReview from '@/react/components/refund/refund-management/resource-approval-review/resource-approval-review.component';

@Component({
  selector: 'component-resource-approval-review',
  templateUrl: './resource-approval-review.component.html',
})
export class ResourceApprovalReviewComponent implements OnInit {
  ResourceApprovalReview = ResourceApprovalReview;
  props: ComponentProps<typeof ResourceApprovalReview> = {
    resourceApprovalUuid: undefined,
  };

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const { resourceApprovalUuid } = this.route.snapshot.params;

    this.props = {
      ...this.props,
      resourceApprovalUuid,
    };
  }
}
