import React from 'react';

import { State, StateActions } from './interfaces';

const defaultState = {
  context: null,
};

export const ProfileContext =
  React.createContext<[State, StateActions | null]>(null);

export function ProfileProvider({
  children,
  initialState = defaultState,
}: {
  children: React.ReactNode;
  initialState?: State;
}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const defaultActions: StateActions = {
    edit: (context) => {
      dispatch({
        type: 'SET_CONTEXT',
        payload: context,
      });
    },
  };

  const actions = { ...defaultActions, ...initialState?.actions };
  const value: [State, StateActions] = [state, actions];

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}

function reducer(state: State, action): State {
  switch (action.type) {
    case 'SET_CONTEXT': {
      return {
        ...state,
        context: action.payload,
      };
    }
  }
}
