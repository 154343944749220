import * as React from 'react';
import { Box, Typography, Stack, Grid, Link } from '@mui/material';
import { nanoid } from 'nanoid';
import { EmptyList } from '@/react/components/refund/empty-list.component';
import { useRefundStatus } from '@/react/components/refund/utils/use-refund-status';
import { StatusChip } from '@/react/components/refund/utils/status-chip';
import { refundableAmount } from '@/react/components/refund/utils/refundable-amount';

const dataFormatted = (resourceApproval) => {
  return {
    requested_at: new Date(resourceApproval.requested_at).toLocaleDateString(
      'pt-BR'
    ),
    amount: new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(refundableAmount(resourceApproval))),
  };
};

const refundResquest = ({ refund_request }) => {
  const { mappedStatus } = useRefundStatus(refund_request);
  const { requested_at, amount } = dataFormatted(refund_request);

  const link = `empresa/gestao-de-reembolsos/${refund_request?.resource_approval_uuid}/detalhes/${refund_request?.refund_request_uuid}`;

  return (
    <>
      <Grid
        item
        xs={12}
        sx={({ palette }) => ({
          border: `1px solid ${palette.neutral[300]}`,
          p: 2,
        })}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="body3" fontWeight={600}>
              Data da solicitação
            </Typography>
            <Typography variant="body3">{requested_at}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body3" fontWeight={600}>
              Reembolso solicitado
            </Typography>
            {amount && <Typography variant="body3">{amount}</Typography>}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body3" fontWeight={600}>
              Situação
            </Typography>
            <StatusChip statusApproval={mappedStatus} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'flex-start',
                '@media (min-width: 900px)': {
                  justifyContent: 'flex-end',
                },
                pr: 2,
              }}
            >
              <Link href={link} variant="inherit" rel="noopener">
                Ver detalhes
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapRefundRequests = (data: any) => {
  return Array.isArray(data.refund_requests)
    ? data.refund_requests.map((refund: any) => ({
        resource_approval_uuid: data.uuid,
        refund_request_uuid: refund.uuid,
        status: refund.status,
        refund_request: refund,
        amount: data.amount,
        requested_at: data.requested_at,
      }))
    : [];
};

export const ListRefundRequest = ({ resourceApproval }) => {
  if (!resourceApproval?.refund_requests) {
    return <EmptyList />;
  }

  const resourceAprovalByRefundRequests = mapRefundRequests(resourceApproval);

  return (
    <Box
      height={'100%'}
      width={'100%'}
      display="flex"
      alignItems="center"
      p={2}
      my={2}
      sx={({ palette }) => ({
        minHeight: 156,
        border: `1px solid ${palette.neutral[300]}`,
        backgroundColor: `${palette.neutral[50]}`,
      })}
    >
      <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} mb={2} style={{ textAlign: 'left' }}>
          <Typography
            variant="body1"
            style={{ fontWeight: '600' }}
            color="primary.200"
          >
            Reembolsos
          </Typography>
        </Grid>
        {resourceAprovalByRefundRequests.map(
          (refund_request, index: number) => (
            <Grid item xs={12} key={index + nanoid()}>
              {refundResquest({ refund_request })}
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};
