import * as React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useWizard } from '@/react/components/wizard/state';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { StepsIds } from '@/react/components/idp/create-plan/create-plan.component';
import { useGetProfileBasedRecommendationActivities } from '@/react/data/idp';
import { ProfileBasedRecommendationActivitiesStatus } from '@/react/data/idp/profile/constants';

const componentTexts = {
  [ProfileBasedRecommendationActivitiesStatus.IN_PROGRESS]: {
    title: 'Gerando recomendações...',
    description:
      'Estamos processando suas respostas para criar um plano alinhado ao seu objetivo e às habilidades que você deseja desenvolver.',
  },
  [ProfileBasedRecommendationActivitiesStatus.FAIL]: {
    title: 'Não conseguimos gerar suas recomendações.',
    description:
      'Não concluímos as suas recomendações, você pode aguardar mais alguns instantes ou voltar novamente mais tarde para acessar suas recomendações.',
  },
};

export const GenerateRecommendation: React.ElementType<
  WizardStepComponent & {
    profileBasedRecommendationId: string;
    generateRecommendation: (stepId: StepsIds, skills: any) => Promise<void>;
  }
> = ({ profileBasedRecommendationId, generateRecommendation, onNext }) => {
  const { recommendedActivities, status, refetch } =
    useGetProfileBasedRecommendationActivities(profileBasedRecommendationId);

  const { values } = useWizard();

  const skillSelection = values[CreatePlanWizardSteps.SKILL_SELECTION];

  const generateAgain = async () => {
    await generateRecommendation(
      StepsIds.GENERATE_RECOMMENDATION,
      skillSelection
    );
    refetch();
  };

  React.useEffect(() => {
    if (
      !!onNext &&
      status === ProfileBasedRecommendationActivitiesStatus.SUCCESS
    ) {
      onNext({ recommendedActivities });
    }
  }, [status]);

  return (
    <Box
      mt={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {status === ProfileBasedRecommendationActivitiesStatus.IN_PROGRESS && ( //TODO: Remove this conditional once we have the image that goes here
        <Box sx={{ height: 40 }}>
          <CircularProgress data-testid="loading" />
        </Box>
      )}
      <Box mt={3}>
        <Typography variant="h6">{componentTexts[status]?.title}</Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body3">
          {componentTexts[status]?.description}
        </Typography>
      </Box>
      {status === ProfileBasedRecommendationActivitiesStatus.FAIL && (
        <Box mt={3}>
          <Button
            role="button"
            onClick={generateAgain}
            color="primary"
            type="submit"
            variant="contained"
          >
            Gerar novamente
          </Button>
        </Box>
      )}
    </Box>
  );
};
