import React, { useCallback, useMemo } from 'react';
import { BaseDialog } from '@/react/components/dialog';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
} from '@mui/material';
import Typography from '@mui/material/Typography';

export interface NewPlanInfoDialogProps {
  onClose: () => void;
  open: boolean;
}

const stepsInfo = [
  'Escolha receber uma recomendação personalizada da nossa IA ou crie seu plano manualmente.',
  'Dê um título ao seu plano, defina a duração e adicione as atividades que ajudarão no seu desenvolvimento.',
  'Inicie e acompanhe seu plano, trabalhe nas suas habilidades e monitore seu progresso para alcançar seus objetivos.',
];

export const NewPlanInfoDialog = ({
  onClose,
  open,
}: NewPlanInfoDialogProps) => {
  const saveNotShowOnboardingNewPlanModal = (value: boolean) => {
    localStorage.setItem('not_show_onboarding_new_plan_modal', String(value));
  };

  const handleStartOnboarding = useCallback(() => {
    window.location.href = '/pdi/planos/recomendacoes';
  }, []);

  const actions = useMemo(() => {
    return (
      <>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  saveNotShowOnboardingNewPlanModal(e.target.checked)
                }
              />
            }
            label="Não mostrar novamente"
          />
        </FormGroup>
        <Button onClick={handleStartOnboarding} variant="contained">
          Começar
        </Button>
      </>
    );
  }, []);

  return (
    <>
      <BaseDialog
        data-testid="new-plan-info-dialog"
        onClose={onClose}
        open={open}
        title="Como funciona o plano de desenvolvimento"
        actionsComponent={actions}
      >
        <List>
          {stepsInfo.map((text: string, index: number) => (
            <ListItem key={`new-plan-info-description-${String(index)}`}>
              <Typography variant="h5" className="mr-3">
                {index + 1}.
              </Typography>
              <Typography variant="body3">{text}</Typography>
            </ListItem>
          ))}
        </List>
      </BaseDialog>
    </>
  );
};
