import { Component, OnInit } from '@angular/core';
import { RouterService } from '@/services/router.service';

import { default as ReactPlanGallery } from '@/react/components/cursos/plan-gallery.component';
import { BootstrapDataService } from '@/services/api/bootstrap_data.service';

@Component({
  selector: 'plan-galery.component',
  templateUrl: './plan-galery.component.html',
})
export class PlanGaleryComponent implements OnInit {
  ReactPlanGallery = ReactPlanGallery;

  subscription: any;

  constructor(
    private routerService: RouterService,
    private bootstrapDataService: BootstrapDataService
  ) {}

  validatePlan() {
    if (!this.bootstrapDataService.plan?.code) {
      return this.routerService.home();
    }
  }

  ngOnInit(): void {
    if (this.bootstrapDataService.isLoaded) {
      this.validatePlan();
    }

    this.subscription = this.bootstrapDataService.planChange.subscribe(() => {
      this.validatePlan();
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
