import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Styled = {
  Card: styled(Card)`
    display: flex;
    flex-direction: row;
    height: 100%;
  `,
  CardIcon: styled('div')`
    align-items: center;
    background-color: ${({ theme }) => theme.palette.primary[100]};
    color: ${({ theme }) => theme.palette.primary[900]};
    display: flex;
    height: 100%;
    justify-content: center;
    margin-right: ${({ theme }) => theme.spacing(2)};
    min-height: 40px;
    width: 40px;
  `,
  Category: styled(Typography)`
    text-transform: uppercase;
  `,
};

export const CourseDetailsHeaderCard = ({
  iconComponent,
  loading,
  subtitle,
  title,
}: {
  iconComponent: React.ReactNode;
  loading?: boolean;
  subtitle: string;
  title: string;
}) => {
  const content = loading ? (
    <Skeleton
      animation="wave"
      variant="rounded"
      sx={{
        height: '100%',
        width: '100%',
      }}
    />
  ) : (
    <>
      <Styled.CardIcon>{iconComponent}</Styled.CardIcon>
      <Stack direction="column">
        <Typography color="neutral.800" variant="body3" fontWeight={700}>
          {title}
        </Typography>
        <Typography color="primary.900" variant="body3">
          {subtitle}
        </Typography>
      </Stack>
    </>
  );
  return (
    <Styled.Card square sx={{ px: 1, py: 1, border: 'none' }}>
      <Box sx={{ display: 'flex', minHeight: 40, width: '100%' }}>
        {content}
      </Box>
    </Styled.Card>
  );
};
