import { colors } from './tokens';

export default {
  background: {
    default: colors.neutral[200],
  },
  primary: {
    main: colors.primary[700],
    ...colors.primary,
  },
  secondary: {
    main: colors.secondary[500],
    ...colors.secondary,
  },
  error: {
    main: colors.danger[500],
    ...colors.danger,
  },
  success: {
    main: colors.success[500],
    ...colors.success,
  },
  warning: {
    main: colors.yellow[500],
    ...colors.yellow,
  },
  text: {
    primary: colors.neutral[700],
  },
  tertiary: {
    main: colors.neutral[200],
    light: colors.neutral[100],
    dark: colors.neutral[600],
    text: colors.neutral[900],
    contrastText: colors.neutral[50],
  },
  neutral: { ...colors.neutral },
  idle: {
    main: colors.neutral[200],
    contrastText: colors.neutral[800],
  },
  negative: {
    main: colors.red[100],
    contrastText: colors.red[900],
  },
  attention: {
    main: colors.yellow[100],
    contrastText: colors.yellow[900],
  },
  idleSuccess: {
    main: colors.success[100],
    contrastText: colors.success[900],
  },
  concluded: {
    main: colors.success[700],
    contrastText: colors.success[900],
  },
};
