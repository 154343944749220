import React, { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import { BaseDialog } from '@/react/components/dialog';
import Button from '@mui/material/Button';
import { IdpClient } from '../../../data/idp/idp-client';
import { PlanType } from '../../../data/idp/plans';
import { NewPlanInfoDialog } from './new-plan-info-dialog.component';
import { Typography } from '@mui/material';

export interface PlanInDraftDialogProps {
  onClose: () => void;
  open: boolean;
  planInDraft?: PlanType;
}

export const PlanInDraftDialog = ({
  onClose,
  open,
  planInDraft,
}: PlanInDraftDialogProps) => {
  const [newPlanInfoDialog, setNewPlanInfoDialog] = React.useState(false);

  const handleResumeDraft = useCallback(async () => {
    if (!planInDraft?.id) return;

    window.location.href = `/pdi/planos/${planInDraft.id}`;
  }, [planInDraft]);

  const handleStartOnboarding = useCallback(() => {
    window.location.href = '/pdi/planos/recomendacoes';
  }, []);

  const notShowOnboardingNewPlanModal = useMemo(() => {
    return (
      localStorage.getItem('not_show_onboarding_new_plan_modal') === 'true'
    );
  }, []);

  const handleStartNew = useCallback(async () => {
    try {
      if (planInDraft?.id) await IdpClient.deletePlan(planInDraft.id);

      if (notShowOnboardingNewPlanModal) {
        handleStartOnboarding();
      } else {
        setNewPlanInfoDialog(true);
        onClose();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [handleStartOnboarding, planInDraft, notShowOnboardingNewPlanModal]);

  const actions = useMemo(() => {
    return (
      <>
        <Button onClick={() => handleResumeDraft()} variant="contained">
          Retomar rascunho
        </Button>
        <Button onClick={() => handleStartNew()} variant="contained">
          Começar novo
        </Button>
      </>
    );
  }, [handleResumeDraft, handleStartNew]);

  return (
    <>
      <BaseDialog
        actionsComponent={actions}
        data-testid="plan-in-draft-dialog"
        onClose={onClose}
        open={open}
        title="Você já possui um rascunho em aberto"
      >
        <Typography variant="body3">
          Deseja retomar o rascunho atual ou iniciar um novo? Se optar por
          começar um novo, o rascunho em aberto será excluído.
        </Typography>
      </BaseDialog>
      <NewPlanInfoDialog
        onClose={() => setNewPlanInfoDialog(false)}
        open={newPlanInfoDialog}
      />
    </>
  );
};
