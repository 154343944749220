import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment.development';

if (environment.production) {
  enableProdMode();
}

// This enables MSW (Mock Service Worker).
// It will intercept all requests to the API and return mocked responses.
// The idea is to mock the API responses so that we can develop the UI
// without having to run the API server.
// See https://mswjs.io/docs/getting-started/mocks/rest-api
async function enableApiMocking() {
  if (environment.env === 'development' || environment.env === 'staging') {
    const { worker } = await import('@/api-mocks/browser');
    return worker.start({ onUnhandledRequest: 'bypass' });
  }

  return Promise.resolve();
}

// enableApiMocking().then(() => {
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
// });
