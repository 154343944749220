import { useQuery } from '@tanstack/react-query';
import { AvailableResourceApprovalsForRefundReponse } from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from './subsidy-client';

export function useAvailableResourceApprovalsForRefund() {
  const result = useQuery<AvailableResourceApprovalsForRefundReponse>({
    queryKey: ['useAvailableResourceApprovalsForRefund'],
    refetchOnMount: false,
    queryFn: async () => {
      return SubsidyClient.getResourceApprovalsAvailableForRefund();
    },
  });

  const availableResourceApprovals = result.data?.resource_approvals || [];

    return {
      availableResourceApprovals,
      isFetching: result.isFetching,
      error: !!result.error,
    };
}
