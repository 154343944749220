import React, { useMemo, ReactNode, useState, MouseEventHandler } from 'react';
import chunk from 'lodash/chunk';
import { styled, Box, Paper, Divider, Button } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { MenuColumn } from './MenuColumn';
import cx from 'clsx';

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  max-width: max-content;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;

const RootPaper = styled(Paper)`
  overflow: hidden;
  background-color: transparent;
  border: solid 1px ${({ theme }) => theme.palette.grey.A200};
  border-radius: ${({ theme }) => theme.spacing(2)};
  margin-top: -4px;
`;

const FloatingContainer = styled(Paper)`
  align-self: flex-start;
  overflow: hidden;
  float: left;
  display: flex;
  border-radius: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const FloatingMenuLeftBox = styled('div')`
  padding: 26px;
  padding-right: 12px;
  padding-left: 36px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: auto;
  background: ${({ theme }) => theme.palette.background.paper};
  width: 211px;
  border-radius: ${({ theme }) => theme.spacing(2)};
  &.is-open {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const RightSideSubMenus = styled('div')`
  padding: 26px;
  padding-right: 0;
  padding-bottom: 0;
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${({ theme }) => theme.palette.common.white};
  flex-wrap: wrap;
  flex-direction: row;
  overflow-x: auto;
  background: #414141; // <- not mapped color, needed for contrast
  color: ${({ theme }) => theme.palette.common.white};
  max-width: min(95vw, 750px);
`;

const SidebarHeader = styled('div')`
  align-content: center;
  font-family: inherit;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 0 0 15px;
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.main};
  height: 40px;
  justify-content: flex-start;

  .Header__back-button {
    ${({ theme }) => theme.typography.body2};
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    svg {
      margin-right: ${({ theme }) => theme.spacing(2)};
    }
  }

  .Header__divider {
    position: absolute;
    bottom: 0;
    left: ${({ theme }) => theme.spacing(3)};
    right: ${({ theme }) => theme.spacing(3)};
  }
`;

const Title = styled('h4')`
  font-weight: bold;
  font-size: 12px;
  width: 100%;
  display: flex;
  text-transform: uppercase;
  margin-bottom: 28px;
`;

export type MenuViewMode = 'floatingMenu' | 'sidebar';

export type MegaMenuProps = {
  alwaysOpenMenuId?: string; // used to set an always open menu item
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onClickItem?: (
    item: MenuItem,
    event: React.MouseEvent<HTMLAnchorElement>
  ) => void;
  menuItems: MenuItem[];
  viewMode: MenuViewMode;
  itemsBySubMenuColumn?: number; // defaults to 8
  // seeMoreText: string;
  // maxSubMenuColumns?: number; // defaults to 3
};

export function MegaMenu(props: MegaMenuProps) {
  const {
    menuItems,
    itemsBySubMenuColumn = 9,
    onClickItem,
    onMouseOver,
    onMouseLeave,
    onMouseEnter,
    viewMode,
  } = props;

  const alwaysOpenMenuId =
    viewMode === 'floatingMenu' ? props.alwaysOpenMenuId : undefined;

  const state = useMenuItems({
    menuItems,
    itemsBySubMenuColumn,
    alwaysOpenMenuId,
  });

  const { byId, activeMenuId, setActiveMenuId, subItems, goBack } = state;

  const currentSubMenu = byId[activeMenuId];

  const renderContentAsSidebar = () => {
    return (
      <>
        {(() => {
          if (!currentSubMenu?.label) return null;
          return (
            <SidebarHeader className={'SubMenuHeader'}>
              <Button
                disableRipple
                className={'Header__back-button'}
                variant={'text'}
                size={'large'}
                onClick={() => {
                  currentSubMenu && goBack();
                }}
              >
                {currentSubMenu ? <ChevronLeft /> : null}
                <b className={'submenu-menu-title'}>
                  {currentSubMenu?.label ?? 'Menu'}
                </b>
              </Button>
            </SidebarHeader>
          );
        })()}

        <MenuColumn
          columnTheme={'light'}
          setActiveMenuId={(id) => setActiveMenuId(id)}
          activeMenuId={activeMenuId}
          alwaysOpenMenuId={alwaysOpenMenuId}
          viewMode={viewMode}
          items={{
            flat: currentSubMenu ? subItems?.flat || [] : menuItems,
            byId,
          }}
          onClickItem={onClickItem}
        />
      </>
    );
  };

  function renderContentAsFloatingMenu() {
    return (
      <RootPaper className={'MegaMenu__paper'}>
        <FloatingContainer className={'MegaMenu__container'}>
          <FloatingMenuLeftBox
            className={cx('left-box', { 'is-open': currentSubMenu })}
          >
            <MenuColumn
              columnTheme={'light'}
              className={'hard-hover'}
              alwaysOpenMenuId={alwaysOpenMenuId}
              setActiveMenuId={(id) => setActiveMenuId(id)}
              activeMenuId={activeMenuId}
              viewMode={viewMode}
              items={{
                flat: menuItems,
                byId,
              }}
              onClickItem={onClickItem}
            />
          </FloatingMenuLeftBox>

          {(() => {
            if (!subItems?.chunks.length) return null;

            return (
              <>
                <RightSideSubMenus className={'RightSideSubMenus'}>
                  <Title>Temas</Title>

                  {subItems.chunks.map((items, index) => {
                    return (
                      <MenuColumn
                        columnTheme={'dark'}
                        alwaysOpenMenuId={alwaysOpenMenuId}
                        className={'submenu-content-column'}
                        key={`${index}_${activeMenuId}`}
                        setActiveMenuId={(id) => setActiveMenuId(id)}
                        activeMenuId={activeMenuId}
                        viewMode={viewMode}
                        items={{ flat: items, byId }}
                        onClickItem={onClickItem}
                      />
                    );
                  })}
                </RightSideSubMenus>
              </>
            );
          })()}
        </FloatingContainer>
      </RootPaper>
    );
  }

  return (
    <>
      <Wrapper
        className={'MegaMenu'}
        onMouseEnter={onMouseEnter}
        onMouseLeave={(event) => {
          onMouseLeave?.(event);
        }}
        onMouseOver={onMouseOver}
      >
        {viewMode === 'sidebar'
          ? renderContentAsSidebar()
          : renderContentAsFloatingMenu()}
      </Wrapper>
    </>
  );
}

function useMenuItems(init: {
  alwaysOpenMenuId?: string;
  itemsBySubMenuColumn: number;
  menuItems: MenuItem[];
}) {
  const { itemsBySubMenuColumn, menuItems, alwaysOpenMenuId } = init;

  const [state, setState] = useState<State>(() => ({
    activeMenuId: alwaysOpenMenuId ?? '',
    history: [{ menuId: '', prev: null }],
  }));

  const { activeMenuId, history } = state;

  function setActiveMenuId(
    menuId: string | ((current: typeof state) => string)
  ) {
    setState((current) => {
      menuId = typeof menuId === 'function' ? menuId(current) : menuId;
      const next: HistoryItem = {
        menuId,
        prev: history[0] || null,
      };

      return {
        activeMenuId: menuId,
        history: [next, ...current.history.slice(0, 30)],
      };
    });
  }

  function goBack() {
    setActiveMenuId((current) => {
      if (current.activeMenuId === 'categories') return 'courses'; // FIXME not really a history
      return '';
    });
  }

  const byId = useMemo<Record<string, MenuItem>>(() => {
    const byId: Record<string, MenuItem> = {};

    function set(curr: MenuItem[]) {
      curr.forEach((el) => {
        byId[el.id] = el;
        if (el.subItems) {
          set(el.subItems);
        }
      });
    }
    set(menuItems);

    return byId;
  }, [menuItems]);

  const subItems = useMemo(() => {
    if (!activeMenuId) return null;

    const items = byId[activeMenuId]?.subItems;

    if (!items?.length) {
      console.error(`no subItems found for activeMenuId ${activeMenuId}.`);
      return null;
    }

    return {
      flat: items,
      chunks: chunk(
        // on desktop, we divide the submenu in 3 columns of 8 items each
        items,
        itemsBySubMenuColumn
      ),
    };
  }, [byId, activeMenuId]);

  return {
    subItems,
    setActiveMenuId,
    goBack,
    activeMenuId,
    byId,
    history,
  };
}

export interface MenuItem {
  loading?: boolean;
  prepend?: ReactNode;
  append?: ReactNode;
  id: string;
  label: ReactNode;
  subItems?: MenuItem[];
  divider?: boolean;
  route?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

type HistoryItem = {
  menuId: string;
  prev: HistoryItem | null;
};

type State = { activeMenuId: string; history: HistoryItem[] };
