import { useMutation } from '@tanstack/react-query';
import { IdpClient } from './idp-client';

import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';

export function useRedoRecommendation(
  recommendationId: string,
  onSuccess: (any) => void
) {
  const { toast } = useNotification();
  const {
    mutateAsync: redoRecommendation,
    isError,
    isPending,
  } = useMutation({
    mutationFn: async () => {
      await IdpClient.deleteRecommendation(recommendationId);
      return await IdpClient.createRecommendationPlan(
        CreatePlanWizardSteps.DURATION
      );
    },
    onSuccess,
    onError: (error: any) => {
      Sentry.captureException(error);

      toast({
        content: 'Erro ao tentar refazer recomendação.',
        type: 'error',
      });
    },
  });

  return {
    error: !!isError,
    isPending,
    redoRecommendation,
  };
}
