import React from 'react';
import { styled } from '@mui/material/styles';
import { ResourceCourseSources } from '@/react/components/refund/refund-request/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { Button, Skeleton, Typography } from '@mui/material';
import StepActions from '@/react/components/wizard/step-actions.component';
import { useAvailableResourceApprovalsForRefund } from '@/react/data/subsidy/useAvailableResourceApprovalsForRefund';
import { ResourceApproval, EligibleResourceType } from '@/react/data/subsidy/interfaces';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { nanoid } from 'nanoid';

const Styled = {
  Button: styled(Button)(({ theme: { palette, spacing } }) => ({
    alignItems: 'flex-start',
    backgroundColor: palette.neutral[50],
    border: `1px solid ${palette.neutral[300]}`,
    borderRadius: 0,
    color: palette.neutral[800],
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    padding: spacing(3),
    width: '100%',

    '&.active': {
      backgroundColor: palette.primary[100],
      border: `1px solid ${palette.primary[900]}`,
      color: palette.neutral[900],
    },
  })),
};

const stepId = WizardStepIds.CATALOG_FORM;

const ResourceTypeForm = () => {
  const { actions, values: wizardValues } = useWizard();
  const [showAll, setShowAll] = React.useState(false);
  const { isFetching, availableResourceApprovals } = useAvailableResourceApprovalsForRefund();
  const { subsidyPolicy } = useRefundRequest();
  const [selected, setSelected] = React.useState(null);
  const NEW_ITEM_SELECT = 'NEW_ITEM';

  const visibleItems = showAll
    ? availableResourceApprovals
    : availableResourceApprovals.slice(0, 3);

  const handleSelection = (
    newItem: boolean,
    resourceApproval?: ResourceApproval
  ) => {
    if (newItem) {
      setSelected(NEW_ITEM_SELECT);
    } else {
      setSelected(resourceApproval);
    }
  };

  const handleNext = async () => {
    if (selected && selected === NEW_ITEM_SELECT) {
      subsidyPolicy?.configuration?.eligible_resource_type === EligibleResourceType.CATALOG ?
        actions.navigate(WizardStepIds.CATALOG_FORM) :
        actions.navigate(WizardStepIds.ITEM_SOURCE_FORM);
    } else {
      actions.updateValues({ subsidyPolicy, selected });
      window.location.href = `/meus-reembolsos/solicitacao/${selected.uuid}/comprovante`;
    }
  };

  const maxVisible = 3;

  const setActiveClass = (item) => {
    return selected === item ? 'active' : '';
  };

  const renderApprovedItems = () => (
    <>
      <Typography variant="body3" fontWeight={600} color="primary.900" mb={2}>
        Itens aprovados
      </Typography>
      {visibleItems.map((resourceApproval: ResourceApproval, index) => (
        <React.Fragment key={index + nanoid()}>
          <Styled.Button
            className={setActiveClass(resourceApproval)}
            onClick={() =>
              handleSelection(
                false,
                resourceApproval
              )
            }
          >
            <Typography variant="body3" fontWeight={600}>
              {resourceApproval?.name}
            </Typography>
          </Styled.Button>
        </React.Fragment>
      ))}
    </>
  );

  const renderShowAllButton = () => (
    <Button
      variant="text"
      onClick={() => setShowAll(true)}
      sx={({ palette }) => ({
        borderRadius: 0,
        alignSelf: 'flex-end',
        textDecoration: 'underline',
        ':hover': {
          textDecoration: 'underline',
          color: palette.primary[900],
          backgroundColor: palette.primary[300],
        },
      })}
    >
      {`Ver todos (${availableResourceApprovals.length})`}
    </Button>
  );

  const renderShowLessButton = () => (
    <Button
      variant="text"
      onClick={() => setShowAll(false)}
      sx={({ palette }) => ({
        borderRadius: 0,
        alignSelf: 'flex-end',
        textDecoration: 'underline',
        ':hover': {
          textDecoration: 'underline',
          color: palette.primary[900],
          backgroundColor: palette.primary[300],
        },
      })}
    >
      {`Ver menos`}
    </Button>
  );

  return (
    <div data-testid={WizardStepIds.RESOURCE_TYPE}>
      <Typography variant="h6" color="primary.900" mb={2}>
        Selecione uma opção para sua solicitação:
      </Typography>
      <Styled.Button
        className={setActiveClass(NEW_ITEM_SELECT)}
        onClick={() => handleSelection(true)}
      >
        <Typography variant="body3" fontWeight={600}>
          Novo item
        </Typography>
      </Styled.Button>
      {availableResourceApprovals.length === 0 && !isFetching && null}
      {availableResourceApprovals.length === 0 && isFetching && (
        <>
          <Typography
            variant="body3"
            fontWeight={600}
            color="primary.900"
            mb={2}
          >
            Itens aprovados
          </Typography>
          <Skeleton variant="rectangular" height={68} />
        </>
      )}
      {!isFetching &&
        availableResourceApprovals.length > 0 &&
        renderApprovedItems()}
      {availableResourceApprovals && availableResourceApprovals.length >= maxVisible
        ? availableResourceApprovals.length > maxVisible && !showAll
          ? renderShowAllButton()
          : renderShowLessButton()
        : null}
      <StepActions>
        <Button
          color="primary"
          onClick={() => {
            window.location.href = '/meus-reembolsos';
          }}
          variant="text"
        >
          Voltar
        </Button>
        <Button
          color="primary"
          disabled={!selected}
          onClick={handleNext}
          type="submit"
          variant="contained"
        >
          Próximo
        </Button>
      </StepActions>
    </div>
  );
};

export default ResourceTypeForm;
