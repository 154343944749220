import * as React from 'react';

import { BaseDialog } from '@/react/components/dialog/base-dialog.component';

import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import { IdpDialogBody } from './styles';

interface ActionsProps {
  actions: {
    label: string;
    variant: 'text' | 'contained';
    onClick: (any) => void | Promise<void>;
  }[];
}

const Actions = ({ actions }: ActionsProps) => {
  return (
    <>
      {actions.map(({ label, onClick, variant }) => (
        <Button onClick={onClick} variant={variant} key={label}>
          {label}
        </Button>
      ))}
    </>
  );
};

interface ConfirmDiscardRecommendationDialogProps {
  open: boolean;
  onCancel: () => void;
  onContinueLater: () => void;
  onConfirm: () => Promise<void>;
}
export const ConfirmDiscardRecommendationDialog = ({
  open,
  onCancel,
  onContinueLater,
  onConfirm,
}: ConfirmDiscardRecommendationDialogProps) => {
  return (
    <BaseDialog
      actionsComponent={
        <Actions
          actions={[
            { label: 'Cancelar', variant: 'text', onClick: onCancel },
            {
              label: 'Continuar depois',
              variant: 'text',
              onClick: onContinueLater,
            },
            {
              label: 'Descartar plano',
              variant: 'contained',
              onClick: onConfirm,
            },
          ]}
        />
      }
      disableEscapeKeyDown
      onClose={onCancel}
      open={open}
    >
      <IdpDialogBody>
        <Box
          component="img"
          sx={{ height: 128, width: 128, margin: '0 auto 8px' }}
        />
        <Typography variant="h6" gutterBottom>
          {'Tem certeza de que deseja sair?'}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {
            'Você ainda não concluiu a criação do seu plano. Se sair, perderá o que fez até agora.'
          }
        </Typography>
      </IdpDialogBody>
    </BaseDialog>
  );
};
