import * as React from 'react';
import { Box, Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatMoney } from '@/react/utils/money';
import { formatDuration } from '@/react/utils/time';
import { PriceTypes } from '@/react/data/catalog/interfaces';
import { CourseTypesLabels, PeriodTypesLabels, ModalityTypesLabels } from '@/react/data/catalog/constants';
import { formatDate } from '@/react/utils/date';
import { useRefundStatus } from '@/react/components/refund/utils/use-refund-status';
import { messageCancelled } from '@/react/components/refund/utils/messages-from-steps';
import { ResourceTypes, EligibleResourceType } from '@/react/data/subsidy/interfaces';
import { ResourceApproval } from '@/react/data/subsidy/interfaces';

interface ExtensiveOverviewProps {
  resourceApproval: ResourceApproval;
}

export const ExtensiveOverview: React.FC<ExtensiveOverviewProps> = ({
  resourceApproval,
}) => {
  const { mappedStatus } = useRefundStatus(resourceApproval);

  const renderCourseOverview = () => {
    const { course } = resourceApproval?.resource || {};

    return (
      <>
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Tipo de item
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">{resourceApproval?.type.value}</Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Instituição
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">{course?.institution_name}</Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Curso
          </Typography>
        </Grid>
        {course?.source === 'external' ? (
          <Grid item xs={12} md={10}>
            <Typography variant="body3">{resourceApproval?.name}</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} md={10}>
            <Typography variant="body3">
              {resourceApproval?.name
                ? resourceApproval.name.split(',')[0]
                : []}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Categoria
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">
            {CourseTypesLabels[course?.course_type]}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Modalidade
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">
            {ModalityTypesLabels[course?.modality]}
          </Typography>
        </Grid>

        {(course?.modality === 'presential' ||
          course?.modality === 'hybrid') && (
          <>
            <Grid item xs={12} md={2}>
              <Typography variant="body3" fontWeight={600}>
                Estado
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="body3">{course?.address.state}</Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography variant="body3" fontWeight={600}>
                Cidade
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="body3">{course?.address.city}</Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography variant="body3" fontWeight={600}>
                Polo
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="body3">{course?.address.campus}</Typography>
            </Grid>
          </>
        )}

        {course?.period !== null && (
          <>
            <Grid item xs={12} md={2}>
              <Typography variant="body3" fontWeight={600}>
                Turno
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="body3">
                {PeriodTypesLabels[course?.period]}
              </Typography>
            </Grid>
          </>
        )}

        {course?.workload !== null && (
          <>
            <Grid item xs={12} md={2}>
              <Typography variant="body3" fontWeight={600}>
                Carga horária
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="body3">{course?.workload}</Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Duração
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          {course?.duration_type && Number.isFinite(course?.duration) && (
            <Typography variant="body3">
              {formatDuration(course?.duration_type, course?.duration)}
            </Typography>
          )}
        </Grid>
      </>
    );
  };

  const renderLearningAidOverview = (type) => {
    const label = {
      [ResourceTypes.BOOK]: 'Nome do Livro',
      [ResourceTypes.EVENT_OR_LECTURE]: 'Nome do Evento',
      [ResourceTypes.OTHERS]: 'Nome do Item'
    }
  
    return (
      <>
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>{label[resourceApproval?.type.label]}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">{resourceApproval?.name}</Typography>
        </Grid>
      </>
    );
  }

  return (
    <>
      {mappedStatus && mappedStatus === 'canceledResourceApprovalStepOne' && (
        <Grid container>
          <Grid
            item
            xs={12}
            sx={({ spacing }) => ({
              paddingBottom: spacing('0.5'),
              marginLeft: spacing(2),
              marginBottom: spacing(2),
            })}
          >
            <Chip
              label={`${messageCancelled(resourceApproval)} ${formatDate(resourceApproval?.decided_at)}`}
              variant="negativeRequestResponse"
            />
          </Grid>
        </Grid>
      )}
      <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12} md={2}>
            <Typography variant="body3" fontWeight={600}>
              Catálogo
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="body3">
              {resourceApproval?.resource?.course?.source === EligibleResourceType.CATALOG
                ? 'Plataforma Galena'
                : 'Fora da Galena'}
            </Typography>
          </Grid>
          {resourceApproval?.type.label === ResourceTypes.COURSE ? 
            renderCourseOverview() : 
            renderLearningAidOverview(resourceApproval?.type.label)}
          
          <Grid item xs={12} md={2}>
            <Typography variant="body3" fontWeight={600}>
              Tipo de pagamento
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="body3">
              {resourceApproval?.sale_type === PriceTypes.one_off ? 'Único' : 'Mensal'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography variant="body3" fontWeight={600}>
              Valor
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="body3">
              {formatMoney(Number(resourceApproval?.sale_price))}
            </Typography>
          </Grid>
          {(resourceApproval?.resource.course?.link != null || resourceApproval?.resource?.learning_aid?.link != null) && (
            <>
              <Grid item xs={12} md={2}>
                <Typography variant="body3" fontWeight={600}>
                  Link
                </Typography>
              </Grid>

              <Grid item xs={12} md={10}>
                <Typography variant="body3">
                  {resourceApproval?.type.label === ResourceTypes.COURSE ?
                    resourceApproval?.resource.course?.link :
                    resourceApproval?.resource.learning_aid?.link
                  }</Typography>
              </Grid>
            </>
          )}
          {resourceApproval?.request_reason && (
            <>
              <Grid item xs={12} md={2}>
                <Typography variant="body3" fontWeight={600}>
                  Motivo
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography variant="body3">
                  {resourceApproval?.request_reason  || ''}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid item xs={12}>
        <Box
          sx={{
            px: 2,
            my: 2,
          }}
        >
          <Stack spacing={2}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 0, sm: 3 }}
            >
              <Box flexGrow={1}>
                <Typography
                  component="div"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                  variant="body3"
                >
                  Custo total do item
                </Typography>
              </Box>
              <Box>
                <Typography
                  component="div"
                  display="flex"
                  justifyContent={{ xs: 'left', sm: 'right' }}
                  variant="body3"
                >
                  {formatMoney(parseFloat(resourceApproval?.sale_price))}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 4 }}
            >
              <Box flexGrow={1}>
                <Typography
                  component="div"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                  variant="body3"
                >
                  Valor máximo reembolsável
                </Typography>
              </Box>
              <Box>
                <Typography
                  component="div"
                  display="flex"
                  justifyContent={{ xs: 'left', sm: 'right' }}
                  variant="body3"
                >
                  {formatMoney(
                    parseFloat(resourceApproval?.requested_refundable_amount)
                  )}
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
            mt={2}
          >
            <Typography component="p" variant="body3">
              Calculado de acordo com as regras de reembolso da sua política. O
              reembolso final depende também do seu saldo disponível.
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </>
  );
};

export default ExtensiveOverview;
