import React from "react";
import { Grid, Typography } from "@mui/material";
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatDate } from "@/react/utils/date";
import { ResourceTypes } from "@/react/data/subsidy/interfaces";

export const BriefSummary = () => {
  const {resourceApproval } = useRefundRequest();

  const getSummaryText = (resourceType: string, name: string, institutionName?: string) => {
    const categoryMap = {
      [ResourceTypes.COURSE]: 'Curso',
      [ResourceTypes.BOOK]: 'Livro',
      [ResourceTypes.EVENT_OR_LECTURE]: 'Evento ou Palestra',
      [ResourceTypes.OTHERS]: 'Outros',
    }

    return resourceType === ResourceTypes.COURSE ?
      `${categoryMap[resourceType]} | ${name.split(', ')[0]} | ${institutionName}` :
      `${categoryMap[resourceType]} | ${name}`;
  }

  return (
    <Grid container>
      <Grid item xs={12} justifyContent="center"
        sx={({palette, spacing}) => ({
          color: palette.neutral[800],
          marginBottom: spacing(2),
          paddingBottom: spacing('0.5'),
          paddingTop: spacing('0.5'),
        })}>
          <Typography variant="body3">
            {getSummaryText(resourceApproval?.type.label, resourceApproval?.name, resourceApproval?.resource.course?.institution_name)}
          </Typography>
          <Typography variant="body3">
            { resourceApproval?.decided_at && `Pedido aprovado em: ${formatDate(resourceApproval?.decided_at)}`}
          </Typography>
      </Grid>
    </Grid>
  );
};
