import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  ResourceTypes,
  RefundableRequestByResource,
  RefundableResponseByResource,
} from './interfaces';
import { SubsidyClient } from './subsidy-client';

export function useRefundableValueByResource(
  resourceApprovalRequest: any,
  subsidyPolicyUuid: string
): UseQueryResult<number, Error> {
  const fetchRefundableValue = async (): Promise<number> => {
    const payload: RefundableRequestByResource = {
      refund_flow: true,
      resource: {
        type: resourceApprovalRequest.resource.type,
        amount: resourceApprovalRequest.resource.amount,
        sale_type: resourceApprovalRequest.resource.sale_type,
        ...(resourceApprovalRequest.resource.type === ResourceTypes.COURSE && {
          data: {
            course: {
              id: resourceApprovalRequest.resource.data?.course?.id,
              modality: resourceApprovalRequest.resource.data?.course?.modality,
              source: resourceApprovalRequest.resource.data?.course?.source,
              course_type: resourceApprovalRequest.resource.data?.course?.course_type,
              duration: resourceApprovalRequest.resource.data?.course?.duration,
              duration_type: resourceApprovalRequest.resource.data?.course?.duration_type,
              period: resourceApprovalRequest.resource.data?.course?.period,
            },
          },
        }),
      },
    };

    const response: RefundableResponseByResource =
      await SubsidyClient.calculateRefundableAmountByResource(
        payload,
        subsidyPolicyUuid
      );

    const rawValue = response.refundable_amount ?? '0';
    const parsedValue = parseFloat(rawValue);

    if (isNaN(parsedValue)) {
      throw new Error('Invalide refundable value');
    }

    return parsedValue;
  };

  const queryKey = [
    'useRefundableValueByResource',
    resourceApprovalRequest.resource.type,
    resourceApprovalRequest.resource.amount,
    resourceApprovalRequest.resource.sale_type,
    subsidyPolicyUuid,
  ];

  const queryResult = useQuery<number, Error>({
    queryKey,
    queryFn: fetchRefundableValue,
    enabled: Boolean(resourceApprovalRequest.resource.type && resourceApprovalRequest.resource.amount && subsidyPolicyUuid),
    retry: 2,
  });

  return queryResult;
}
