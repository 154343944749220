export enum RefundRequestSteps {
  INVOICE_ATTACHMENT = 'invoiceAttachment',
  INVOICE_VALIDATION = 'invoiceValidation',
  INVOICE_EDIT = 'invoiceEdit',
  PAYMENT = 'payment',
  RESOURCE_APPROVAL = 'resourceApproval',
  RESOURCE_DESCRIPTION = 'resourceDescription',
  REFUND_FORM_EDIT = 'refundFormEdit',
}

export enum ResourceCourseSources {
  CATALOG = 'catalog',
  EXTERNAL = 'external',
}

export type ResourceCourseAddress = {
  campus_id?: number;
  campus?: string;
  city: string;
  state: string;
};

export type RefundEntitiesUuids = {
  refundRequestUuid?: string;
  resourceApprovalUuid: string;
};
