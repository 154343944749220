import { useQuery } from '@tanstack/react-query';

import { useNotification } from '@/react/components/notification';
import { IdpClient } from './idp-client';
import { ProfileBasedRecommendation } from './interfaces';

export const queryKey = ['useGetProfileBasedRecommendation'];

type getProfileBasedRecommendationResult = {
  error: boolean;
  isPending: boolean;
  isFetching: boolean;
  profileBasedRecommendation: Partial<ProfileBasedRecommendation> | null;
};

export function useGetProfileBasedRecommendation(
  recommendationId: string
): getProfileBasedRecommendationResult {
  const { toast } = useNotification();

  const { data, error, isPending, isFetching } =
    useQuery<ProfileBasedRecommendation>({
      queryFn: async () => {
        try {
          const result =
            await IdpClient.getProfileBasedRecommendations(recommendationId);
          return result;
        } catch (error) {
          toast({
            content: 'Erro ao buscar recomendação de plano',
            type: 'error',
          });
          return null;
        }
      },
      queryKey: [...queryKey, recommendationId],
    });

  const profileBasedRecommendation = data;

  return {
    error: !!error,
    isPending,
    isFetching,
    profileBasedRecommendation,
  };
}
