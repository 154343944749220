import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useNotification } from '@/react/components/notification';
import { IdpClient } from './idp-client';
import { Plan, CreatePlanType } from './interfaces';

type useGetPlanType = {
  error: boolean;
  isFetching: boolean;
  plan: Plan | null;
};

export const useGetPlanKey = 'useGetPlan';

export function useGetPlan(planId: string): useGetPlanType {
  const { data, isFetching, error, refetch } = useQuery<Plan>({
    queryKey: [useGetPlanKey, planId],
    queryFn: async () => {
      return IdpClient.showPlan(planId);
    },
    refetchOnMount: false,
  });

  const plan = data || null;

  return {
    error: !!error,
    isFetching,
    plan,
  };
}

type useCreatePlanType = {
  updatePlan: ({
    planId,
    payload,
  }: {
    planId: string;
    payload: CreatePlanType;
  }) => Promise<Plan>;
};

export function useUpdatePlan(): useCreatePlanType {
  const queryClient = useQueryClient();

  const { toast } = useNotification();
  const { mutateAsync: updatePlan } = useMutation({
    mutationFn: ({
      planId,
      payload,
    }: {
      planId: string;
      payload: CreatePlanType;
    }) => {
      return IdpClient.updatePlan(planId, payload);
    },
    onSuccess: (updatedPlan) => {
      toast({ content: 'Plano atualizado', type: 'success' });
      queryClient.setQueryData(
        [useGetPlanKey, updatedPlan.id],
        () => updatedPlan
      );
    },
    onError: () => {
      toast({
        content: 'Erro ao atualizar plano',
        type: 'error',
      });
    },
  });

  return {
    updatePlan,
  };
}
