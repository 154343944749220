import * as React from 'react';
import { LinearProgress } from '@mui/material';

import { ListRefundRequest } from './list-refund-request.component';
import { ResourceApprovalInformation } from './resource-approval-information.component';
import { DetailsResourceApporvalItem } from './details-resource-apporval-item';
import { ResourceApprovalDescriptionProps } from './intefaces';

export const ResourceApprovalSkeleton: React.FC<
  ResourceApprovalDescriptionProps
> = ({ resourceApprovalsDetails, isLoading }) => {
  if (isLoading) {
    return <LinearProgress />;
  }

  if (!resourceApprovalsDetails) {
    return <div>Erro ao carregar detalhes do recurso</div>;
  }

  return (
    <>
      <ResourceApprovalInformation
        resourceApprovalsDetails={resourceApprovalsDetails}
        isLoading={isLoading}
      />
      <DetailsResourceApporvalItem
        resourceApprovalsDetails={resourceApprovalsDetails}
      />
      <ListRefundRequest resourceApproval={resourceApprovalsDetails} />
    </>
  );
};
