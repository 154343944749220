import { z } from 'zod';
import { updatePlanSchema } from '@/react/data/idp/schemas';
import { ProfileBasedRecommendation } from '@/react/data/idp/profile';

type CreatePlanType = z.infer<typeof updatePlanSchema>;

enum PlanStatuses {
  DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
}

type PlanResponse = {
  description: string;
  duration_in_months: number;
  ended_at: string;
  estimated_ends_at: string;
  id: string;
  started_at: string;
  status: PlanStatuses;
  title: string;
  profile_based_recommendation?: ProfileBasedRecommendation;
};

type PlansResponseType = {
  plans: PlanResponse[];
};

type PlanType = Omit<
  PlanResponse,
  | 'duration_in_months'
  | 'started_at'
  | 'estimated_ends_at'
  | 'ended_at'
  | 'profile_based_recommendation'
> & {
  durationInMonths: number;
  endedAt: string;
  estimatedEndsAt: string;
  startedAt: string;
  status: PlanStatuses;
  profileBasedRecommendation?: ProfileBasedRecommendation;
};

type PlansByStatus = {
  [PlanStatuses.DRAFT]: PlanType;
  [PlanStatuses.IN_PROGRESS]: PlanType;
  [PlanStatuses.CLOSED]: PlanType[];
};

export {
  type CreatePlanType,
  PlanStatuses,
  type PlanResponse,
  type PlansByStatus,
  type PlansResponseType,
  type PlanType,
};
