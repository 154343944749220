import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { getRecommendationProfile } from '@/react/data/recommendation';
import { useProfile } from '@/react/components/idp/create-plan/state';
import { formatDuration } from '@/react/utils/time';
import { formatMoney } from '@/react/utils/money';
import {
  GoalsLabels,
  LanguagesLabels,
  ScholaritiesLabels,
  SenioritiesLabels,
  GOALS,
} from '@/react/data/recommendation/constants';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { Modalities, Profile } from '@/react/data/recommendation/interfaces';
import { CourseTypesLabels } from '@/react/data/catalog/constants';
import { ProfileEditDialog } from '@/react/components/idp/profile-edit';

const Section = ({
  children,
  id,
  title,
}: {
  children: React.ReactNode;
  id: string;
  title: string;
}) => {
  return (
    <Box data-testid={`${id}-section`}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Stack spacing={2}>{children}</Stack>
    </Box>
  );
};

const SectionSkeleton = () => {
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <Skeleton width="25%" />
      </Typography>
      <Stack spacing={2}>
        <Skeleton variant="rounded" height={80} />
      </Stack>
    </Box>
  );
};

const ProfileItem = ({
  children,
  id,
  title,
}: {
  children?: React.ReactNode;
  id: string;
  title: string;
}) => {
  const { actions: profileActions } = useProfile();

  const handleClick = () => {
    profileActions.edit(id);
  };

  const Blank = () => (
    <Typography component="p" variant="body3">
      Não informado
    </Typography>
  );

  return (
    <Card>
      <CardActionArea onClick={handleClick}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            p: 2,
            width: '100%',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              component="p"
              variant="body3"
              fontWeight="bold"
              sx={{ mb: 1 }}
            >
              {title}
            </Typography>
            {children || <Blank />}
          </Box>
          <Box>
            <ChevronRightRoundedIcon sx={{ display: 'block' }} />
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const Chips = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack direction="row" gap={2} flexWrap="wrap">
      {children}
    </Stack>
  );
};

const ObjectivesSection = ({ profile }: { profile: Partial<Profile> }) => {
  const isCarreerChange = profile.goal === GOALS.CARRER_CHANGE;
  const hasDesiredJob = !!Object.keys(profile.desired_job).length;

  return (
    <Section id="objectives" title="Objetivos">
      <ProfileItem id="goal" title="Objetivo de desenvolvimento">
        {profile.goal && (
          <Typography component="p" variant="body3">
            {GoalsLabels[profile.goal]}
          </Typography>
        )}
      </ProfileItem>
      {isCarreerChange && (
        <ProfileItem id="desired_job" title="Cargo desejado">
          {hasDesiredJob && (
            <Typography component="p" variant="body3">
              {profile.desired_job.name_pt}
            </Typography>
          )}
        </ProfileItem>
      )}
    </Section>
  );
};

const ExperiencesSection = ({ profile }: { profile: Partial<Profile> }) => {
  return (
    <Section id="experiences" title="Experiências">
      <ProfileItem id="scholarity" title="Formação">
        {profile.scholarity && (
          <Typography component="p" variant="body3">
            {ScholaritiesLabels[profile.scholarity]}
          </Typography>
        )}
      </ProfileItem>
      <ProfileItem id="current_job" title="Cargo atual">
        {profile.current_job.name_pt && (
          <Typography component="p" variant="body3">
            {profile.current_job.name_pt}
          </Typography>
        )}
      </ProfileItem>
      <ProfileItem id="seniority" title="Senioridade">
        {profile.seniority && (
          <Typography component="p" variant="body3">
            {SenioritiesLabels[profile.seniority]}
          </Typography>
        )}
      </ProfileItem>
    </Section>
  );
};

const InterestsSection = ({ profile }: { profile: Partial<Profile> }) => {
  return (
    <Section id="interests" title="Interesses">
      <ProfileItem id="course_types" title="Tipos de curso">
        {!!profile.course_types.length && (
          <Chips>
            {profile.course_types.map((type) => (
              <Chip
                key={type}
                label={CourseTypesLabels[type]}
                sx={{ textTransform: 'capitalize' }}
                variant="outlined"
              />
            ))}
          </Chips>
        )}
      </ProfileItem>
      <ProfileItem id="knowledge_areas" title="Temas">
        {!!profile.knowledge_areas.length && (
          <Chips>
            {profile.knowledge_areas.map((area) => (
              <Chip
                key={area.id}
                label={area.description_pt_br}
                variant="outlined"
              />
            ))}
          </Chips>
        )}
      </ProfileItem>
      <ProfileItem id="languages" title="Idiomas">
        {!!profile.languages.length && (
          <Chips>
            {profile.languages.map((language) => (
              <Chip
                key={language}
                label={LanguagesLabels[language]}
                variant="outlined"
              />
            ))}
          </Chips>
        )}
      </ProfileItem>
    </Section>
  );
};

const PreferencesSection = ({ profile }: { profile: Partial<Profile> }) => {
  return (
    <Section id="preferences" title="Preferências">
      <ProfileItem id="address" title="Localização">
        {(profile.address_city || profile.address_state) && (
          <Typography component="p" variant="body3">
            {profile?.address_city} - {profile?.address_state}
          </Typography>
        )}
      </ProfileItem>
      <ProfileItem id="modalities" title="Modalidades">
        {!!profile.modalities.length && (
          <Chips>
            {profile.modalities.map((modality) => (
              <Chip
                key={modality}
                label={Modalities[modality]}
                variant="outlined"
              />
            ))}
          </Chips>
        )}
      </ProfileItem>
      <ProfileItem id="monthly_budget" title="Orçamento mensal">
        <Typography component="p" variant="body3">
          {profile.max_budget_per_month
            ? `Até ${formatMoney(profile.max_budget_per_month, false)}`
            : 'Qualquer valor'}
        </Typography>
      </ProfileItem>
      <ProfileItem id="weekly_availability" title="Disponibilidade semanal">
        {(profile.min_hours_available_per_week ||
          profile.max_hours_available_per_week) && (
          <Typography component="p" variant="body3">
            {!!profile.min_hours_available_per_week &&
              `Mais de ${formatDuration('hour', profile.min_hours_available_per_week)}`}
            {!!profile.max_hours_available_per_week &&
              `Até ${formatDuration('hour', profile.max_hours_available_per_week)}`}
          </Typography>
        )}
      </ProfileItem>
    </Section>
  );
};

const LoadingContent = () => {
  return (
    <Stack spacing={3}>
      <SectionSkeleton />
      <SectionSkeleton />
    </Stack>
  );
};

const Content = () => {
  const { isPending, profile } = getRecommendationProfile();
  const { actions: profileActions, context: profileContext } = useProfile();

  if (isPending) return <LoadingContent />;
  if (!profile) return null;

  return (
    <>
      <Stack spacing={3}>
        <ObjectivesSection profile={profile} />
        <ExperiencesSection profile={profile} />
        <InterestsSection profile={profile} />
        <PreferencesSection profile={profile} />
      </Stack>
      {!!profileContext && (
        <ProfileEditDialog
          context={profileContext}
          onClose={() => profileActions.edit(null)}
          open={!!profileContext}
          profile={profile}
        />
      )}
    </>
  );
};

export const PlanProfile: React.ElementType<WizardStepComponent> = ({
  onNext,
  onPrevious,
}) => {
  const handlePrevious = () => {
    if (onPrevious) {
      onPrevious();
    }
  };

  const handleNext = () => {
    if (onNext) {
      onNext();
    }
  };

  return (
    <>
      <Box mt={3}>
        <Typography variant="h6">
          Quais são seus objetivos e interesses para este plano?
        </Typography>
      </Box>
      <Box mt={3}>
        <Content />
      </Box>
      <WizardStepActions>
        <Button color="primary" onClick={handlePrevious} variant="text">
          Voltar
        </Button>
        <Button
          color="primary"
          onClick={handleNext}
          type="submit"
          variant="contained"
        >
          Próximo
        </Button>
      </WizardStepActions>
    </>
  );
};
