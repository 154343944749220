import React from 'react';
import { styled } from '@mui/material/styles';
import { ResourceCourseSources } from '@/react/components/refund/refund-request/interfaces';
import { ResourceTypes } from '@/react/data/subsidy/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { Button, Typography } from '@mui/material';
import StepActions from '@/react/components/wizard/step-actions.component';

const Styled = {
  Button: styled(Button)(({ theme: { palette, spacing } }) => ({
    alignItems: 'flex-start',
    backgroundColor: palette.neutral[100],
    border: `1px solid ${palette.neutral[300]}`,
    borderRadius: 0,
    color: palette.neutral[800],
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    padding: spacing(3),
    width: '100%',

    '&.active': {
      backgroundColor: palette.primary[100],
      border: `1px solid ${palette.primary[900]}`,
      color: palette.neutral[900],
    },
  })),
};

const stepId = WizardStepIds.ITEM_SOURCE_FORM;

const ItemSourceForm = () => {
  const { actions, values: wizardValues } = useWizard();
  const [selected, setSelected] = React.useState<ResourceCourseSources>(
    wizardValues[stepId]
  );

  const handleSelection = (source: ResourceCourseSources) => {
    setSelected(source);
  };

  const handleNext = () => {
    actions.updateValues({
      [stepId]: selected,
      [WizardStepIds.RESOURCE_TYPE]:
        selected === ResourceCourseSources.CATALOG
          ? ResourceTypes.COURSE
          : null,
    });

    const destination =
      selected === ResourceCourseSources.CATALOG
        ? WizardStepIds.CATALOG_FORM
        : WizardStepIds.EXTERNAL_RESOURCE_TYPE_FORM;

    actions.navigate(destination);
  };

  const cssClass = (source: ResourceCourseSources) =>
    selected === source ? 'active' : '';

  return (
    <div data-testid={WizardStepIds.ITEM_SOURCE_FORM}>
      <Typography variant="h6" color="primary.900" mb={2}>
        Onde você encontrou o item?
      </Typography>
      <Styled.Button
        className={cssClass(ResourceCourseSources.CATALOG)}
        onClick={() => handleSelection(ResourceCourseSources.CATALOG)}
      >
        <Typography variant="body3" fontWeight={600}>
          Aqui, na plataforma Galena
        </Typography>
        <Typography variant="body3" fontWeight={400}>
          Qualquer item encontrado no catálogo da Galena.
        </Typography>
      </Styled.Button>
      <Styled.Button
        className={cssClass(ResourceCourseSources.EXTERNAL)}
        onClick={() => handleSelection(ResourceCourseSources.EXTERNAL)}
      >
        <Typography variant="body3" fontWeight={600}>
          Fora da plataforma Galena
        </Typography>
        <Typography variant="body3" fontWeight={400}>
          Item que não existe no catálogo da Galena.
        </Typography>
      </Styled.Button>
      <StepActions>
        <Button
          color="primary"
          onClick={() => {
            window.location.href = '/meus-reembolsos';
          }}
          variant="text"
        >
          Voltar
        </Button>
        <Button
          color="primary"
          disabled={!selected}
          onClick={handleNext}
          type="submit"
          variant="contained"
        >
          Próximo
        </Button>
      </StepActions>
    </div>
  );
};

export default ItemSourceForm;
