import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentProps } from 'react';
import { CreatePlan } from '@/react/components/idp/create-plan';

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
})
export class IdpCreatePlanComponent implements OnInit {
  IdpCreatePlanComponent = CreatePlan;
  createPlanProps: ComponentProps<typeof CreatePlan> = {
    recommendationId: undefined,
  };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.createPlanProps = {
        recommendationId: params.recommendationId,
      };
    });
  }
}
