import { z } from 'zod';
import { createActivitySchema } from '@/react/data/idp/schemas';
import { ResourceResponseType } from '@/react/data/idp/resource';

type CreateActivityInterface = z.infer<typeof createActivitySchema>;

enum ActivityStatuses {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

const ActivityStatusLabel = {
  [ActivityStatuses.PENDING]: 'A fazer',
  [ActivityStatuses.IN_PROGRESS]: 'Em andamento',
  [ActivityStatuses.COMPLETED]: 'Feito',
};

type ActivityResponseType = {
  id: string;
  plan_id: string;
  title: string;
  description: string;
  status: ActivityStatuses;
  resource_url?: string;
  resource_id?: number;
  resource_data?: ResourceResponseType;
};

type ActivitiesResponseType = {
  activities: ActivityResponseType[];
};

export {
  ActivityStatuses,
  type ActivityResponseType,
  type ActivitiesResponseType,
  type CreateActivityInterface,
  ActivityStatusLabel,
};
