import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubsidyClient } from './subsidy-client';
import { DecideResourceApprovalRequest } from '@/react/data/subsidy/interfaces';
import { useResourceApprovalsDetailsQueryKey } from '@/react/data/subsidy/useResourceApprovalsDetails';

export const useDecideResourceApproval = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: decideResourceApproval, isPending } = useMutation(
    {
      mutationFn: (input: DecideResourceApprovalRequest) => {
        return SubsidyClient.decideResourceApproval({request: input});
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({
          queryKey: useResourceApprovalsDetailsQueryKey(variables.resource_approval_uuid),
        });
      },
    }
  );

  return { isPending, decideResourceApproval };
};
