import React from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface SectionHeaderProps {
  title?: string;
  handleBackNavigation?: () => void;
}

const SectionHeader = ({ title, handleBackNavigation }: SectionHeaderProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 64,
        }}
      >
        <Stack alignItems="center" direction="row" gap={1} sx={{ flex: 1 }}>
          {handleBackNavigation && (
            <IconButton
              aria-label="voltar"
              size="small"
              onClick={handleBackNavigation}
              sx={{
                width: 32,
                height: 32,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          {title && (
            <Typography variant="body3" fontWeight={600}>
              {title}
            </Typography>
          )}
        </Stack>
      </Box>
    </>
  );
};

export { SectionHeader };
