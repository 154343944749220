import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SubsidyClient } from '@/react/data/subsidy/subsidy-client';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { useAccordion } from '@/react/components/refund/accordion/state/useAccordion';
import { RefundRequestSteps } from '@/react/components/refund/refund-request/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatMoney } from '@/utils/money-parser';
import StepActions from '@/react/components/wizard/step-actions.component';
import { ResourceCourseSources } from '../../interfaces';
import ResourceApprovalCreatedDialog from '@/react/components/refund/refund-request/resource-approval-created-dialog.component';
import { useNotification } from '@/react/components/notification';
import { openToast } from '@/react/components/refund/utils/openToast';
import { useRefundableValueByResource } from '@/react/data/subsidy/useRefundableValueByResource';
import { ResourceTypes, EligibleResourceType } from '@/react/data/subsidy/interfaces';

export const RefundableValue = ({
  isValid,
  value,
}: {
  isValid: boolean;
  value: number;
}) => {
  return (
    <Box
      component="span"
      sx={({ palette }) => ({
        color: !isValid ? palette.error.main : 'inherit',
      })}
    >
      {formatMoney(value)}
    </Box>
  );
};

const formatCreateResourceApproval = ({ formValues, policyUuid, formType }) => {
  if (formType === WizardStepIds.CATALOG_FORM) {
    return {
      policy: {
        uuid: policyUuid,
      },
      resource: {
        amount: formValues?.amount,
        data: {
          course: {
            course_type: formValues?.courseType,
            duration_type: formValues?.durationType?.trim() !== '' ? formValues?.durationType : null,
            duration: formValues?.duration ?? null,
            id: formValues?.course?.id,
            institution_name: formValues?.institution?.name,
            modality: formValues?.modality,
            offer_identifier: formValues?.offerIdentifier,
            period: formValues?.period?.value,
            source: ResourceCourseSources.CATALOG,
            workload: formValues?.workload ?? null,
            ...(formValues.campus && formValues.city && formValues.state && {
                address: {
                  campus: `${formValues?.campus?.label} - ${formValues?.campus?.value}`,
                  city: formValues?.city?.value,
                  state: formValues?.state?.value,
                },
              }
            ),
          },
        },
        link: formValues?.link?.trim() !== '' ? formValues?.link : null,
        name: formValues?.course?.label,
        request_reason: formValues?.requestReason?.trim() !== '' ? formValues?.requestReason : null,
        sale_type: formValues?.salesType,
        type: ResourceTypes.COURSE,
      },
    };
  } else if (formType === WizardStepIds.EXTERNAL_COURSE_FORM) {
    return  {
      policy: {
        uuid: policyUuid,
      },
      resource: {
        amount: formValues.amount,
        data: {
          course: {
            course_type: formValues.courseType,
            duration_type: formValues?.durationType?.trim() !== '' ? formValues?.durationType : null,
            duration: formValues.duration ?? null,
            id: formValues.course?.id ?? null,
            institution_name: formValues.institution,
            modality: formValues.modality,
            period: formValues.period?.trim() !== '' ? formValues.period : null,
            source: ResourceCourseSources.EXTERNAL,
            workload: formValues.workload ?? null,
            ...(formValues.campus && formValues.campus?.trim() !== '' &&
              formValues.city && formValues.city?.trim() !== '' &&
              formValues.state && formValues.state?.trim() !== '' && {
                address: {
                  campus: `${formValues.campus} - ${formValues.campus}`,
                  city: formValues.city,
                  state: formValues.state,
                },
              }
            ),
          },
        },
        link: formValues?.link?.trim() !== '' ? formValues?.link : null,
        name: formValues.course,
        request_reason: formValues?.requestReason?.trim() !== '' ? formValues?.requestReason : null,
        sale_type: formValues.saleType,
        type: ResourceTypes.COURSE,
      },
    };
  } else {
    const stepIdToResourceTypeMapping = {
      [WizardStepIds.EXTERNAL_BOOK_FORM]: ResourceTypes.BOOK,
      [WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM]: ResourceTypes.EVENT_OR_LECTURE,
      [WizardStepIds.EXTERNAL_OTHER_FORM]: ResourceTypes.OTHERS,
    };

    return {
      policy: {
        uuid: policyUuid,
      },
      resource: {
        amount: formValues.amount,
        link: formValues?.link?.trim() !== '' ? formValues?.link : null,
        name: formValues.name,
        request_reason: formValues?.requestReason?.trim() !== '' ? formValues?.requestReason : null,
        sale_type: formValues.salesType,
        type: stepIdToResourceTypeMapping[formType],
      },
    };
  }
};

const initialState = {
  isSubmitting: false,
  resourceApprovalCreated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CREATED':
      return { ...state, resourceApprovalCreated: action.payload };
    case 'SET_SUBMITTING':
      return { ...state, isSubmitting: action.payload };
    default:
      return state;
  }
};

const ResourceApprovalSummary = () => {
  const { actions: wizardActions, values: wizardValues } = useWizard();
  const { actions: accordionActions } = useAccordion();
  const { subsidyPolicy } = useRefundRequest();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { toast } = useNotification();

  const possibleFormStepIds = [
    WizardStepIds.CATALOG_FORM,
    WizardStepIds.EXTERNAL_COURSE_FORM,
    WizardStepIds.EXTERNAL_BOOK_FORM,
    WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM,
    WizardStepIds.EXTERNAL_OTHER_FORM,
  ];
  const filledStep = possibleFormStepIds.find(stepId => wizardValues[stepId]);
  const formValues = wizardValues[filledStep] ?? {};
  const formattedApproval = formatCreateResourceApproval({
    formValues,
    policyUuid: subsidyPolicy?.uuid,
    formType: filledStep,
  });


  const { data: refundableValue, isLoading, error, refetch } = useRefundableValueByResource(
    formattedApproval,
    subsidyPolicy.uuid,
  );

  const valid = refundableValue !== undefined && refundableValue > 0;

  const totalValueParsed = parseFloat(formattedApproval.resource.amount);

  const [resourceApprovalUUID, setResourceApprovalUUID] = React.useState(null);

  const handleCreateRefund = async () => {
      if (!valid) {
          return;
      }

    const requestPayload = formatCreateResourceApproval({
      formValues,
      policyUuid: subsidyPolicy.uuid,
      formType: filledStep
    });

    dispatch({ type: 'SET_SUBMITTING', payload: true });
    dispatch({ type: 'SET_CREATED', payload: false });

    try {
      const resource =
        await SubsidyClient.createResourceApproval(requestPayload);

      if (!!resource) {
        setResourceApprovalUUID(resource.resource_approval.uuid);
        dispatch({ type: 'SET_SUBMITTING', payload: false });
        dispatch({ type: 'SET_CREATED', payload: true });
      }

      openToast({
        toast,
        message: 'Solicitação de reembolso enviada com sucesso.',
        type: 'success',
      });

      accordionActions.close(RefundRequestSteps.RESOURCE_DESCRIPTION);
    } catch (error) {
      openToast({
        toast,
        message: error?.response?.data?.error,
        type: 'error',
      });

      dispatch({ type: 'SET_SUBMITTING', payload: false });
    }
  };

  const handleGoBack = () => {
    if (wizardValues.itemSourceForm === EligibleResourceType.CATALOG) {
      wizardActions.navigate(WizardStepIds.CATALOG_FORM);
    } else {
      const externalFormMapping = {
        [ResourceTypes.COURSE]: WizardStepIds.EXTERNAL_COURSE_FORM,
        [ResourceTypes.BOOK]: WizardStepIds.EXTERNAL_BOOK_FORM,
        [ResourceTypes.EVENT_OR_LECTURE]: WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM,
        [ResourceTypes.OTHERS]: WizardStepIds.EXTERNAL_OTHER_FORM,
      }

      wizardActions.navigate(externalFormMapping[wizardValues.resourceType]);
    }
  };

  return (
    <>
      <Stack spacing={2} my={2}>
        <Typography component="div" variant="body3">
          Calculado de acordo com as regras de reembolso da sua política. O
          reembolso final depende também do seu saldo disponível.
        </Typography>
      </Stack>
      <Box
        sx={{
          borderColor: 'neutral.300',
          borderStyle: 'solid',
          borderWidth: 1,
          p: 2,
          mt: 3,
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Custo total do item
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {formatMoney(totalValueParsed)}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Valor máximo reembolsável
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {isLoading ? (
                  <Skeleton width={60} />
                ) : error ? (
                  <span style={{ color: 'red' }}>Erro: {error.message}</span>
                ) : (
                  <RefundableValue isValid={valid} value={refundableValue!} />
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <StepActions>
        <Button color="primary" onClick={handleGoBack} variant="text">
          Voltar
        </Button>
        <Button
          color="primary"
          disabled={!valid}
          loading={state.isSubmitting}
          onClick={() => handleCreateRefund()}
          variant="contained"
        >
          Enviar para aprovação
        </Button>
      </StepActions>
      <ResourceApprovalCreatedDialog
        actionsVisibility={{
          waitApproval: !subsidyPolicy.configuration.auto_approval_enabled,
        }}
        open={state.resourceApprovalCreated}
        resourceApprovalUUID={resourceApprovalUUID}
      />
    </>
  );
};

export default ResourceApprovalSummary;


