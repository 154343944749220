import React from 'react';
import { Chip } from '@mui/material';
import { StatusChipProps } from '@/react/components/refund/refund-list/interfaces';
import { statusMessages } from './status-messages';

export const StatusChip: React.FC<StatusChipProps> = ({ statusApproval }) => {
  const statusDefault = {
    message: 'Informação não disponível',
    variant: 'default',
  };

  const statusInfo = statusMessages[statusApproval]?.chip || {
    ...statusDefault,
  };

  const { message, variant } = statusInfo || {
    ...statusDefault,
  };

  return (
    <>
      <Chip label={message} variant={variant} />
    </>
  );
};
