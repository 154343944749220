import { ApiService } from 'src/app/services/api.service';
import { EventEmitter, Injectable } from '@angular/core';
import { BootstrapData, Plan } from '@/react/components/cursos/home.interfaces';

@Injectable({
  providedIn: 'root',
})
export class BootstrapDataService {
  readonly router: string = 'users/bootstrap-data';

  isLoaded = false;

  bootstrapData: BootstrapData = null;
  plan: Plan = null;

  planChange = new EventEmitter<Plan>();

  constructor(private apiService: ApiService) {
    this.load();
  }

  public load() {
    this.apiService.getAll(`${this.router}`).subscribe(
      (response) => {
        this.bootstrapData = response.data;
        this.plan = this.bootstrapData.plan;
        this.isLoaded = true;
        this.planChange.emit(this.plan);
      },
      () => {
        this.plan = null;
        this.isLoaded = true;
        this.planChange.emit(this.plan);
      }
    );
  }
}
