import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentProps } from 'react';

import ResourceApprovalDetails from '@/react/components/refund/refund-management/resource-approval-details/resource-approval-details.component';

@Component({
  selector: 'component-resource-approval-detail',
  templateUrl: './resource-approval-details.component.html',
})
export class ResourceApprovalDetailsComponent implements OnInit {
  ResourceApprovalDetails = ResourceApprovalDetails;

  props: ComponentProps<typeof ResourceApprovalDetails> = {
    resourceApprovalUuid: undefined,
  };

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const { resourceApprovalUuid } = this.route.snapshot.params;

    this.props = {
      ...this.props,
      resourceApprovalUuid,
    };
  }
}
