import { useMutation } from '@tanstack/react-query';
import { IdpClient } from './idp-client';

import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';
export function useDeleteRecommendation(
  recommendationId: string,
  onSuccess: () => void
) {
  const { toast } = useNotification();
  const {
    mutateAsync: deleteRecommendation,
    isError,
    isPending,
  } = useMutation({
    mutationFn: async () => {
      return IdpClient.deleteRecommendation(recommendationId);
    },
    onSuccess: onSuccess,
    onError: (error: any) => {
      Sentry.captureException(error);

      toast({
        content: 'Erro ao deletar recomendação.',
        type: 'error',
      });
    },
  });

  return {
    error: !!isError,
    isPending,
    deleteRecommendation,
  };
}
