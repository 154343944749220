import React from 'react';
import {
  statusMapRefundRequest,
  useRefundStatus,
} from '@/react/components/refund/utils/use-refund-status';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import ExtensiveOverview from './extensive-overview/extensive-overview.component';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';

export const RefundDetails = () => {
  const { resourceApproval } = useRefundRequest();

  const { mappedStatus } = useRefundStatus(resourceApproval);

  if (!resourceApproval) return null;

  return (
    <>
      {[
        'waitingForRevisionStepTwo',
        'canceledResourceApprovalStepOne',
        'pendingDocumentationStepThree',
        'waitingForDocumentationRevisionStepFour',
        'declinedRefundRequestStepFour',
        'needsRefundRequestRevisionStepFour',
        'pendingRefundPaymentStepFive',
        'paidRefundRequestStepFive',
        statusMapRefundRequest[RefundRequestStatus.cancelled],
      ].includes(mappedStatus) && (
        <ExtensiveOverview resourceApproval={resourceApproval} />
      )}
    </>
  );
};
