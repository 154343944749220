import * as React from 'react';
import { debounce } from '@mui/material/utils';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { formatOfferFiltersParams } from '@/react/data/catalog/utils';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { useOfferFilters } from '@/react/data/catalog/useOfferFilters';
import { useBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import { useInstitutionsSearch } from '@/react/data/institutions/useInstitutionsData';
import { useCatalogSearchForRefund } from '@/react/data/catalog/useCatalogSearchForRefund';
import { CatalogFilters, PriceTypes } from '@/react/data/catalog/interfaces';
import { formatDuration } from '@/react/utils/time';
import { useWizard } from '@/react/components/wizard/state';
import { StepActions } from '@/react/components/wizard';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { MoneyField } from '@/react/components/form';
import { EligibleResourceType } from '@/react/data/subsidy/interfaces';
import ResourceApprovalModal from '@/react/components/refund/modals/resource-approval-dialog.component';

const DEBOUNCE_CATALOG_SEARCH_MS = 500;

const formatCourseLabel = (course) => {
  const formatCourseDuration = (course) => {
    const { type, quantity } = course.duration;

    return formatDuration(type, quantity);
  };

  const formattedDuration = formatCourseDuration(course);
  return `${course.course_name}, ${course.course_type.label}, ${formattedDuration}, ${course.modalities.map(({ label }) => label).join(',')}`;
};

const mountCourseOptions = (courses) => {
  return courses.map((course) => {
    const label = formatCourseLabel(course);

    return {
      courseType: course.course_type,
      id: course.id,
      label,
      modality: course.modalities[0],
      offerId: course.offer_id,
    };
  });
};

const mountStatesOptions = (states) => {
  return states.map(({ abbreviation, name }) => ({
    label: name,
    value: abbreviation,
  }));
};

const mountCitiesOptions = (cities) => {
  return cities.map((city) => ({
    label: city,
    value: city,
  }));
};

const mountHubsOptions = (hubs) => {
  return hubs.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
};

const mountPeriodsOptions = (periods) => {
  return periods.map(({ label, type }) => ({
    label,
    value: type,
  }));
};

const defaultValues = {
  amount: null,
  campus: null,
  city: null,
  course: null,
  courseType: '',
  disableDuration: false,
  duration: '',
  durationType: '',
  institution: null,
  link: '',
  modality: '',
  offerIdentifier: null,
  period: null,
  requestReason: '',
  salesType: '',
  state: null,
  workload: null,
};

const mainValidationSchema = z.object({
  courseType: z.string().min(1, 'Categoria obrigatória'),
  course: z.object(
    {
      id: z.number(),
      label: z.string(),
      offerId: z.number(),
    },
    {
      errorMap: () => {
        return { message: 'Curso obrigatório' };
      },
    }
  ),
  institution: z.object(
    {
      name: z.string(),
      id: z.number(),
    },
    {
      errorMap: () => {
        return { message: 'Instituição de ensino obrigatória' };
      },
    }
  ),
  link: z.union([z.literal(''), z.string().trim().url()]),
  offerIdentifier: z.union([z.null(), z.string()]),
  requestReason: z.string(),
  salesType: z.nativeEnum(PriceTypes, {
    errorMap: () => {
      return { message: 'Tipo de pagamento obrigatório' };
    },
  }),
  workload: z.union([
    z.null(),
    z.coerce.number().int().positive('Valor deve ser maior que zero'),
  ]),
});

const modalitySchema = z
  .object({
    campus: z.union([
      z.null(),
      z.object({
        label: z.string(),
        value: z.number(),
      }),
    ]),
    city: z.union([
      z.null(),
      z.object({
        label: z.string(),
        value: z.string(),
      }),
    ]),
    modality: z.string().min(1, 'Modalidade obrigatória'),
    period: z.union([
      z.null(),
      z.object({
        label: z.string(),
        value: z.string(),
      }),
    ]),
    state: z.union([
      z.null(),
      z.object({
        label: z.string(),
        value: z.string(),
      }),
    ]),
    amount: z.union([z.null(), z.coerce.number().optional()]),
  })
  .partial()
  .superRefine(({ campus, city, period, state, amount }, ctx) => {
    if (!state) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Estado obrigatório',
        path: ['state'],
      });
    }
    if (!city) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Cidade obrigatória',
        path: ['city'],
      });
    }
    if (!campus) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Polo obrigatório',
        path: ['campus'],
      });
    }
    if (!period) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Turno obrigatório',
        path: ['period'],
      });
    }

    if (state && city && campus && period) {
      if (amount === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Valor deve ser maior que zero',
          path: ['amount'],
        });
      }

      if (amount === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['amount_equal_zero'],
        });
      }
    }
  });

// zod has a limitation with dependent fields, so it's best to have them in separate schema
// more about this: https://github.com/colinhacks/zod/issues/479#issuecomment-1536233005
const durationSchema = z
  .object({
    disableDuration: z.boolean(),
    duration: z.coerce.number().int(),
    durationType: z.string(),
  })
  .partial()
  .superRefine(({ disableDuration, duration, durationType }, ctx) => {
    if (duration < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Valor deve ser maior que zero',
        path: ['duration'],
      });
    }

    if (!disableDuration) {
      if (!duration) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Valor obrigatório',
          path: ['duration'],
        });
      }
      if (!durationType) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Tipo obrigatório',
          path: ['duration'],
        });
      }
    }
  });

// z.intersection does not work to merge schemas here because
// we're using .refine/.superRefine which outputs ZodEffects instead of ZodObject.
// This is poorly documented in the zod documentation.
// As a workaround, we're using .and to merge the schemas.
// More about this:
// https://github.com/colinhacks/zod/issues/2474
// https://github.com/colinhacks/zod/issues/1147
const formValidationSchema = mainValidationSchema
  .and(modalitySchema)
  .and(durationSchema);

const FieldSkeleton = () => {
  return (
    <FormControl fullWidth>
      <Stack spacing={1}>
        <Typography component="div" variant="body3">
          <Skeleton width="20%" />
        </Typography>
        <Skeleton height={53} variant="rounded" />
      </Stack>
      <FormHelperText />
    </FormControl>
  );
};

const FieldWrapper = ({ loading, children }) => {
  return loading ? <FieldSkeleton /> : children;
};

const CatalogRefundForm = () => {
  const { subsidyPolicy } = useRefundRequest();
  const { actions, values } = useWizard();
  const bootstrapData = useBootstrapData();
  const { institutions, isFetching: institutionsLoading } =
    useInstitutionsSearch();
  const formValues = values[WizardStepIds.CATALOG_FORM];
  const [catalogSearchTerm, setCatalogSearchTerm] = React.useState('');
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [hubs, setHubs] = React.useState([]);
  const [periods, setPeriods] = React.useState([]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const loadingBootstrapData = bootstrapData.isFetching || institutionsLoading;
  const subsidyPolicyType =
    subsidyPolicy?.configuration?.eligible_resource_type;

  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(formValidationSchema),
  });

  React.useEffect(() => {
    reset({ ...defaultValues, ...formValues });
  }, [formValues]);

  const {
    amount,
    campus,
    city,
    courseType,
    course,
    institution,
    modality,
    period,
    salesType,
    state,
  } = watch();

  const [isDisabled, setIsDisabled] = React.useState(true);

  const catalogSearchFilters = {} as Partial<CatalogFilters>;

  if (institution) {
    catalogSearchFilters.institutions = [institution.id];
  }
  if (catalogSearchTerm) {
    catalogSearchFilters.terms = catalogSearchTerm;
  }

  const offerSearchFilters = {} as any;

  if (campus) offerSearchFilters.hub = [campus.value];
  if (state) offerSearchFilters.location = [state.value, ''];
  if (city) offerSearchFilters.location = [state.value, city.value];
  if (period) offerSearchFilters.period = [period.value];

  const { catalog: catalogData, isFetching: fetchingCatalog } =
    useCatalogSearchForRefund(catalogSearchFilters);

  const offerRequestParams = formatOfferFiltersParams({
    courseId: course?.id,
    ...offerSearchFilters,
  });

  const { data: offerFilters, isFetching: fetchingOfferFilters } =
    useOfferFilters({
      courseId: course?.id,
      enabled: !!course,
      queryParams: offerRequestParams,
    });

  const handleError = (errors) => {
    if (
      Object.keys(errors).length === 1 &&
      Object.keys(errors).includes('amount_equal_zero')
    ) {
      setIsDialogOpen(true);
    }
  };

  const handleNext = async (data) => {
    if (data.amount === 0) {
      setIsDialogOpen(true);
      return;
    }
    const formData = { ...data };
    Object.keys(formData).forEach((key) => {
      return formData[key] === undefined && delete formData[key];
    });

    const payload = {
      ...defaultValues,
      ...formData,
    };

    actions.updateValues({
      [WizardStepIds.CATALOG_FORM]: payload,
    });
    actions.navigate(WizardStepIds.SUMMARY);
  };

  const handleErrormessage = (error) => {
    return error ? error.message : '';
  };

  const allOfferFiltersSelected = React.useMemo(() => {
    return state && city && campus && period;
  }, [campus, city, period, state]);

  React.useEffect(() => {
    if (!fetchingOfferFilters && offerFilters) {
      const {
        cheapest_offer,
        filters: { states, cities, periods, hubs },
      } = offerFilters;

      const { duration, hash_key, pricing_description } = cheapest_offer;

      if (periods.length > 0 && pricing_description.sale_price === null) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }

      if (states.length) setStates(states);
      if (cities.length) setCities(cities);
      if (hubs.length) setHubs(hubs);
      if (periods.length) setPeriods(periods);

      if (allOfferFiltersSelected) {
        setValue(
          'amount',
          pricing_description.sale_price ?? getValues('amount')
        );
        setValue('duration', duration.quantity as any);
        setValue('durationType', duration.type);
        setValue('offerIdentifier', hash_key ?? null);
        setValue('salesType', pricing_description.type);
      } else {
        setValue('amount', defaultValues.amount);
        setValue('duration', defaultValues.duration);
        setValue('durationType', defaultValues.durationType);
        setValue('offerIdentifier', defaultValues.offerIdentifier);
        setValue('salesType', defaultValues.salesType);
      }
    }
  }, [offerFilters, fetchingOfferFilters, state, city, campus, period]);

  const handleChange = (field) => (value) => {
    const valueToSet = value === null ? '' : value;
    field.onChange(valueToSet);
  };

  const debounceCatalogSearchTerm = React.useMemo(
    () =>
      debounce((value) => {
        setCatalogSearchTerm(value);
      }, DEBOUNCE_CATALOG_SEARCH_MS),
    []
  );

  const courseOptions = mountCourseOptions(catalogData.items || []);
  const statesOptions = mountStatesOptions(states);
  const citiesOptions = mountCitiesOptions(cities);
  const hubsOptions = mountHubsOptions(hubs);
  const periodsOptions = mountPeriodsOptions(periods);

  const renderBackButton = () => {
    if (subsidyPolicyType === EligibleResourceType.CATALOG) {
      return null;
    }

    return (
      <Button
        color="primary"
        onClick={() => {
          actions.navigate(WizardStepIds.ITEM_SOURCE_FORM);
        }}
        variant="text"
      >
        Voltar
      </Button>
    );
  };

  return (
    <>
      <form
        data-testid={WizardStepIds.CATALOG_FORM}
        onSubmit={handleSubmit(handleNext, handleError)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="institution"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="institution">
                        Instituição de Ensino
                      </FormLabel>
                      <Autocomplete
                        {...field}
                        data-testid="institution-autocomplete"
                        getOptionKey={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        id="institution"
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        onChange={(_, option, reason) => {
                          if (reason === 'clear') {
                            reset({ ...defaultValues });
                          }
                          if (reason === 'selectOption') {
                            // We need to reset the form when the institution changes,
                            // but we don't want to reset the institution field itself.
                            reset({ ...defaultValues, institution: option });
                          }
                        }}
                        openOnFocus
                        options={institutions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error}
                            helperText={handleErrormessage(error)}
                            placeholder="Selecione"
                          />
                        )}
                        value={institution}
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="course"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="course">Curso</FormLabel>
                        <Autocomplete
                          {...field}
                          autoSelect
                          clearOnBlur
                          data-testid="course-name-autocomplete"
                          disabled={!institution}
                          filterOptions={(x) => x}
                          fullWidth
                          id="course"
                          includeInputInList
                          isOptionEqualToValue={(a, b) => {
                            return a.id === b.id;
                          }}
                          loading={fetchingCatalog}
                          loadingText="Carregando..."
                          noOptionsText={
                            !catalogSearchTerm
                              ? 'Digite o nome de um curso'
                              : 'Não encontramos nenhum curso com esses parâmetros.'
                          }
                          onBlur={() => {
                            setCatalogSearchTerm('');
                          }}
                          onChange={(_, option, reason) => {
                            const keepFields = {
                              ...defaultValues,
                              institution,
                            };

                            // We need to reset the form when the course changes,
                            // but we don't want to reset the institution and course fields.
                            // We also need to set the course type and modality fields.
                            if (reason === 'clear') {
                              reset(
                                { ...keepFields },
                                { keepDefaultValues: true }
                              );
                            }
                            if (reason === 'selectOption') {
                              reset(
                                {
                                  ...keepFields,
                                  course: option,
                                  courseType: option.courseType.type,
                                  modality: option.modality.type,
                                },
                                { keepDefaultValues: true }
                              );
                            }
                          }}
                          onInputChange={(_, value, reason) => {
                            if (reason === 'input') {
                              debounceCatalogSearchTerm(value);
                            }
                          }}
                          openOnFocus
                          options={courseOptions}
                          renderInput={(params) => {
                            return (
                              <TextField
                                error={!!error}
                                helperText={handleErrormessage(error)}
                                placeholder="Selecione"
                                {...params}
                              />
                            );
                          }}
                          value={course}
                        />
                      </Stack>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldWrapper
              loading={loadingBootstrapData || (fetchingOfferFilters && !state)}
            >
              <Controller
                control={control}
                name="state"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="state">Estado</FormLabel>
                      <Autocomplete
                        {...field}
                        autoSelect
                        data-testid="state-autocomplete"
                        disabled={!course}
                        fullWidth
                        id="state"
                        includeInputInList
                        isOptionEqualToValue={(a, b) => {
                          return a.value === b.value;
                        }}
                        noOptionsText="Sem opções de estado"
                        onChange={(_, option, reason) => {
                          const keepFields = {
                            ...defaultValues,
                            course,
                            courseType,
                            institution,
                            modality,
                          };

                          if (reason === 'clear') {
                            reset(
                              {
                                ...keepFields,
                              },
                              { keepDefaultValues: true }
                            );
                          }
                          if (reason === 'selectOption') {
                            reset(
                              {
                                ...keepFields,
                                state: option,
                              },
                              { keepDefaultValues: true }
                            );
                          }
                        }}
                        openOnFocus
                        options={statesOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error}
                            helperText={handleErrormessage(error)}
                            placeholder="Selecione"
                          />
                        )}
                        value={state}
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldWrapper
              loading={loadingBootstrapData || (fetchingOfferFilters && !city)}
            >
              <Controller
                control={control}
                name="city"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="city">Cidade</FormLabel>
                        <Autocomplete
                          {...field}
                          autoSelect
                          data-testid="city-autocomplete"
                          disabled={!state}
                          fullWidth
                          id="city"
                          includeInputInList
                          isOptionEqualToValue={(a, b) => {
                            return a.value === b.value;
                          }}
                          noOptionsText="Sem opções de cidade"
                          onChange={(_, option, reason) => {
                            const keepFields = {
                              ...defaultValues,
                              course,
                              courseType,
                              institution,
                              modality,
                              state,
                            };

                            if (reason === 'clear') {
                              reset(
                                {
                                  ...keepFields,
                                },
                                { keepDefaultValues: true }
                              );
                            }
                            if (reason === 'selectOption') {
                              reset(
                                {
                                  ...keepFields,
                                  city: option,
                                },
                                { keepDefaultValues: true }
                              );
                            }
                          }}
                          openOnFocus
                          options={citiesOptions}
                          renderInput={(params) => {
                            return (
                              <TextField
                                error={!!error}
                                helperText={handleErrormessage(error)}
                                placeholder="Selecione"
                                {...params}
                              />
                            );
                          }}
                          value={city}
                        />
                      </Stack>
                    </FormControl>
                  );
                }}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldWrapper
              loading={
                loadingBootstrapData || (fetchingOfferFilters && !campus)
              }
            >
              <Controller
                control={control}
                name="campus"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="campus">Polo</FormLabel>
                      <Autocomplete
                        {...field}
                        autoSelect
                        data-testid="campus-autocomplete"
                        disabled={!city}
                        fullWidth
                        id="campus"
                        includeInputInList
                        isOptionEqualToValue={(a, b) => {
                          return a.value === b.value;
                        }}
                        noOptionsText="Sem opções de polo"
                        onChange={(_, option, reason) => {
                          const keepFields = {
                            ...defaultValues,
                            city,
                            course,
                            courseType,
                            institution,
                            modality,
                            state,
                          };

                          if (reason === 'clear') {
                            reset(
                              {
                                ...keepFields,
                              },
                              { keepDefaultValues: true }
                            );
                          }
                          if (reason === 'selectOption') {
                            reset(
                              {
                                ...keepFields,
                                campus: option,
                              },
                              { keepDefaultValues: true }
                            );
                          }
                        }}
                        openOnFocus
                        options={hubsOptions}
                        renderInput={(params) => {
                          return (
                            <TextField
                              error={!!error}
                              helperText={handleErrormessage(error)}
                              placeholder="Selecione"
                              {...params}
                            />
                          );
                        }}
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldWrapper
              loading={
                loadingBootstrapData || (fetchingOfferFilters && !period)
              }
            >
              <Controller
                control={control}
                name="period"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="period">Turno</FormLabel>
                      <Autocomplete
                        {...field}
                        autoSelect
                        data-testid="period-autocomplete"
                        disabled={!campus}
                        fullWidth
                        id="period"
                        includeInputInList
                        isOptionEqualToValue={(a, b) => {
                          return a.value === b.value;
                        }}
                        noOptionsText="Sem opções de turno"
                        onChange={(_, option, reason) => {
                          const keepFields = {
                            ...defaultValues,
                            campus,
                            city,
                            course,
                            courseType,
                            institution,
                            modality,
                            state,
                          };

                          if (reason === 'clear') {
                            reset(
                              {
                                ...keepFields,
                              },
                              { keepDefaultValues: true }
                            );
                          }
                          if (reason === 'selectOption') {
                            reset(
                              {
                                ...keepFields,
                                period: option,
                              },
                              { keepDefaultValues: true }
                            );
                          }
                        }}
                        openOnFocus
                        options={periodsOptions}
                        renderInput={(params) => {
                          return (
                            <TextField
                              error={!!error}
                              helperText={handleErrormessage(error)}
                              placeholder="Selecione"
                              {...params}
                            />
                          );
                        }}
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldWrapper
              loading={
                loadingBootstrapData ||
                (!amount && allOfferFiltersSelected && fetchingOfferFilters)
              }
            >
              <Controller
                control={control}
                name="amount"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="amount">
                          {salesType === PriceTypes.tuition
                            ? 'Valor da mensalidade (R$)'
                            : 'Custo total do item (R$)'}
                        </FormLabel>
                        <TextField
                          sx={{
                            backgroundColor: isDisabled
                              ? 'neutral.200'
                              : 'white',
                          }}
                          {...field}
                          disabled={isDisabled}
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          id="amount"
                          variant="outlined"
                          onChange={handleChange(field)}
                          slotProps={{
                            input: {
                              inputComponent:
                                field.value !== null ? MoneyField : ('' as any),
                              startAdornment: (
                                <InputAdornment
                                  disableTypography
                                  position="start"
                                >
                                  R$
                                </InputAdornment>
                              ),
                            },

                            htmlInput: {
                              currency: 'BRL',
                              'data-testid': 'amount-input',
                            },
                          }}
                        />
                      </Stack>
                    </FormControl>
                  );
                }}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="link"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="link">
                        Link do item
                        <Box
                          component="span"
                          sx={{ pl: 1, fontWeight: 400, fontSize: '12px' }}
                        >
                          opcional
                        </Box>
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="link"
                        placeholder="Ex.: www.link.com"
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <FieldWrapper loading={loadingBootstrapData}>
              <Controller
                control={control}
                name="requestReason"
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="requestReason">
                        Descrição ou justificativa
                        <Box
                          component="span"
                          sx={{ pl: 1, fontWeight: 400, fontSize: '12px' }}
                        >
                          opcional
                        </Box>
                      </FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="requestReason"
                        multiline
                        placeholder="Descreva o motivo da compra para avaliação da sua empresa."
                        rows={4}
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                )}
              />
            </FieldWrapper>
          </Grid>
        </Grid>
        <StepActions>
          {renderBackButton()}
          <Button color="primary" type="submit" variant="contained">
            Próximo
          </Button>
        </StepActions>
      </form>
      <ResourceApprovalModal
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default CatalogRefundForm;
