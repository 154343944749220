import * as React from 'react';
import {
  Box,
  Typography,
  Skeleton,
  Stack
} from '@mui/material';
import ExternalRefundForm from './external-refund-form/external-refund-form.component';
import CatalogRefundForm from './catalog-refund-form/catalog-refund-form.component';
import ResourceApprovalSummary from './refund-summary/resource-approval-summary.component';
import { ResourceTypes } from '@/react/data/subsidy/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { WizardProvider } from '@/react/components/wizard/state/context';
import Wizard from '@/react/components/wizard/wizard.component';
import { State as WizardState } from '@/react/components/wizard/state/interfaces';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { EligibleResourceType } from '@/react/data/subsidy/interfaces';
import ItemSourceForm from '@/react/components/refund/refund-request/description-step/item-source-form/item-source-form';
import ResourceTypeForm from '@/react/components/refund/refund-request/description-step/resource-type-form/resource-type-form.component';
import { useAvailableResourceApprovalsForRefund } from '@/react/data/subsidy/useAvailableResourceApprovalsForRefund';
import { ExternalResourceTypeForm } from '@/react/components/refund/refund-request/description-step/external-resource-type-form/external-resource-type-form.component';
import LearningAidForm from '@/react/components/refund/refund-request/description-step/external-refund-form/learning-aid-form/learning-aid-form.component';

const Title = ({ children }) => {
  return (
    <Box>
      <Typography variant="h6" color="primary.900">
        {children}
      </Typography>
    </Box>
  );
};

const stepConfigurations = {
  default: [
    WizardStepIds.RESOURCE_TYPE,
    WizardStepIds.CATALOG_FORM,
    WizardStepIds.SUMMARY,
  ],
  [EligibleResourceType.THREE_SIXTY]: [
    WizardStepIds.RESOURCE_TYPE,
    WizardStepIds.ITEM_SOURCE_FORM,
    WizardStepIds.EXTERNAL_RESOURCE_TYPE_FORM,
    WizardStepIds.SUMMARY,
    WizardStepIds.CATALOG_FORM,
    WizardStepIds.EXTERNAL_COURSE_FORM,
    WizardStepIds.EXTERNAL_BOOK_FORM,
    WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM,
    WizardStepIds.EXTERNAL_OTHER_FORM,
  ],
};

const stepComponents = {
  [WizardStepIds.RESOURCE_TYPE]: { component: ResourceTypeForm },
  [WizardStepIds.ITEM_SOURCE_FORM]: { component: ItemSourceForm },
  [WizardStepIds.EXTERNAL_RESOURCE_TYPE_FORM]: { component: ExternalResourceTypeForm },
  [WizardStepIds.SUMMARY]: { component: ResourceApprovalSummary, titleElement: <Title>Reembolso disponível</Title> },
  [WizardStepIds.CATALOG_FORM]: { component: CatalogRefundForm },
  [WizardStepIds.EXTERNAL_COURSE_FORM]: { component: ExternalRefundForm },
  [WizardStepIds.EXTERNAL_BOOK_FORM]: { component: () => <LearningAidForm resourceType={ResourceTypes.BOOK} /> },
  [WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM]: { component: () => <LearningAidForm resourceType={ResourceTypes.EVENT_OR_LECTURE} /> },
  [WizardStepIds.EXTERNAL_OTHER_FORM]: { component: () => <LearningAidForm resourceType={ResourceTypes.OTHERS} /> },
};

export const DescriptionStep = () => {
  const { subsidyPolicy } = useRefundRequest();
  const subsidyPolicyType = subsidyPolicy?.configuration?.eligible_resource_type as EligibleResourceType;
  const stepsIds = stepConfigurations[subsidyPolicyType] || stepConfigurations.default;
  const steps = stepsIds.reduce((acc, stepId) => {
    const stepConfig = stepComponents[stepId];
    acc[stepId] = {
      ...stepConfig,
    };
    return acc;
  }, {});

  return (
    <>
      <Wizard steps={steps} />
    </>
  );
};

const createInitialState = ({ subsidyPolicyType, availableResourceApprovals }) => {
  const stepByPolicyResourceEligible = (subsidyPolicyType) => {
    return subsidyPolicyType === EligibleResourceType.CATALOG ? WizardStepIds.CATALOG_FORM : WizardStepIds.ITEM_SOURCE_FORM;
  }

  const state: WizardState = {
    currentStepId: availableResourceApprovals.length > 0 ?
      WizardStepIds.RESOURCE_TYPE :
      stepByPolicyResourceEligible(subsidyPolicyType),
    values: {},
  };
  
  return state;
};

const DescriptionStepWithContext = () => {
  const { subsidyPolicy } = useRefundRequest();
  const { availableResourceApprovals, isFetching } = useAvailableResourceApprovalsForRefund();
  const subsidyPolicyType = subsidyPolicy?.configuration?.eligible_resource_type as EligibleResourceType;

  if (isFetching) {
    return (
      <Stack spacing={3}>
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            <Skeleton variant="rectangular" height={68} />
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            <Skeleton variant="rectangular" height={68} />
          </Typography>
        </Box>
      </Stack>
    );
  }

  const initialState = createInitialState({ subsidyPolicyType, availableResourceApprovals });

  return (
    <WizardProvider initialState={initialState}>
      <DescriptionStep />
    </WizardProvider>
  );
};

export default DescriptionStepWithContext;
