export enum WizardStepIds {
  ITEM_SOURCE_FORM = 'itemSourceForm',
  EXTERNAL_RESOURCE_TYPE_FORM = 'externalResourceTypeForm',
  CATALOG_FORM = 'catalogForm',
  EXTERNAL_COURSE_FORM = 'externalCourseForm',
  EXTERNAL_BOOK_FORM = 'externalBookForm',
  EXTERNAL_OTHER_FORM = 'externalOtherForm',
  EXTERNAL_EVENT_OR_LECTURE_FORM = 'externalEventOrLectureForm',
  RESOURCE_TYPE = 'resourceType',
  SUMMARY = 'summary',
  SENDING_RECEIPTS_FORM = 'sendingReceiptsForm',
  SENDING_RECEIPTS_SUMMARY = 'sendingReceiptsSummary',
  REFUND_DETAILS = 'RefundDetails',
  PAYMENT_RESUME = 'paymentResume',
}
