export const statusMessages = {
  canceledResourceApprovalStepOne: {
    chip: {
      message: '1/5: Solicitação cancelada',
      variant: 'negativeRequestResponse',
    },
    action: { text: 'Ver detalhes', type: 'link' },
  },
  needsResourceApprovalRevisionStepOne: {
    chip: {
      message: '1/5: Correção necessária',
      variant: 'actionNeededAfterRevision',
    },
    action: { text: 'Corrigir', type: 'button' },
  },
  waitingForRevisionStepTwo: {
    chip: { message: '2/5: Item em análise', variant: 'waitingForRevision' },
    action: { text: 'Ver detalhes', type: 'link' },
  },
  declinedResourceApprovalStepTwo: {
    chip: { message: '2/5: Item recusado', variant: 'negativeRequestResponse' },
    action: { text: 'Ver detalhes', type: 'link' },
  },
  pendingDocumentationStepThree: {
    chip: {
      message: '3/5: Documentação Pendente',
      variant: 'actionNeededAfterRevision',
    },
    action: { text: 'Enviar comprovantes', type: 'button' },
  },
  canceledRefundRequestStepThree: {
    chip: {
      message: '3/5: Solicitação cancelada',
      variant: 'negativeRequestResponse',
    },
    action: { text: 'Ver detalhes', type: 'link' },
  },
  waitingForDocumentationRevisionStepFour: {
    chip: {
      message: '4/5: Documentação em análise',
      variant: 'waitingForRevision',
    },
    action: { text: 'Ver detalhes', type: 'link' },
  },
  declinedRefundRequestStepFour: {
    chip: {
      message: '4/5: Documentação recusada',
      variant: 'negativeRequestResponse',
    },
    action: { text: 'Ver detalhes', type: 'link' },
  },
  needsRefundRequestRevisionStepFour: {
    chip: {
      message: '4/5: Correção necessária',
      variant: 'actionNeededAfterRevision',
    },
    action: { text: 'Corrigir', type: 'button' },
  },
  pendingRefundPaymentStepFive: {
    chip: {
      message: '5/5: Pagamento pendente',
      variant: 'waitingForConclusion',
    },
    action: { text: 'Ver detalhes', type: 'link' },
  },
  paidRefundRequestStepFive: {
    chip: {
      message: '5/5: Pagamento realizado',
      variant: 'processConcludedSuccessfully',
    },
    action: { text: 'Ver detalhes', type: 'link' },
  },
};
