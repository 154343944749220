import React, { useState } from 'react';
import cx from 'clsx';
import { Box, Divider, List, styled } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import type { MegaMenuProps, MenuItem } from './MegaMenu';
import CircularProgress from '@mui/material/CircularProgress';
import { UnstyledLink } from './UnstyledLink';
import { hasProperty } from 'powership';

const LinkButton = styled(UnstyledLink)`
  display: flex;
  flex-direction: row;

  &.view-mode-sidebar {
    min-height: ${({ theme }) => theme.spacing(6)};
  }

  &.view-mode-floatingMenu {
    padding: ${({ theme }) => [0, theme.spacing(2), 0, 0].join(' ')};

    &:hover {
      background: transparent;
      color: ${(props) => props.theme.palette.warning.contrastText};

      &.hard-hover {
        color: ${(props) => props.theme.palette.warning.dark};
      }

      &.column-theme-light {
        color: ${(props) => props.theme.palette.primary.main};
      }
    }

    &.alwaysOpen {
      &,
      &:hover {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
  }

  &.view-mode-sidebar {
    margin-bottom: 2px;
    min-height: 40px;
    width: 255px;
  }

  &.loading {
    pointer-events: none;
    opacity: 0.5;
  }

  &.view-mode-floatingMenu {
    overflow: hidden;
    line-height: 1.2;
    height: 47px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .MenuColumn__list-divider {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;

const MenuList = styled(List)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => [theme.spacing(2), theme.spacing(4)].join(' ')};
  padding-bottom: 0;
  width: 100%;
  max-width: 337px;
  box-sizing: border-box;

  &.view-mode-floatingMenu {
    padding: 0;
    width: min(236px, 14vw);
  }
`;

const ArrowWrapper = styled('div')`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
`;

let mouseOverThrottleRef: ReturnType<typeof setTimeout>;

export type MenuColumnProps = {
  items: { flat: MenuItem[]; byId: Record<string, MenuItem | undefined> };
  className?: string;
  activeMenuId: string | undefined;
  alwaysOpenMenuId?: string;
  setActiveMenuId: (id: string) => void;
  viewMode: MegaMenuProps['viewMode'];
  onClickItem: MegaMenuProps['onClickItem'];
  columnTheme: 'dark' | 'light';
};

export function MenuColumn(props: MenuColumnProps) {
  const {
    columnTheme,
    items,
    className,
    alwaysOpenMenuId,
    activeMenuId,
    setActiveMenuId,
    viewMode,
    onClickItem,
  } = props;

  const viewAsFloatingMenu = viewMode === 'floatingMenu';
  const viewAsSidebar = viewMode === 'sidebar';
  const viewModeClassName = `view-mode-${viewMode}`;
  const columnThemeClassName = `column-theme-${columnTheme}`;
  const [loadingId, setLoadingId] = useState<string | undefined>(undefined);

  return (
    <MenuList
      className={cx(
        className,
        viewModeClassName,
        'MenuColumn__list',
        columnThemeClassName
      )}
    >
      {items.flat.map((item, index) => {
        const isOpen = item.id === activeMenuId;
        const hasChildren = Boolean(items.byId[item.id]?.subItems?.length);
        const loading = item.loading || loadingId === item.id;

        const showArrow = !alwaysOpenMenuId && hasChildren;

        function maybeSetActive(id: string) {
          if (hasChildren) {
            setActiveMenuId(id);
          }
        }

        return (
          <LinkButton
            href={item.route}
            tabIndex={0}
            key={
              item.id +
              index +
              `${
                item.loading ? 'fixing_MUI_premature_optimization_thanks' : ''
              }`
            }
            className={cx(
              {
                divider: item.divider,
                loading: item.loading,
                active: isOpen,
                alwaysOpen: item.id == alwaysOpenMenuId,
              },
              columnThemeClassName,
              `menu-button-${item.id}`,
              'MenuColumn_Button',
              viewModeClassName,
              className
            )}
            onMouseEnter={() => {
              if (viewAsSidebar) return;
              clearTimeout(mouseOverThrottleRef);
              mouseOverThrottleRef = setTimeout(() => {
                maybeSetActive(item.id);
              }, 200);
            }}
            onFocus={() => {
              viewAsFloatingMenu && maybeSetActive(item.id);
            }}
            onClick={(event) => {
              if (item.route) {
                if (event?.ctrlKey || event?.metaKey) {
                  return;
                }
              } else {
                event.preventDefault();
              }

              viewAsSidebar && maybeSetActive(item.id);
              onClickItem?.(item, event);

              const result = item.onClick?.(event);
              const promise = checkPromise(result);

              if (promise) {
                (async () => {
                  setLoadingId(item.id);
                  await promise.catch(() => setLoadingId('_NOT_LOADING_'));
                  setLoadingId('_NOT_LOADING_');
                })();
              }
            }}
          >
            {item.prepend}

            <Box
              width={showArrow ? '80%' : '100%'}
              className={cx({ showArrow }, 'label-item')}
            >
              {item.label}
            </Box>

            {loading ? <CircularProgress size={20} color="primary" /> : null}

            {item.append}

            {showArrow && (
              <ArrowWrapper>
                {isOpen ? <ChevronLeft /> : <ChevronRight />}
              </ArrowWrapper>
            )}

            {!!item.divider && (
              <Divider className={'MenuColumn__list-divider'} />
            )}
          </LinkButton>
        );
      })}
    </MenuList>
  );
}

function checkPromise(input: any): Promise<any> | undefined {
  return hasProperty(input, 'then') &&
    hasProperty(input, 'catch') &&
    typeof input.catch === 'function'
    ? (input as any)
    : undefined;
}
